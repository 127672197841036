import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'

import { CHANGE_REQUEST_REMOVE_ERROR } from './actions'

const initialState = fromJS({
  change_password_loading: false,
  change_password_error: undefined
})

function changePasswordReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'auth')
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case CHANGE_REQUEST_REMOVE_ERROR:
      return state.merge({
        change_password_error: undefined
      })
    default:
      return state
  }
}

export default changePasswordReducer
