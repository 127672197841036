import apiAction from 'common/actions/api'

export const RESET_REQUEST_REMOVE_ERROR =
  'cylera/ResetPassword/RESET_REQUEST_REMOVE_ERROR'

const authApi = apiAction('auth')

export const doResetRequest = (
  token,
  password,
  confirmPassword,
  successCallback,
  failureCallback
) =>
  authApi(
    'reset_password',
    {
      token: token,
      password: password,
      confirm_password: confirmPassword
    },
    {
      method: 'POST',
      failure: failureCallback,
      success: successCallback
    }
  )

export const removeResetRequestError = () => ({
  type: RESET_REQUEST_REMOVE_ERROR
})
