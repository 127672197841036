import asyncComponent from 'common/components/AsyncComponent'
import { ChipIcon } from '@heroicons/react/outline'

export const ROUTE_SUBNETS = '/app/networking/subnets'
export const ROUTE_SUBNET_DETAILS = '/app/subnets/:subnetId'
export const ROUTE_NETWORKING = '/app/networking'
export const ROUTE_POLICIES_OVERVIEW = '/app/networking/policies/overview'
export const ROUTE_POLICY_DETAILS = '/app/networking/policy/:groupUuid'
export const ROUTE_EXTERNAL_COMMUNICATION =
  '/app/networking/externalcommunication'

export const networkingRoutes = [
  // Policies Tabs
  {
    route: ROUTE_POLICY_DETAILS,
    component: asyncComponent(
      () => import('components/Main/Networking/Policies/PolicyDetail'),
      { retryKey: 'policy-detail' }
    ),
    parent: ROUTE_POLICIES_OVERVIEW,
    title: 'Policy Detail'
  },
  {
    route: ROUTE_NETWORKING,
    component: asyncComponent(() => import('components/Main/Networking'), {
      retryKey: 'networking'
    }),
    exact: false
  },
  {
    route: ROUTE_POLICIES_OVERVIEW,
    component: asyncComponent(
      () => import('components/Main/Networking/Policies/PoliciesOverview'),
      { retryKey: 'policy-overview' }
    ),
    parent: ROUTE_SUBNETS,
    title: 'Policies',
    subContexts: [/app\/networking\/(policies|policy)(.*?)/]
  },
  {
    route: ROUTE_SUBNET_DETAILS,
    component: asyncComponent(
      () => import('components/Main/Networking/Subnets/SubnetDetails'),
      { retryKey: 'subnet-details' }
    ),
    parent: ROUTE_SUBNETS,
    title: 'Subnet Details'
  },
  {
    route: ROUTE_SUBNETS,
    component: asyncComponent(
      () => import('components/Main/Networking/Subnets'),
      { retryKey: 'subnets' }
    ),
    exact: true,
    icon: 'os-icon-grid-squares',
    heroIcon: <ChipIcon />,
    title: 'Network',
    tabTitle: 'Subnets',
    navbar: true,

    order: 5,
    subContexts: [/app\/(networking|subnets)(.*)/],
    children: [
      { name: 'Subnets', path: ROUTE_SUBNETS },
      { name: 'Policies', path: ROUTE_POLICIES_OVERVIEW },
      { name: 'External Communication', path: ROUTE_EXTERNAL_COMMUNICATION }
    ],
    engine: 'network_engine'
  },
  {
    route: ROUTE_EXTERNAL_COMMUNICATION,
    component: asyncComponent(
      () => import('components/Main/Networking/ExternalCommunication'),
      { retryKey: 'external-communication' }
    ),
    title: 'External Communication',
    parent: ROUTE_SUBNETS
  }
]

export default networkingRoutes
