import asyncComponent from 'common/components/AsyncComponent'

export const ROUTE_SETTINGS = '/app/settings'
export const ROUTE_SETTINGS_APPLIANCES = '/app/settings/appliances'
export const ROUTE_SETTINGS_USERS = '/app/settings/users'
export const ROUTE_SETTINGS_ACCOUNT = '/app/settings/account'
export const ROUTE_SETTINGS_CUSTOM_DASHBOARDS = '/app/settings/dashboards'
export const ROUTE_SETTINGS_CUSTOM_DASHBOARDS_EDITOR =
  '/app/dashboards/editor/:id'
export const ROUTE_SETTINGS_ROLES = '/app/settings/roles'
export const ROUTE_SETTINGS_ROLE_EDITOR = '/app/role/:id'

export const settingsRoutes = [
  {
    route: ROUTE_SETTINGS,
    exact: false,
    component: asyncComponent(() => import('components/Main/Settings'), {
      retryKey: 'settings'
    }),
    title: 'Settings'
  },
  {
    route: ROUTE_SETTINGS_USERS,
    title: 'Users',
    parent: ROUTE_SETTINGS
  },
  {
    route: ROUTE_SETTINGS_APPLIANCES,
    title: 'Appliances',
    navbar: false,
    parent: ROUTE_SETTINGS
  },
  {
    route: ROUTE_SETTINGS_ACCOUNT,
    title: 'Account',
    navbar: false,
    parent: ROUTE_SETTINGS
  },
  {
    route: ROUTE_SETTINGS_CUSTOM_DASHBOARDS,
    title: 'Custom Dashboards',
    navbar: false,
    parent: ROUTE_SETTINGS
  },
  {
    route: ROUTE_SETTINGS_CUSTOM_DASHBOARDS_EDITOR,
    title: 'Custom Dashboard Editor',
    navbar: false,
    exact: true,
    parent: ROUTE_SETTINGS_CUSTOM_DASHBOARDS,
    component: asyncComponent(
      () =>
        import(
          'components/Main/Settings/CustomDashboards/CustomDashboardEditor/'
        ),
      {
        retryKey: 'dashboard_editor'
      }
    )
  },
  {
    route: ROUTE_SETTINGS_ROLES,
    title: 'Roles',
    navbar: false,
    parent: ROUTE_SETTINGS
  },
  {
    route: ROUTE_SETTINGS_ROLE_EDITOR,
    title: 'Role Details',
    navbar: false,
    exact: true,
    parent: ROUTE_SETTINGS_ROLES,
    component: asyncComponent(
      () => import('components/Main/Settings/Roles/RoleEditor'),
      {
        retryKey: 'role_details'
      }
    )
  }
]

export default settingsRoutes
