import asyncComponent from 'common/components/AsyncComponent'
import { DocumentTextIcon } from '@heroicons/react/outline'

export const ROUTE_REPORTING = '/app/reporting'

export const reportingRoutes = [
  {
    route: ROUTE_REPORTING,
    component: asyncComponent(() => import('components/Main/Reporting'), {
      retryKey: 'reporting'
    }),
    icon: 'os-icon-documents-03',
    heroIcon: <DocumentTextIcon />,
    title: 'Reporting',
    tabTitle: 'Scheduled',
    navbar: true,
    order: 7,
    subContexts: [/app\/reporting\/(.*?)/],
    engine: 'reporting_engine'
  }
]

export default reportingRoutes
