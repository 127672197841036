export const INIT_TRACKING = 'analytics/INIT_TRACKING'
export const TRACK_AGGREGATE_PAGE_VIEW = 'analytics/TRACK_AGGREGATE_PAGE_VIEW'
export const TRACK_PAGE_VIEW = 'analytics/TRACK_PAGE_VIEW'
export const REGISTER_SOURCE = 'analytics/REGISTER_SOURCE'

export const initTracking = (history, testMode = false) => ({
  type: INIT_TRACKING,
  history: history,
  testMode: testMode
})

export const trackAggregatePageView = (location, id) => ({
  type: TRACK_AGGREGATE_PAGE_VIEW,
  location: location,
  id: id
})

export const registerSource = response => ({
  type: REGISTER_SOURCE,
  response
})

export const trackPageView = ({ history, location }) => ({
  type: TRACK_PAGE_VIEW,
  history,
  location
})
