import { createSelector } from 'reselect'
import { lowerCase } from 'common/util/string'

export const group = (state, props) => state.main.group

export const details = createSelector(group, group => group.get('details'))
export const detailsLoading = createSelector(group, group =>
  group.get('details_loading')
)

export const groupType = createSelector(
  details,
  details => details && lowerCase(details.get('group_display_type'))
)
export const name = createSelector(
  details,
  details => details && details.get('name')
)
export const groupInternalId = createSelector(
  details,
  details => details && details.get('group_internal_id')
)
export const rawName = createSelector(
  details,
  details => details && details.get('raw_name')
)
export const isCustom = createSelector(
  details,
  details => details && details.get('is_system_owned') === false
)
export const groupUuid = createSelector(group, group => group.get('group_uuid'))
export const groupUuidFromRoute = (state, props) => props.match.params.groupUuid

export const utilization = createSelector(group, group =>
  group.get('utilization')
)
export const utilizationLoading = createSelector(group, group =>
  group.get('utilization_loading')
)
export const groupFilters = createSelector(group, group =>
  group.get('group_filters')
)
export const groupFiltersLoading = createSelector(group, group =>
  group.get('group_filters_loading')
)

export const ownerName = createSelector(
  details,
  details =>
    details &&
    details.get('owner_first_name') &&
    details.get('owner_last_name') &&
    details.get('owner_first_name') + ' ' + details.get('owner_last_name')
)

export const ownerId = createSelector(
  details,
  details => details && details.get('owned_by')
)

export const groupsOwnedCount = createSelector(group, group =>
  group.get('groups_owned_count')
)

export const overview = (state, props) => {
  return state.main.devices.overview
}
export const groupings = createSelector(
  overview,
  overview => overview && overview.get('groupings')
)
export const groupingsTotal = createSelector(overview, overview => {
  return overview && overview.get('groupings_total')
})

export const groupingsLoading = createSelector(
  overview,
  overview => overview && overview.get('groupings_loading')
)

export const myGroupings = createSelector(
  overview,
  overview => overview && overview.get('my_groupings')
)
export const myGroupingsTotal = createSelector(overview, overview => {
  return overview && overview.get('my_groupings_total')
})

export const myGroupingsLoading = createSelector(
  overview,
  overview => overview && overview.get('my_groupings_loading')
)
export const myGroupingsSearch = createSelector(
  overview,
  overview => overview && overview.get('my_groupings_search_query')
)
export const filterValues = createSelector(
  overview,
  overview => overview && overview.get('filter_values')
)
export const groupColumnVisibilities = state =>
  state.main.group.get('devices_group_column_visibilities')
export const persistedGroupActiveFilters = state =>
  state.main.group.get('group_active_filters')

export const queryFilter = state => state.main.group.get('query_filter')
export const sortKey = state => state.main.group.get('sort_key')
export const sortDirection = state => state.main.group.get('sort_direction')
