import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'

import { RESET_REQUEST_REMOVE_ERROR } from './actions'

const initialState = fromJS({
  reset_password_loading: false,
  reset_password_error: undefined
})

function resetPasswordReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'auth')
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case RESET_REQUEST_REMOVE_ERROR:
      return state.merge({
        reset_password_error: undefined
      })
    default:
      return state
  }
}

export default resetPasswordReducer
