import { fromJS } from 'immutable'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'
import {
  SET_FILTERS,
  SET_COLUMN_VISIBILITIES,
  SET_QUERY,
  SET_SORT
} from './actions'
import { SORT_DESC } from 'common/constants'

const devicesColumnVisibilities = fromJS({
  ip_address: { label: 'IP Address', visible: true },
  'inventory_status.device_class': { label: 'Class', visible: true },
  mac_address: { label: 'MAC Address', visible: true },
  vlan: { label: 'VLAN', visible: false },
  hostname: { label: 'Hostname', visible: true },
  risk: { label: 'Risk', visible: true },
  tag_type: { label: 'Type', visible: true },
  tag_model: { label: 'Model', visible: true },
  inventory_status: { label: 'Insights', visible: true },
  aetitle: { label: 'AE Title', visible: false },
  eol_os: { label: 'EOL OS', visible: false },
  tag_vendor: { label: 'Vendor', visible: false },
  first_seen: { label: 'First Seen', visible: false },
  last_seen: { label: 'Last Seen', visible: false },
  fda_class_code: { label: 'FDA Class Code', visible: false },
  serial_number: { label: 'Serial Number', visible: false },
  vulnerabilities: { label: 'Vulnerabilities', visible: false },
  threats: { label: 'Threats', visible: false },
  tag_os: { label: 'Operating System', visible: false },
  owner_names: { label: 'Owners', visible: false }
})

const initialState = fromJS({
  devices_loading: true,
  devices_total: 0,
  devices: [],
  filters: [],
  filter_options: undefined,
  filter_options_loading: false,
  columnVisibilities: devicesColumnVisibilities,
  queryFilter: undefined,
  sortKey: 'last_seen',
  sortDirection: SORT_DESC,
  ...apiInitialState('rules')
})

function suppressedDevicesReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'suppressed',
    initialState
  )

  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case SET_QUERY:
      return state.merge({
        queryFilter: action.data
      })
    case SET_FILTERS:
      return state.merge({
        filters: action.data
      })
    case SET_SORT:
      const sortKey = action.data['sortKey']
      const sortDirection = action.data['sortDirection']
      return state.merge({
        sortKey: sortKey,
        sortDirection: sortDirection
      })
    case SET_COLUMN_VISIBILITIES:
      return state.merge({
        columnVisibilities: action.data
      })
    default:
      return state
  }
}

export default suppressedDevicesReducer
