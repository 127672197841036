import apiAction from 'common/actions/api'

export const CHANGE_REQUEST_REMOVE_ERROR =
  'cylera/auth/CHANGE_REQUEST_REMOVE_ERROR'

const authApi = apiAction('auth')

export const doChangeRequest = (email, successCallback, failureCallback) =>
  authApi(
    'change_password',
    {
      email: email
    },
    {
      method: 'POST',
      failure: failureCallback,
      success: successCallback
    }
  )

export const removeChangeRequestError = () => ({
  type: CHANGE_REQUEST_REMOVE_ERROR
})
