import { createSelector } from 'reselect'

const changePassword = (state, props) => state.auth.change_password

export const error = createSelector(
  changePassword,
  changePassword =>
    changePassword.getIn(['change_password_error', 'email', 0]) ||
    changePassword.get('change_password_error')
)
export const isLoading = createSelector(changePassword, changePassword =>
  changePassword.get('change_password_loading')
)
