import asyncComponent from 'common/components/AsyncComponent'
import { PresentationChartBarIcon } from '@heroicons/react/outline'

export const ROUTE_UTILIZATION = '/app/utilization/'
export const ROUTE_UTILIZATION_GROUP_DETAIL =
  '/app/utilization/group/:groupUuid'
export const ROUTE_UTILIZATION_GROUP_DETAIL_OVERVIEW =
  '/app/utilization/group/:groupUuid/overview'
export const ROUTE_UTILIZATION_GROUP_DETAIL_CLINICAL =
  '/app/utilization/group/:groupUuid/clinical'
export const ROUTE_UTILIZATION_GROUP_DETAIL_DEVICES =
  '/app/utilization/group/:groupUuid/devices'
export const ROUTE_UTILIZATION_GROUP_UTILIZATION_LIST =
  '/app/utilization/groups'

export const utilizationRoutes = [
  {
    exact: false,
    route: ROUTE_UTILIZATION_GROUP_DETAIL,
    title: 'Group Utilization Detail',
    component: asyncComponent(
      () => import('components/Main/Utilization/GroupUtilizationDetail'),
      { retryKey: 'group-utilization-detail' }
    )
  },
  {
    route: ROUTE_UTILIZATION_GROUP_DETAIL_DEVICES,
    title: 'Group Utilization Devices',
    tabTitle: 'Devices',
    component: asyncComponent(
      () =>
        import(
          'components/Main/Utilization/GroupUtilizationDetail/GroupUtilizationDevices'
        ),
      { retryKey: 'group-utilization-devices' }
    ),
    parent: ROUTE_UTILIZATION_GROUP_UTILIZATION_LIST
  },
  {
    route: ROUTE_UTILIZATION_GROUP_DETAIL_OVERVIEW,
    title: 'Group Utilization Overview',
    tabTitle: 'Overview',
    component: asyncComponent(
      () =>
        import(
          'components/Main/Utilization/GroupUtilizationDetail/GroupUtilizationOverview'
        ),
      { retryKey: 'group-utilization-overview' }
    ),
    parent: ROUTE_UTILIZATION_GROUP_UTILIZATION_LIST
  },
  {
    route: ROUTE_UTILIZATION_GROUP_DETAIL_CLINICAL,
    title: 'Group Clinical Utilization',
    tabTitle: 'Clinical',
    component: asyncComponent(
      () =>
        import(
          'components/Main/Utilization/GroupUtilizationDetail/GroupUtilizationClinical'
        ),
      { retryKey: 'group-utilization-clinical' }
    ),
    parent: ROUTE_UTILIZATION_GROUP_UTILIZATION_LIST
  },
  {
    route: ROUTE_UTILIZATION_GROUP_UTILIZATION_LIST,
    component: asyncComponent(() => import('components/Main/Utilization/'), {
      retryKey: 'utilization'
    }),
    title: 'Utilization',
    tabTitle: 'Group Utilization',
    icon: 'os-icon-bar-chart-stats-up',
    heroIcon: <PresentationChartBarIcon />,
    navbar: true,

    order: 6,
    subContexts: [/app\/utilization\/(.*?)/],
    engine: 'utilization_engine'
  }
]

export default utilizationRoutes
