import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import apiReducer from 'common/reducers/api'
import historyReducer from 'common/reducers/history'
import modalReducer from 'common/reducers/modal'
import notificationsReducer from 'common/reducers/notifications'
import firestoreReducer from 'common/reducers/firestore'
import firebaseReducer from 'common/reducers/firebase'
import authReducer from 'components/Auth/reducer'
import mainReducer from 'components/Main/reducer'

export default combineReducers({
  api: apiReducer,
  routing: routerReducer,
  auth: authReducer,
  main: mainReducer,
  history: historyReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
})
