import { fromJS } from 'immutable'

import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'

const GET_AUTH_SUCCESS = 'cylera/nav/GET_AUTH_SUCCESS'
const GET_AUTH_FAILURE = 'cylera/nav/GET_AUTH_FAILURE'

const initialState = fromJS({
  ...apiInitialState('alerts'),
  auth: undefined
})

function navReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'navAlert', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case GET_AUTH_SUCCESS:
      return state.merge({
        auth: action.data
      })
    case GET_AUTH_FAILURE:
      return state.merge({
        auth: undefined
      })
    default:
      return state
  }
}

export default navReducer
