import { REGISTER_FIREBASE_APP } from 'common/actions/firebase'
import { getPerformance } from 'firebase/performance'

export const firebaseMiddleware = store => next => action => {
  const nextAction = next(action)

  const { type: actionType } = action

  switch (actionType) {
    case REGISTER_FIREBASE_APP:
      getPerformance(action.firebaseApp)
      break
    default:
      return false
  }
  return nextAction
}

export default firebaseMiddleware
