import { fromJS } from 'immutable'
import { toSnakeCase } from 'common/util/string'

export const safeAccess = (obj, keys, fallback) => {
  if (!obj) {
    return undefined
  }
  const immutableObj = obj.hasOwnProperty('get') ? obj : fromJS(obj)
  if (!immutableObj) {
    return undefined
  }
  return immutableObj.getIn(keys) || fallback
}

export const safeCount = data => {
  if (!data) {
    return 0
  }
  if (data.hasOwnProperty('size')) {
    return data.size
  }
  if (data.hasOwnProperty('length')) {
    return data.length
  }
}

export const sumOfValues = dictionary => {
  if (!dictionary) {
    return undefined
  }
  return dictionary.reduce((sum, cur) => sum + cur, 0)
}

export const sortLabelValuesLowMediumHigh = list => {
  if (!list) {
    return undefined
  }
  let sorted = []
  let initial = list.hasOwnProperty('size') ? fromJS(list) : list
  const order = ['critical', 'high', 'medium', 'low', 'info', 'unknown']
  const getIfExists = level =>
    initial.find(each => toSnakeCase(each.get('label')) === level)
  for (var level in order) {
    const labelValue = getIfExists(order[level])
    if (labelValue) {
      sorted.push(labelValue)
    }
  }
  initial.map(
    each => !order.includes(toSnakeCase(each.get('label'))) && sorted.push(each)
  )
  return fromJS(sorted)
}
