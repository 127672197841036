import apiAction from 'common/actions/api'

export const CHANGE_LOCATION = 'cylera/locations/CHANGE_LOCATION'

export const changeLocation = locationId => {
  return {
    type: CHANGE_LOCATION,
    location_id: locationId
  }
}

const locationsOverviewApi = apiAction('dashboard/locations')

export const getLocationsOverview = callback =>
  locationsOverviewApi('overview', {}, { success: callback })
