import { createSelector } from 'reselect'
export const organization = (state, props) => state.main.organization

export const organizationLocation = createSelector(
  organization,
  organization => organization.location
)
export const reporting = state => state.main.reporting.scheduled
export const locations = state => state.main.locations

export const organizationLocations = createSelector(
  organizationLocation,
  organizationLocation =>
    organizationLocation && organizationLocation.get('list')
)

export const download = createSelector(reporting, reporting =>
  reporting.get('download')
)
export const scheduled = createSelector(reporting, reporting =>
  reporting.get('scheduled')
)
export const scheduledLoading = createSelector(reporting, reporting =>
  reporting.get('scheduled_loading')
)
export const scheduledTotal = createSelector(reporting, reporting =>
  reporting.get('scheduled_total')
)

export const locationsLoading = createSelector(
  organizationLocation,
  location => location && location.get('list_loading')
)

export const frequencies = createSelector(reporting, reporting =>
  reporting.get('frequencies')
)
export const frequenciesLoading = createSelector(reporting, reporting =>
  reporting.get('frequencies_loading')
)
export const kinds = createSelector(reporting, reporting =>
  reporting.get('kinds')
)
export const kindsLoading = createSelector(reporting, reporting =>
  reporting.get('kinds_loading')
)
export const recipients = createSelector(reporting, reporting =>
  reporting.get('recipients')
)
export const recipientsLoading = createSelector(reporting, reporting =>
  reporting.get('recipients_loading')
)

export const locationOptions = createSelector(
  organizationLocations,
  organizationLocations =>
    organizationLocations &&
    organizationLocations.toJS().map((location, i) => ({
      label: location.name,
      value: location.id
    }))
)

export const details = createSelector(reporting, reporting =>
  reporting.get('details')
)

export const detailsLoading = createSelector(reporting, reporting =>
  reporting.get('details_loading')
)

export const fields = createSelector(reporting, reporting =>
  reporting.getIn(['details', 'fields'])
)

export const description = createSelector(reporting, reporting =>
  reporting.getIn(['details', 'description'])
)
