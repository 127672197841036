import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'
import { RESET_USER_PAGE } from './actions'

const initialState = fromJS({
  user: undefined,
  user_loading: true,
  all_users: undefined,
  all_users_loading: true
})

function userReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'user', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case RESET_USER_PAGE:
      return state.merge({
        user: initialState.get('user'),
        user_loading: initialState.get('user_loading')
      })
    default:
      return state
  }
}

export default userReducer
