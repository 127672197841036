import asyncComponent from 'common/components/AsyncComponent'
import { FEATURE_CYBER_ALERTS } from 'common/util/env'
import { RiskIcon } from 'icons/RiskIcon'

export const ROUTE_RISK = '/app/risk/'
export const ROUTE_RISK_OVERVIEW = '/app/risk/overview'
export const ROUTE_RISK_DETAIL = '/app/risk/detail/:referenceUuid/:groupType'
export const ROUTE_NHS_CYBER_ALERTS = '/app/risk/nhscyberalerts'
export const ROUTE_NHS_CYBER_ALERT_DETAIL =
  '/app/risk/nhscyberalert/:cyberAlertId'

export const riskRoutes = [
  {
    route: ROUTE_NHS_CYBER_ALERT_DETAIL,
    component: asyncComponent(
      () => import('components/Main/Risk/NHSCyberAlerts/NHSCyberAlertDetail'),
      { retryKey: 'nhs-cyber-alert-detail' }
    ),
    parent: ROUTE_NHS_CYBER_ALERTS,
    title: 'NHS Cyber Alert Detail'
  },
  {
    route: ROUTE_RISK_DETAIL,
    component: asyncComponent(() => import('components/Main/Risk/RiskDetail'), {
      retryKey: 'risk-detail'
    }),
    parent: ROUTE_RISK_OVERVIEW,
    title: 'Risk Details'
  },
  {
    route: ROUTE_NHS_CYBER_ALERTS,
    component: asyncComponent(
      () => import('components/Main/Risk/NHSCyberAlerts'),
      { retryKey: 'nhs-cyber-alerts' }
    ),
    parent: ROUTE_RISK_OVERVIEW,
    title: 'NHS Cyber Alerts',
    featureFlag: FEATURE_CYBER_ALERTS
  },
  {
    route: ROUTE_RISK_OVERVIEW,
    component: asyncComponent(() => import('components/Main/Risk/'), {
      retryKey: 'risk'
    }),
    title: 'Risk Overview',
    tabTitle: 'Overview',
    icon: 'os-icon-fire',
    heroIcon: <RiskIcon />,
    navbar: true,

    order: 3,
    subContexts: [/app\/risk\/(.*?)/],
    engine: 'risk_engine'
  }
]

export default riskRoutes
