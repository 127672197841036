import { initializeApp } from 'firebase/app'
import * as ANALYTICS_ACTIONS from 'common/actions/analytics'
import * as HISTORY_ACTIONS from 'common/actions/history'
import * as LIFECYCLE_ACTIONS from 'common/actions/lifecycle'
import * as FIREBASE_ACTIONS from 'common/actions/firebase'

const DEFAULT_CONFIG_VALUE = 'from-firebase-console'
const lifecycleMiddleware = store => next => action => {
  let result = next(action)
  const { history } = action.params || {}

  const { dispatch } = store

  switch (action.type) {
    case LIFECYCLE_ACTIONS.INIT_APP:
      if (
        process.env.REACT_APP_FIREBASE_API_KEY !== undefined &&
        process.env.REACT_APP_FIREBASE_APP_ID !== undefined &&
        process.env.REACT_APP_FIREBASE_PROJECT_ID !== undefined &&
        process.env.REACT_APP_FIREBASE_API_KEY !== DEFAULT_CONFIG_VALUE &&
        process.env.REACT_APP_FIREBASE_APP_ID !== DEFAULT_CONFIG_VALUE &&
        process.env.REACT_APP_FIREBASE_PROJECT_ID !== DEFAULT_CONFIG_VALUE
      ) {
        const firebaseConfig = {
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
          appId: process.env.REACT_APP_FIREBASE_APP_ID,
          projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
        }
        const firebaseApp = initializeApp(firebaseConfig)
        dispatch(FIREBASE_ACTIONS.registerFirebaseApp(firebaseApp))
      }
      dispatch(ANALYTICS_ACTIONS.initTracking())
      dispatch(
        HISTORY_ACTIONS.initListeners({
          history,
          listeners: [ANALYTICS_ACTIONS.trackPageView]
        })
      )
      break
    default:
      return result
  }
}

export default lifecycleMiddleware
