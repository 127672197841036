import { selectIn } from 'common/util/selector'

const dashboards = (state, props) => state.main.settings.dashboards

export const filterOptions = selectIn(dashboards, 'filter_options')
export const filterOptionsLoading = selectIn(
  dashboards,
  'filter_options_loading'
)

export const filterValues = selectIn(dashboards, 'filter_values')

export const customDashboards = selectIn(dashboards, 'list')
export const customDashboardsLoading = selectIn(dashboards, 'list_loading')
export const customDashboardsTotal = selectIn(dashboards, 'list_total')

export const dashboardLoading = selectIn(dashboards, 'dashboard_loading')
