import { selectIn } from 'common/util/selector'

const base = (state, props) => state.main.risk.overview

export const riskSummary = selectIn(base, 'summary')
export const riskSummaryLoading = selectIn(base, 'summary_loading')

export const eolOsBreakdown = selectIn(base, 'eol_os_breakdown')
export const eolOsBreakdownLoading = selectIn(base, 'eol_os_breakdown_loading')

export const riskByLocation = selectIn(base, 'risk_by_location')
export const riskByLocationLoading = selectIn(base, 'risk_by_location_loading')

export const riskiestModels = selectIn(base, 'riskiest_models')
export const riskiestModelsLoading = selectIn(base, 'riskiest_models_loading')

export const riskiestVendors = selectIn(base, 'riskiest_vendors')
export const riskiestVendorsLoading = selectIn(base, 'riskiest_vendors_loading')

export const ageSeverityMap = selectIn(base, 'age_severity_map')
export const ageSeverityMapLoading = selectIn(base, 'age_severity_map_loading')

export const topMitigation = selectIn(base, 'top_mitigation')
export const topMitigationLoading = selectIn(base, 'top_mitigation_loading')

export const scoreHistory = selectIn(base, 'score_history')
export const scoreHistoryLoading = selectIn(base, 'score_history_loading')
