import { fromJS } from 'immutable'
import { convertFilterValueToArray } from 'common/util/type'

import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'

import {
  CHANGE_FILTER,
  CHANGE_SINGULAR_FILTER,
  RESET_FILTERS,
  CLEAR_INSTANCES
} from './actions'

export const initialFilterValues = fromJS({
  query: '',
  severity: [],
  status: 'include_all'
})

const initialState = fromJS({
  ...apiInitialState('instances'),
  ...apiInitialState('filters'),
  ...apiInitialState('events'),
  ...apiInitialState('count_v2'),
  filter_values: initialFilterValues
})

function alertsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'alert', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case CHANGE_FILTER:
      const filterKey = action.data['filter']
      const filterValue = action.data['value']
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filterKey]: convertFilterValueToArray(filterValue)
        })
      )
    case CHANGE_SINGULAR_FILTER:
      const singularFilterKey = action.data['filter']
      const singularFilterValue = action.data['value']
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [singularFilterKey]: singularFilterValue
        })
      )
    case RESET_FILTERS:
      return state.mergeIn(['filter_values'], initialState.get('filter_values'))
    case CLEAR_INSTANCES:
      return state.merge({ instances: undefined })
    default:
      return state
  }
}

export default alertsReducer
