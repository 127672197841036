import apiAction from 'common/actions/api'

export const CLEAR_INTEGRATION_DETAIL =
  'cylera/settings/integrations/CLEAR_INTEGRATION_DETAIL'
export const CLEAR_INTEGRATION_ACTIONS =
  'cylera/settings/integrations/CLEAR_INTEGRATION_ACTIONS'
const integrationsApi = apiAction('settings/integrations')

export const addIntegrationInstance = (integration, success) =>
  integrationsApi(
    'instance/add',
    {
      integration
    },
    {
      method: 'POST',
      success
    }
  )

export const deleteIntegrationInstance = (instance, success) =>
  integrationsApi(
    'instance/delete',
    {
      instance
    },
    {
      method: 'POST',
      success
    }
  )

export const loadIntegrationDetail = (uuid, callback) =>
  integrationsApi(
    'details',
    { uuid: uuid },
    { cacheable: false, success: callback }
  )

export const loadIntegrationActions = (uuid, callback) =>
  integrationsApi(
    'actions',
    { uuid: uuid },
    { cacheable: false, success: callback }
  )

export const loadIntegrationLogfile = (uuid, callback) =>
  integrationsApi(
    'logfile',
    {
      uuid
    },
    { cacheable: false, success: callback }
  )

export const loadIntegrations = callback =>
  integrationsApi(
    'kinds',
    {},
    {
      cacheable: false,
      success: callback
    }
  )

export const loadAppliances = (
  integrationUuid,
  integrationInstanceUuid,
  integrationName,
  callback
) =>
  integrationsApi(
    'appliances',
    {
      integration_uuid: integrationUuid,
      integration_instance_uuid: integrationInstanceUuid,
      integration_name: integrationName
    },
    {
      cacheable: false,
      success: callback
    }
  )

export const invokeIntegration = (uuid, action, success) =>
  integrationsApi(
    'instance/invoke',
    {
      uuid,
      action
    },
    {
      method: 'POST',
      success,
      cacheable: false
    }
  )

export const saveIntegration = (
  uuid,
  data,
  files,
  applianceUuid,
  successCallback,
  failureCallback
) =>
  integrationsApi(
    'save',
    {
      uuid: uuid,
      data: data,
      files: files,
      appliance_uuid: applianceUuid
    },
    {
      method: 'POST',
      success: () => {
        successCallback()
      },
      failure: () => {
        failureCallback()
      }
    }
  )

export const connectionTest = (
  uuid,
  fieldValues,
  files,
  appliance_uuid,
  success
) =>
  integrationsApi(
    'instance/connection_test',
    {
      uuid,
      integration_field_values: fieldValues,
      files: files,
      appliance_uuid
    },
    {
      method: 'POST',
      success,
      cacheable: false
    }
  )

export const loadGroups = callback =>
  integrationsApi(
    'groups',
    {},
    {
      cacheable: false,
      success: callback
    }
  )

export const triggerTicketingIntegrations = (
  shortDescription,
  longDescription,
  url,
  entityType,
  entityId,
  severity,
  successCallback
) =>
  integrationsApi(
    'trigger_ticketing_integrations',
    {
      short_description: shortDescription,
      long_description: longDescription,
      url: url,
      entity_type: entityType,
      entity_id: entityId,
      severity: severity
    },
    {
      method: 'POST',
      success: () => {
        successCallback()
      }
    }
  )

export const generateKey = (uuid, callback) =>
  integrationsApi(
    'generate_key',
    {
      uuid
    },
    { method: 'POST', success: callback }
  )

export const clearIntegrationDetail = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_INTEGRATION_DETAIL
    })
  }
}

export const clearIntegrationActions = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_INTEGRATION_ACTIONS
    })
  }
}
