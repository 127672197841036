import { createSelector } from 'reselect'

const mfa = (state, props) => state.main.settings.user.mfa

export const isEnabled = createSelector(mfa, mfa =>
  mfa.getIn(['registration', 'is_mfa_enabled'])
)
export const email = createSelector(mfa, mfa =>
  mfa.getIn(['registration', 'email'])
)
export const countryCode = createSelector(mfa, mfa =>
  mfa.getIn(['registration', 'country_code'])
)
export const phoneNumber = createSelector(mfa, mfa =>
  mfa.getIn(['registration', 'phone'])
)

export const errorMessage = createSelector(
  mfa,
  mfa =>
    mfa.getIn(['registration_error', 'country_code', 0]) ||
    mfa.getIn(['registration_error', 'phone_number', 0]) ||
    mfa.get('registration_error')
)

export const successMessage = createSelector(mfa, mfa =>
  mfa.getIn(['registration', 'message'])
)

export const isLoading = createSelector(mfa, mfa =>
  mfa.get('registration_loading')
)
