import apiAction from 'common/actions/api'
export const SET_FILTERS = 'cylera/settings/role/SET_FILTERS'
export const SET_SORT = 'cylera/settings/role/SET_SORT'
export const SET_TOTAL = 'cylera/settings/role/SET_TOTAL'

const rolesApi = apiAction('role')

export const getRole = uuid =>
  rolesApi('details', { uuid }, { cacheable: false })

export const getDashboards = () =>
  rolesApi('secondary_dashboards', {}, { cacheable: false })

export const getPrimaryDashboard = uuid =>
  rolesApi('primary_dashboard', { uuid }, { cacheable: false })

export const getRoleMetadata = uuid =>
  rolesApi('metadata', { uuid }, { cacheable: false })

export const getRoleAssignedGroups = uuid =>
  rolesApi('assigned_groups', { uuid }, { cacheable: false })

export const getRoleEditGroups = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  query
) =>
  rolesApi(
    'edit_groups',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query_text: query
    },
    { cacheable: false }
  )
export const getRoleEditGroupsInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  query
) =>
  rolesApi(
    'edit_groups',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query_text: query
    },
    { cacheable: false, infinite: true }
  )
export const getRoleEditGroupsUuids = query =>
  rolesApi('edit_groups_uuids', { query_text: query }, { cacheable: false })

export const getRolePermissions = uuid =>
  rolesApi('permissions', { uuid }, { cacheable: false })

export const getRoleLocations = uuid =>
  rolesApi('locations', { uuid }, { cacheable: false })

export const updateRolePermissions = (
  uuid,
  permissions,
  { onSuccess, onFailure } = {}
) =>
  rolesApi(
    'permissions',
    { uuid, permissions },
    { cacheable: false, method: 'PUT', success: onSuccess, failure: onFailure }
  )

export const updateRoleLocations = (
  uuid,
  locations,
  { onSuccess, onFailure } = {}
) =>
  rolesApi(
    'locations',
    { uuid, locations },
    { cacheable: false, method: 'PUT', success: onSuccess, failure: onFailure }
  )

export const updateRoleGroups = (uuid, groups, { onSuccess, onFailure } = {}) =>
  rolesApi(
    'assigned_groups',
    { uuid, groups },
    { cacheable: false, method: 'PUT', success: onSuccess, failure: onFailure }
  )

export const getRoleUsers = (
  roleUuid,
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues,
  { infinite = false } = {}
) => {
  const { query } = filterValues || {}
  return rolesApi(
    'users',
    {
      uuid: roleUuid,
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query
    },
    { infinite, cacheable: false }
  )
}

export const getRoleUserTotal = ({ roleUuid, query } = {}) => {
  return rolesApi(
    'users_count',
    { uuid: roleUuid, query },
    { cacheable: false }
  )
}

export const setFilters = ({ query } = {}) => {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      data: {
        query
      }
    })
  }
}

export const setTotalGroups = total => {
  return dispatch => {
    dispatch({
      type: SET_TOTAL,
      data: total
    })
  }
}

export function setSort({ sortKey, sortDirection } = {}) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: {
        sort_key: sortKey,
        sort_direction: sortDirection
      }
    })
  }
}

export const saveRole = (
  { uuid, name, description } = {},
  { onSuccess, onFailure } = {}
) =>
  rolesApi(
    'metadata',
    { uuid, name, description },
    { method: 'PUT', success: onSuccess, failure: onFailure }
  )

export const deleteRole = ({ uuid } = {}, { onSuccess, onFailure } = {}) =>
  rolesApi(
    'metadata',
    { uuid },
    { method: 'DELETE', success: onSuccess, failure: onFailure }
  )

export const updatePrimaryDashboard = (
  { roleUuid, dashboardUuid } = {},
  { onSuccess, onFailure } = {}
) =>
  rolesApi(
    'primary_dashboard',
    { uuid: roleUuid, dashboard_uuid: dashboardUuid },
    { cacheable: false, method: 'PUT', success: onSuccess, failure: onFailure }
  )
