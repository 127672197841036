import apiAction from 'common/actions/api'

export const CHANGE_SINGULAR_FILTER =
  'cylera/cyberAlertInstances/CHANGE_SINGULAR_FILTER'
export const CHANGE_CYBER_ALERT_LIST_FILTER =
  'cylera/cyberAlertInstances/list/CHANGE_CYBER_ALERT_LIST_FILTER'
export const CHANGE_RISK_LIST_FILTER =
  'cylera/cyberAlertInstances/list/CHANGE_RISK_LIST_FILTER'
export const CHANGE_SINGULAR_RISK_LIST_FILTER =
  'cylera/cyberAlertInstances/list/CHANGE_SINGULAR_FILTER'
export const RESET_RISK_LIST_FILTER =
  'cylera/cyberAlertInstances/list/RESET_RISK_LIST_FILTER'
export const SET_FILTERS = 'cylera/cyberAlertInstances/list/SET_FILTERS'

const cyberAlertApi = apiAction('alert')

export const loadCyberAlertInstances = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues
) => {
  const { query, type, status, severity, showApplicableAlerts } =
    filterValues || {}

  return cyberAlertApi('cyber_alert_instances', {
    page: page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    filter_values: filterValues,
    type_filter: type,
    show_applicable_alerts: showApplicableAlerts,
    query,
    status,
    severity
  })
}

export const loadCyberAlertInstancesInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues
) => {
  const { query, type, status, severity, showApplicableAlerts } =
    filterValues || {}

  return cyberAlertApi(
    'cyber_alert_instances',
    {
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      filter_values: filterValues,
      type_filter: type,
      show_applicable_alerts: showApplicableAlerts,
      query,
      status,
      severity
    },
    { infinite: true }
  )
}

export const exportCyberAlertInstances = filterValues => {
  const { query, type, status, severity, showApplicableAlerts } =
    filterValues || {}
  return cyberAlertApi('cyber_alert_instances/export', {
    filter_values: filterValues,
    type_filter: type,
    show_applicable_alerts: showApplicableAlerts,
    query,
    status,
    severity
  })
}

export const loadCyberAlertRiskList = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues,
  cyberAlertId
) => {
  const {
    group_by,
    vulnerability_reference_severity,
    custom_query,
    detected_after
  } = filterValues || {}
  return cyberAlertApi('cyber_alert_risk_list', {
    page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    group_by,
    cyber_alert_id: cyberAlertId,
    vulnerability_reference_severity,
    custom_query,
    detected_after
  })
}

export const loadCyberAlertRiskListInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues,
  cyberAlertId
) => {
  const {
    group_by,
    vulnerability_reference_severity,
    custom_query,
    detected_after
  } = filterValues || {}
  return cyberAlertApi(
    'cyber_alert_risk_list',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      group_by,
      cyber_alert_id: cyberAlertId,
      vulnerability_reference_severity,
      custom_query,
      detected_after
    },
    { infinite: true }
  )
}

export const exportCyberAlertRiskList = (
  filterValues,
  cyberAlertId,
  callback
) => {
  const {
    group_by,
    vulnerability_reference_severity,
    custom_query,
    detected_after
  } = filterValues || {}
  return cyberAlertApi(
    'cyber_alert_risk_list/export',
    {
      group_by,
      cyber_alert_id: cyberAlertId,
      vulnerability_reference_severity,
      custom_query,
      detected_after
    },
    { cacheable: false }
  )
}

export const loadCyberAlertDetails = cyberAlertId => {
  return cyberAlertApi('cyber_alert_details', {
    cyber_alert_id: cyberAlertId
  })
}

export const loadCyberAlertMetrics = filterValues => {
  const { type, showApplicableAlerts } = filterValues || ''

  return cyberAlertApi('cyber_alert_metrics', {
    type_filter: type,
    show_applicable_alerts: showApplicableAlerts
  })
}

export function changeSingularFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_SINGULAR_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function changeCyberAlertListFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_CYBER_ALERT_LIST_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function changeRiskListFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_RISK_LIST_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function resetRiskListFilter() {
  return dispatch => {
    dispatch({
      type: RESET_RISK_LIST_FILTER
    })
  }
}

export function changeRiskListSingularFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_SINGULAR_RISK_LIST_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function setFilters(filters) {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      data: filters
    })
  }
}

export const loadRiskListFilters = () => {
  return cyberAlertApi('cyber_alert_risk_list_filters')
}

export const updateCyberAlertStatus = (
  cyberAlertId,
  status,
  successCallback,
  failureCallback
) =>
  cyberAlertApi(
    'cyber_alert_details/update_status',
    {
      cyber_alert_id: cyberAlertId,
      status
    },
    {
      method: 'POST',
      success: successCallback,
      failure: failureCallback
    }
  )
