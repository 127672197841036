import apiAction from 'common/actions/api'

export const SELECT_ROLE_TYPE = 'cylera/settings/roles/SELECT_ROLE_TYPE'

const rolesApi = apiAction('role')

export const getRoleSummary = filterValues =>
  rolesApi('summary', {}, { cacheable: false })

export function selectRoleType(selection) {
  return dispatch => {
    dispatch({
      type: SELECT_ROLE_TYPE,
      data: selection
    })
  }
}

export const getGlobalRoleNames = () =>
  rolesApi('default_roles', {}, { cacheable: false })
