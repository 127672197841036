export const FEATURE_API_ERROR_ALERT = 'API_ERROR_ALERT'
export const FEATURE_WEBSOCKET = 'WEBSOCKET'
export const FEATURE_CYBER_ALERTS = 'CYBER_ALERTS'

export type Feature = 'API_ERROR_ALERT' | 'WEBSOCKET' | 'CYBER_ALERTS'

export const FeatureEnabled = (feature: Feature, env = process.env) => {
  const featureFlag = `REACT_APP_FEATURE_ENABLED_${feature}`
  return env[featureFlag] === '1'
}
