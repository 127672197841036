import { combineReducers } from 'redux'
import { reducer as navReducer } from './Navigation/DataAccess/'
import alertsV2Reducer from './AlertsV2/reducer'
import appliancesReducer from './Settings/Appliances/reducer'
import { reducer as cyberAlertReducer } from './Risk/NHSCyberAlerts/DataAccess/'
import { reducer as dashboardReducer } from './Dashboard/DataAccess/'
import { reducer as locationsOverviewReducer } from './Navigation/components/Topbar/DataAccess/'
import { reducer as deviceReducer } from './Inventory/devices/DeviceDetail/DataAccess'
import devicesReducer from './Inventory/reducer'
import downloadReducer from './Download/reducer'
import exportReducer from './Export/reducer'
import { reducer as groupReducer } from './Inventory/groups/DataAccess/'
import { reducer as locationReducer } from './Navigation/components/Topbar/modals/LocationsFilter/DataAccess/'
import networkingReducer from './Networking/reducer'
import { reducer as reportingReducer } from './Reporting/DataAccess'
import riskReducer from './Risk/reducer'
import { reducer as settingsReducer } from './Settings/DataAccess'
import threatsReducer from './Threats/reducer'
import userReducer from './User/reducer'
import usersReducer from './Settings/Users/reducer'
import utilizationReducer from './Utilization/reducer'

export const organizationReducer = combineReducers({
  location: locationReducer
})

export const defaultPaginationParams = {
  page: 0,
  pageSize: 20,
  sortKey: undefined,
  sortDirection: undefined
}

export default combineReducers({
  export: exportReducer,
  nav: navReducer,
  alertsV2: alertsV2Reducer,
  appliances: appliancesReducer,
  cyberAlert: cyberAlertReducer,
  dashboard: dashboardReducer,
  locations: locationsOverviewReducer,
  device: deviceReducer,
  devices: devicesReducer,
  download: downloadReducer,
  group: groupReducer,
  reporting: reportingReducer,
  risk: riskReducer,
  settings: settingsReducer,
  networking: networkingReducer,
  organization: organizationReducer,
  threats: threatsReducer,
  user: userReducer,
  users: usersReducer,
  utilization: utilizationReducer
})
