import { createSelector } from 'reselect'

export const overview = (state, props) => {
  return state.main.devices.overview
}
export const organization = (state, props) => state.main.organization
export const location = createSelector(
  organization,
  organization => organization.location
)

export const stats = createSelector(
  overview,
  overview => overview && overview.get('stats')
)

export const user = (state, props) => state.auth.login.get('user')
