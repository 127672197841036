import apiAction from 'common/actions/api'

export const ADD_RULE = 'cylera/settings/notifications/ADD_RULE'
export const ADD_RULE_ACTION = 'cylera/settings/notifications/ADD_RULE_ACTION'
export const IS_EDITING_RULE = 'cylera/settings/notifications/IS_EDITING_RULE'
export const REMOVE_RULE_ACTION =
  'cylera/settings/notifications/REMOVE_RULE_ACTION'
export const UPDATE_CONDITION = 'cylera/settings/notifications/UPDATE_CONDITION'
export const UPDATE_SEVERITY = 'cylera/settings/notifications/UPDATE_SEVERITY'

const notificationsApi = apiAction('settings/organization/notifications')

export const deleteRule = (uuid, callback) =>
  notificationsApi(
    'delete',
    { uuid: uuid },
    { method: 'POST', success: callback, cacheable: false }
  )

export const loadConditions = () => notificationsApi('conditions')

export const loadSettingDetails = (uuid, callback) =>
  notificationsApi(
    'details',
    { uuid: uuid },
    { cacheable: false, success: callback }
  )

export const loadSettings = () =>
  notificationsApi('list', {}, { cacheable: false })

export const legacySaveSettingDetails = (uuid, condition, details, callback) =>
  notificationsApi(
    'save',
    {
      uuid: uuid,
      condition: condition,
      email: details.getIn(['data', 'email']),
      flash: details.getIn(['data', 'flash']),
      sms: details.getIn(['data', 'sms']),
      severity: details.getIn(['filters', 'severity'])
    },
    {
      method: 'POST',
      success: callback
    }
  )

export const saveSettingDetails = (
  uuid,
  condition,
  email,
  flash,
  sms,
  severity,
  callback
) =>
  notificationsApi(
    'save',
    {
      uuid,
      condition,
      email,
      flash,
      sms,
      severity
    },
    {
      method: 'POST',
      success: callback,
      cacheable: false
    }
  )

export const addRule = () => ({
  type: ADD_RULE
})

export const addRuleAction = (kind, val) => ({
  type: ADD_RULE_ACTION,
  kind,
  val
})

export const updateCondition = condition => ({
  type: UPDATE_CONDITION,
  condition: condition
})

export const updateSeverity = severity => ({
  type: UPDATE_SEVERITY,
  severity: severity
})

export const removeRuleAction = (kind, val) => ({
  type: REMOVE_RULE_ACTION,
  kind: kind,
  val: val
})

export const isEditingRule = (kind, val) => ({
  type: IS_EDITING_RULE,
  kind,
  val
})
