import {
  INIT_FIRESTORE,
  HANDLE_FIRESTORE_DATA,
  DELETE_FIRESTORE_DATA,
  UNSUBSCRIBE_FIRESTORE,
  handleFirestoreData,
  registerFirestoreDB,
  registerFirestoreCollection,
  deleteFirestoreData,
  registerFirestoreSubscription
} from 'common/actions/firestore'
import {
  collection,
  onSnapshot,
  query,
  doc,
  deleteDoc
} from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

export const firestoreMiddleware = store => next => action => {
  const nextAction = next(action)

  const { channel, docId, data, type: actionType } = action

  const { dispatch } = store

  const state = store.getState()
  const userUuid = state.auth.login.get('user')?.get('uuid')
  const {
    firestore: { firestore }
  } = state
  switch (actionType) {
    case INIT_FIRESTORE:
      const firebaseApp = state.firebase.firebaseApp
      if (userUuid === undefined || firebaseApp === undefined) {
        return
      }
      const firestoreFirebaseApp =
        process.env.REACT_APP_FIREBASE_FIRESTORE_PROJECT_ID !== undefined &&
        process.env.REACT_APP_FIREBASE_FIRESTORE_APP_ID !== undefined &&
        process.env.REACT_APP_FIREBASE_FIRESTORE_API_KEY !== undefined
          ? initializeApp(
              {
                apiKey: process.env.REACT_APP_FIREBASE_FIRESTORE_API_KEY,
                appId: process.env.REACT_APP_FIREBASE_FIRESTORE_APP_ID,
                projectId: process.env.REACT_APP_FIREBASE_FIRESTORE_PROJECT_ID
              },
              'firestoreConfig'
            )
          : firebaseApp

      const firestoreDB = getFirestore(firestoreFirebaseApp)
      dispatch(registerFirestoreDB(firestoreDB))

      const firestoreCollection = collection(firestoreDB, `${userUuid}/`)
      const firestoreQuery = query(firestoreCollection)
      dispatch(registerFirestoreCollection(channel, firestoreCollection))

      const subscription = onSnapshot(firestoreQuery, snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            const docId = change.doc.id
            const data = change.doc.data()
            dispatch(handleFirestoreData(channel, userUuid, docId, data))
          }
        })
      })
      dispatch(registerFirestoreSubscription(subscription))

      break

    case HANDLE_FIRESTORE_DATA:
      if (firestore && channel in firestore) {
        firestore[channel].listeners.forEach(listener => listener(data))
      }
      dispatch(deleteFirestoreData(channel, userUuid, docId))
      break
    case DELETE_FIRESTORE_DATA:
      if (state.firestore) {
        const docRef = doc(state.firestore.firestoreDB, userUuid, docId)
        deleteDoc(docRef)
      }
      break
    case UNSUBSCRIBE_FIRESTORE:
      if (
        state.firestore &&
        state.firestore.subscription &&
        state.firestore.subscription.unsubscribe
      ) {
        const unsubscribe = state.firestore.subscription
        unsubscribe()
      }
      break
    default:
      return false
  }
  return nextAction
}

export default firestoreMiddleware
