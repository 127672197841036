import { fromJS } from 'immutable'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('summary'),
  ...apiInitialState('eol_os_breakdown'),
  ...apiInitialState('risk_by_location'),
  ...apiInitialState('riskiest_models'),
  ...apiInitialState('riskiest_vendors'),
  ...apiInitialState('age_severity_map'),
  ...apiInitialState('top_mitigation'),
  ...apiInitialState('score_history')
})

function riskOverviewReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'risk', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    default:
      return state
  }
}

export default riskOverviewReducer
