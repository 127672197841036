import {
  selectors as DEVICES_SELECTORS,
  actions as DEVICES_ACTIONS
} from 'components/Main/Inventory/devices/DataAccess'
import {
  actions as THREAT_ACTIONS,
  selectors as THREAT_SELECTORS
} from 'components/Main/Threats/DataAccess/'
import { CHANGE_LOCATION } from 'components/Main/Navigation/components/Topbar/DataAccess/actions'
import { refetchForRoute } from 'common/actions/api'
import { SORT_DESC } from 'common/constants'

const defaultThreatPageSize = 30
const defaultThreatSortKey = 'last_seen'
const defaultThreatSortDirection = SORT_DESC

const filteringMiddleware = store => next => action => {
  if (action) {
    const result = next(action)
    const state = store.getState()

    if (action.type === CHANGE_LOCATION) {
      store.dispatch(refetchForRoute(window.location.href))
    }

    if (
      action.type === THREAT_ACTIONS.CHANGE_THREATS_LIST_FILTER ||
      action.type === THREAT_ACTIONS.RESET_THREATS_LIST_FILTER
    ) {
      const filterValues = THREAT_SELECTORS.filterValues(state)
      store.dispatch(
        THREAT_ACTIONS.loadThreats(
          0,
          defaultThreatPageSize,
          defaultThreatSortKey,
          defaultThreatSortDirection,
          filterValues
        )
      )
    }

    if (
      action.type === DEVICES_ACTIONS.CHANGE_FILTER ||
      action.type === DEVICES_ACTIONS.RESET_FILTERS
    ) {
      const deviceFilterValues = DEVICES_SELECTORS.filterValues(state)
      const locationFilter = deviceFilterValues.get('location_id')

      if (action.context === 'overview') {
        store.dispatch(DEVICES_ACTIONS.getSummary(locationFilter))
      }
    }

    return result
  }
}

export default filteringMiddleware
