import apiAction from 'common/actions/api'

import API from 'common/util/api'

const authApi = apiAction('auth')

export const ssoAuth = (email, successCallback, failureCallback) =>
  authApi(
    'sso_authorization_url',
    { email },
    {
      method: 'GET',
      failure: failureCallback,
      success: successCallback
    }
  )

export const ssoCallback = (code, successCallback, failureCallback) =>
  authApi(
    'sso_callback',
    { code },
    {
      method: 'GET',
      failure: failureCallback,
      success: response => {
        const { user_role, token } = response
        if (API.checkAllowedRoles(user_role)) {
          API.setToken(token)
          successCallback()
        } else {
          failureCallback('Invalid Role')
        }
      }
    }
  )
