import {
  INIT_FIRESTORE,
  REGISTER_FIRESTORE_COLLECTION,
  REGISTER_FIRESTORE_SUBSCRIPTION,
  REGISTER_FIRESTORE_DB
} from 'common/actions/firestore'

const initialState = {
  firestore: {}
}

export const firestoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FIRESTORE:
      return {
        ...state,
        firestore: {
          ...state.firestore,
          [action.channel]: {
            ...state.firestore[action.channel],
            listeners: action.listeners || []
          }
        }
      }
    case REGISTER_FIRESTORE_DB:
      return {
        ...state,
        firestore: {
          ...state.firestore,
          [action.channel]: {
            ...state.firestore[action.channel]
          }
        },
        firestoreDB: action.firestoreDB
      }
    case REGISTER_FIRESTORE_COLLECTION:
      return {
        ...state,
        firestore: {
          ...state.firestore,
          [action.channel]: {
            ...state.firestore[action.channel],
            firestoreCollection: action.firestoreCollection
          }
        }
      }
    case REGISTER_FIRESTORE_SUBSCRIPTION:
      return {
        ...state,
        firestore: {
          ...state.firestore,
          [action.channel]: {
            ...state.firestore[action.channel]
          }
        },
        subscription: action.subscription
      }

    default:
      return state
  }
}

export default firestoreReducer
