import { fromJS } from 'immutable'

import {
  SECONDS_ONE_DAY,
  SECONDS_ONE_WEEK,
  SECONDS_THIRTY_DAYS
} from 'common/constants'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('has_clinical'),
  ...apiInitialState(`percentage_and_trend_${SECONDS_ONE_DAY}`),
  ...apiInitialState(`percentage_and_trend_${SECONDS_ONE_WEEK}`),
  ...apiInitialState(`percentage_and_trend_${SECONDS_THIRTY_DAYS}`),
  ...apiInitialState('timeline_day'),
  ...apiInitialState('timeline_hour'),
  ...apiInitialState('timeline_weekday'),
  ...apiInitialState('detail')
})

function groupUtilizationDetailReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'utilization/group',
    initialState
  )
  if (apiResult) {
    return apiResult
  }

  return state
}

export default groupUtilizationDetailReducer
