import { FLASH_MESSAGE } from 'common/actions/notifications'

export const notificationsMiddleware = store => next => action => {
  const nextAction = next(action)

  const { channel, message, type: actionType } = action

  const state = store.getState()

  switch (actionType) {
    case FLASH_MESSAGE:
      const {
        notifications: { channels }
      } = state
      if (channel in channels) {
        const listeners = channels[channel].listeners
        listeners.forEach(listener => {
          listener(message)
        })
      }
      break
    default:
      return false
  }
  return nextAction
}

export default notificationsMiddleware
