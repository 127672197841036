import { selectIn } from 'common/util/selector'

const base = (state, props) => state.main.risk.detail

export const details = selectIn(base, 'details')
export const detailsLoading = selectIn(base, 'details_loading')
export const devicesTotal = selectIn(base, 'devices_total')
export const devices = selectIn(base, 'devices')
export const devicesLoading = selectIn(base, 'devices_loading')
export const devicesUnresolved = selectIn(base, 'devices_unresolved')
export const statusOptions = selectIn(base, 'status_options')
export const mitigations = selectIn(base, 'mitigations')
export const mitigationsLoading = selectIn(base, 'mitigations_loading')
export const affectedDeviceDetail = selectIn(base, 'affected_device_detail')
export const affectedDeviceDetailLoading = selectIn(
  base,
  'affected_device_detail_loading'
)
export const groupUuidFromRoute = (state, props) => {
  const uuid = props.match.params.groupUuid
  if (uuid === 'undefined') {
    return null
  } else {
    return uuid
  }
}
export const affectedModelCounts = selectIn(base, 'affected_model_counts')
export const affectedModelCountsLoading = selectIn(
  base,
  'affected_model_counts_loading'
)
export const deviceListFilterValues = selectIn(base, 'device_filter_values')
export const deviceListFilters = selectIn(base, 'device_filters')
export const deviceListFiltersLoading = selectIn(base, 'device_filters_loading')
