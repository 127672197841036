import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import { CLEAR_ROLES, SET_FILTERS } from './actions'

export const initialFilterValues = fromJS({
  query: ''
})

const initialState = fromJS({
  ...apiInitialState('list'),
  ...apiInitialState('count'),
  ...apiInitialState('global_role_names'),
  create_loading: false,
  filter_values: initialFilterValues
})

function roleReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'role', initialState)

  if (apiResult) {
    return apiResult
  }
  switch (action.type) {
    case SET_FILTERS:
      const filters = action.data.filters
      return state.merge({
        filter_values: filters
      })
    case CLEAR_ROLES:
      return state.merge({
        list: undefined,
        list_total: undefined
      })
    default:
      return state
  }
}

export default roleReducer
