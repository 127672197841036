import { fromJS } from 'immutable'

import { convertFilterValueToArray } from 'common/util/type'
import { convertDateToEpochTimestampSeconds } from 'common/util/date'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'
import {
  CHANGE_SINGULAR_FILTER,
  CHANGE_RISK_LIST_FILTER,
  CHANGE_SINGULAR_RISK_LIST_FILTER,
  RESET_RISK_LIST_FILTER,
  SET_FILTERS,
  CHANGE_CYBER_ALERT_LIST_FILTER
} from './actions'

export const initialFilterValues = fromJS({
  query: '',
  showApplicableAlerts: true
})

export const initialRiskListFilterValues = fromJS({
  device_type_name: [],
  device_vendor_name: [],
  vulnerability_reference_name: [],
  vulnerability_reference_category: [],
  vulnerability_reference_severity: [],
  owner: undefined,
  status: '1', // show unresolved only
  custom_query: '',
  detected_after: '21600'
})

const initialState = fromJS({
  filter_values: initialFilterValues,
  cyber_alert_risk_list_filter_values: initialRiskListFilterValues,
  ...apiInitialState('cyber_alert_instances'),
  ...apiInitialState('cyber_alert_details'),
  ...apiInitialState('cyber_alert_metrics'),
  ...apiInitialState('cyber_alert_risk_list'),
  ...apiInitialState('cyber_alert_risk_list_filters')
})

function cyberAlertReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'alert', initialState)

  if (apiResult) {
    return apiResult
  }

  const { filter, value } = action.data || {}
  switch (action.type) {
    case CHANGE_SINGULAR_FILTER:
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filter]: value
        })
      )
    case CHANGE_CYBER_ALERT_LIST_FILTER:
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filter]: convertFilterValueToArray(value)
        })
      )
    case CHANGE_RISK_LIST_FILTER:
      if (filter === 'detected_after') {
        return state.mergeIn(
          ['cyber_alert_risk_list_filter_values'],
          fromJS({
            [filter]: convertDateToEpochTimestampSeconds(value)
          })
        )
      } else {
        return state.mergeIn(
          ['cyber_alert_risk_list_filter_values'],
          fromJS({
            [filter]: convertFilterValueToArray(value)
          })
        )
      }
    case CHANGE_SINGULAR_RISK_LIST_FILTER:
      return state.mergeIn(
        ['cyber_alert_risk_list_filter_values'],
        fromJS({
          [filter]: value
        })
      )
    case RESET_RISK_LIST_FILTER:
      return state.mergeIn(
        ['cyber_alert_risk_list_filter_values'],
        initialState.get('cyber_alert_risk_list_filter_values')
      )
    case SET_FILTERS:
      return state.merge({
        cyber_alert_risk_list_filter_values: action.data
      })
    default:
      return state
  }
}

export default cyberAlertReducer
