import asyncComponent from 'common/components/AsyncComponent'
import { LinkIcon } from '@heroicons/react/outline'

export const ROUTE_INTEGRATIONS = '/app/integrations'
export const ROUTE_INTEGRATION_DETAILS = '/app/integrations/:uuid'
export const ROUTE_INTEGRATION_ADD_INSTANCE = '/app/integrations/:addInstance'

export const integrationsRoutes = [
  {
    route: ROUTE_INTEGRATIONS,
    component: asyncComponent(() => import('components/Main/Integrations'), {
      retryKey: 'integrations'
    }),
    icon: 'os-icon-link-3',
    heroIcon: <LinkIcon />,
    title: 'Integrations',
    navbar: true,
    order: 8,
    subContexts: [/app\/integration(.*?)/],
    engine: 'integration_engine'
  },
  {
    route: ROUTE_INTEGRATION_DETAILS,
    component: asyncComponent(() =>
      import('components/Main/Integrations/IntegrationsDetail')
    ),
    exact: false,
    parent: ROUTE_INTEGRATIONS,
    title: 'Integration Detail'
  },
  {
    route: ROUTE_INTEGRATION_ADD_INSTANCE,
    component: asyncComponent(
      () => import('components/Main/Integrations/IntegrationsDetail'),
      { retryKey: 'integrations-detail' }
    ),
    modal: false,
    parent: ROUTE_INTEGRATIONS,
    title: 'Add Integration Instance'
  }
]

export default integrationsRoutes
