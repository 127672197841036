import React from 'react'

class Filter {
  /** @args {Object} { label : String, value : String, placeholder : String, onAdd : Filter => {}, onRemove : Filter => {} } */
  constructor(args) {
    const {
      label,
      value,
      placeholder = '',
      titleCase = false,
      managed = false,
      onAdd = _ => {},
      onRemove = _ => {}
    } = args
    this.label = label
    this.value = value
    this.placeholder = placeholder // only for text filters
    this.titleCase = titleCase // only for category filters
    this.managed = managed // for filters not controllable by the user
    this.onAdd = onAdd
    this.onRemove = onRemove
    this.usesOptions = false // override in subclasses if needed
  }

  /** MAY NEED TO OVERRIDE - VDOM key to force re-mounting, uses column name by default */
  getBuilderKey = () => this.value
  /** MUST OVERRIDE - Class reference for React component */
  getBuilderComponentType = () => {
    console.error('Not implemented!')
    throw new Error(
      'Filters using the FilterBuilder framework must implement getBuilderComponentType'
    )
  }
  /** React component live instance */
  createBuilderComponent = props => {
    const { onSubmit, onChange } = props
    const componentType = this.getBuilderComponentType()
    return React.createElement(
      componentType,
      { key: this.getBuilderKey(), filter: this, onSubmit, onChange },
      null
    )
  }

  /** MAY NEED TO OVERRIDE - Returns new instance with options (if applicable) */
  withOptions = _ => {
    console.error('Not implemented!')
    return this
  }

  /** MUST OVERRIDE - Returns new instance with settings */
  withSettings = _ => {
    console.error('Not implemented!')
    return this
  }

  /** MUST OVERRIDE - Human-readable description (requires settings) */
  getDescription = () => {
    console.error('Not implemented!')
  }

  /** MUST OVERRIDE - VDOM key to force uniqueness in an array (requires settings) */
  getUniqueKey = () => {
    console.error('Not implemented!')
  }

  /** MUST OVERRIDE - Query object for use in the search request (requires settings) */
  getQuery = () => {
    console.error('Not implemented!')
  }

  /** Force a static description - Useful for managed filters */
  withDescriptionOverride = description => {
    return Object.assign(this, { getDescription: () => description })
  }

  static serializeFilters = filters => {
    return filters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.value]: JSON.stringify(filter.getQuery())
      }),
      {}
    )
  }
}

export default Filter
