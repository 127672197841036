import { combineReducers } from 'redux'
import { reducer as riskListReducer } from './hooks/DataAccess'
import { reducer as riskDetailReducer } from './RiskDetail/DataAccess'
import { reducer as riskOverviewReducer } from './DataAccess/'

export default combineReducers({
  risks: riskListReducer,
  detail: riskDetailReducer,
  overview: riskOverviewReducer
})
