import { fromJS } from 'immutable'

import apiAction from 'common/actions/api'

export const CHANGE_SEEN_SINCE = 'cylera/devices/CHANGE_SEEN_SINCE'
export const CHANGE_NOT_SEEN_SINCE = 'cylera/devices/CHANGE_NOT_SEEN_SINCE'
export const PERSIST_FILTERS = 'cylera/devices/PERSIST_FILTERS'
export const CHANGE_FILTER = 'cylera/devices/CHANGE_FILTER'
export const CHANGE_SINGULAR_FILTER = 'cylera/devices/CHANGE_SINGULAR_FILTER'
export const RESET_FILTERS = 'cylera/devices/RESET_FILTERS'
export const SET_FILTERS = 'cylera/devices/SET_FILTERS'
export const SET_SORT = 'cylera/devices/SET_SORT'
export const CLEAR_FILTER_OPTIONS = 'cylera/devices/CLEAR_FILTER_OPTIONS'
export const SET_DEVICES_COLUMN_VISIBILITIES =
  'cylera/devices/SET_DEVICES_COLUMN_VISIBILITIES'
export const UPDATE_QUERY_FILTER = 'cylera/devices/UPDATE_QUERY_FILTER'
export const PERSIST_SELECTED_VIEW = 'cylera/devices/PERSIST_SELECTED_VIEW'
export const APPLY_VIEW = 'cylera/devices/APPLY_VIEW'

const devicesApi = apiAction('devices', '/device')

export const getSummary = locationId =>
  devicesApi('summary', {
    params: {
      location_id: locationId
    }
  })

export const getFilterOptions = () => devicesApi('filter_options')
export const getTagFilterOptions = (kind, filters) =>
  devicesApi(
    `${kind}_filter_options`,
    filters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.value]: JSON.stringify(filter.getQuery())
      }),
      {}
    )
  )
export const getTagFilterOptionsNoProcessing = (kind, filters) =>
  devicesApi(`${kind}_filter_options`, filters)

export const searchDevicesAdvanced = (
  filters,
  page,
  sortKey,
  sortDirection,
  groupUuid,
  pageSize
) =>
  devicesApi(
    'advanced_search:devices',
    filters.reduce(
      (acc, filter) =>
        filter
          ? {
              ...acc,
              [filter.value]: JSON.stringify(filter.getQuery())
            }
          : acc,
      {
        page,
        sort_key: sortKey,
        sort_direction: sortDirection,
        group_uuid: groupUuid,
        page_size: pageSize
      }
    )
  )

export const searchDevicesAdvancedInfinite = (
  filters,
  page,
  sortKey,
  sortDirection,
  groupUuid,
  pageSize
) =>
  devicesApi(
    'advanced_search:devices',
    filters.reduce(
      (acc, filter) =>
        filter
          ? {
              ...acc,
              [filter.value]: JSON.stringify(filter.getQuery())
            }
          : acc,
      {
        page,
        sort_key: sortKey,
        sort_direction: sortDirection,
        group_uuid: groupUuid,
        page_size: pageSize
      }
    ),
    { infinite: true, cacheable: false }
  )

export const exportDevicesAdvanced = (filters, groupUuid) =>
  devicesApi(
    'advanced_export',
    filters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.value]: JSON.stringify(filter.getQuery())
      }),
      { group_uuid: groupUuid }
    ),
    { cacheable: false }
  )

export const saveDeviceSuppressionRules = (
  filters,
  description,
  success,
  failure
) =>
  devicesApi(
    'add_suppression_rules',
    filters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.value]: JSON.stringify(filter.getQuery())
      }),
      { description: description }
    ),
    {
      method: 'POST',
      success,
      failure
    }
  )

export function persistFilters(activeFilters, presetFilters) {
  return dispatch => {
    dispatch({
      type: PERSIST_FILTERS,
      data: {
        active: activeFilters,
        preset: presetFilters
      }
    })
  }
}

export function updateQueryFilter(newQueryFilter) {
  return dispatch => {
    dispatch({
      type: UPDATE_QUERY_FILTER,
      data: newQueryFilter
    })
  }
}

export function changeFilter(filter, value, context = 'search') {
  return dispatch => {
    dispatch({
      type: CHANGE_FILTER,
      context: context,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function changeSeenSince(value) {
  return dispatch => {
    dispatch({
      type: CHANGE_SEEN_SINCE,
      data: value
    })
  }
}

export function changeNotSeenSince(value) {
  return dispatch => {
    dispatch({
      type: CHANGE_NOT_SEEN_SINCE,
      data: value
    })
  }
}

export function changeSingularFilter(filter, value, context = 'search') {
  return dispatch => {
    dispatch({
      type: CHANGE_SINGULAR_FILTER,
      context: context,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function setFilters(filters) {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      data: filters
    })
  }
}

export function resetFilters(pinnedFilters = fromJS({}), context = 'search') {
  return dispatch => {
    dispatch({
      type: RESET_FILTERS,
      context: context,
      data: pinnedFilters
    })
  }
}

export function clearFilterOptions() {
  return dispatch => {
    dispatch({
      type: CLEAR_FILTER_OPTIONS
    })
  }
}

export function setDevicesColumnVisibilities(devicesColumnVisibilities) {
  return dispatch => {
    dispatch({
      type: SET_DEVICES_COLUMN_VISIBILITIES,
      data: devicesColumnVisibilities
    })
  }
}

export const getViews = () => devicesApi('views')

export const saveView = (name, filters, value, { success, failure }) =>
  devicesApi(
    'views',
    {
      view_name: name,
      view_filters: filters,
      view_uuid: value
    },
    {
      method: 'POST',
      success,
      failure
    }
  )

export const deleteView = (uuid, { success, failure }) =>
  devicesApi(
    'views',
    { view_uuid: uuid },
    {
      method: 'DELETE',
      success,
      failure
    }
  )

export function applyView(filters) {
  return dispatch => {
    dispatch({
      type: APPLY_VIEW,
      data: filters
    })
  }
}

export function persistSelectedView(view) {
  return dispatch => {
    dispatch({
      type: PERSIST_SELECTED_VIEW,
      data: view
    })
  }
}

export function setSort({ sortKey, sortDirection } = {}) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: {
        sort_key: sortKey,
        sort_direction: sortDirection
      }
    })
  }
}
