import ReactGA from 'react-ga'
import * as actions from '../actions/analytics'
import * as authSelectors from '../selectors/login'
import { scrubUuids } from '../util/string'

const analyticsMiddleware = store => next => action => {
  if (action) {
    const state = store.getState()
    const isSystemUser = authSelectors.isSystemUser(state, {})

    if (isSystemUser || process.env.REACT_APP_GA_TRACKING_ID === undefined) {
      // TODO: track using custom dimension/alternate tracking ID
      // https://github.com/react-ga/react-ga/issues/265
      return next(action)
    }
    if (action.type === actions.INIT_TRACKING) {
      const { testMode } = action
      const params = {
        testMode: testMode,
        titleCase: false
      }
      ReactGA.set({ user: authSelectors.userEmail(state, {}) })
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, params)
      store.dispatch(actions.trackPageView({ location: window.location }))
      window.GA_INITIALIZED = true
    }

    if (!window.GA_INITIALIZED) {
      return next(action)
    }

    if (action.type === actions.TRACK_AGGREGATE_PAGE_VIEW) {
      const { hash, pathname, search } = action.location
      const scrubbedPathname = scrubUuids(pathname, '{UUID}')

      ReactGA.event({
        category: 'Distributive Views',
        action: `${pathname}${hash}${search}`
      })
      ReactGA.pageview(`${scrubbedPathname}${hash}${search}`)
    }

    if (action.type === actions.TRACK_PAGE_VIEW) {
      const { hash, pathname, search } = action.location
      const scrubbedPathname = scrubUuids(pathname, '{UUID}')
      ReactGA.pageview(`${scrubbedPathname}${hash}${search}`)
    }

    if (action.type === actions.REGISTER_SOURCE) {
      const {
        response: {
          user: { email, name, organization }
        }
      } = action
      ReactGA.event({
        category: 'User session',
        action: `${organization} - ${name}`
      })
      ReactGA.set({
        email,
        name,
        organization
      })
    }

    return next(action)
  }
}

export default analyticsMiddleware
