import asyncComponent from 'common/components/AsyncComponent'
import { ThreatIcon } from 'icons/ThreatIcon'

export const ROUTE_THREATS = '/app/threats'
export const ROUTE_THREAT_DETAILS = '/app/threat/:uuid'

export const threatsRoutes = [
  {
    route: ROUTE_THREATS,
    component: asyncComponent(() => import('components/Main/Threats/'), {
      retryKey: 'threats'
    }),
    title: 'Threats',
    navbar: true,
    order: 4,
    icon: 'os-icon-ui-09',
    heroIcon: <ThreatIcon />,
    subContexts: [/app\/threat(.*?)/],
    engine: 'threat_engine'
  },
  {
    route: ROUTE_THREAT_DETAILS,
    component: asyncComponent(
      () => import('components/Main/Threats/ThreatDetail'),
      { retryKey: 'threat-detail' }
    ),
    parent: ROUTE_THREATS,
    title: 'Threat Details',
    exact: false
  }
]

export default threatsRoutes
