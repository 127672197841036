import { matchPath } from 'react-router'

export function parseQueryString(queryString) {
  if (!queryString) return {}

  queryString = queryString.replace('?', '')
  const queryParts = queryString.split('&')

  const reducer = (accumulator, queryPart) => {
    const paramParts = queryPart.split('=')
    const paramKey = paramParts[0]
    const paramValue = paramParts[1]
    const paramValues = paramValue.split(',')
    accumulator[paramKey] = paramValues
    return accumulator
  }

  const queryParams = queryParts.reduce(reducer, {})
  return queryParams
}

// Because match prop is <Route> relative:
// see: https://github.com/ReactTraining/react-router/issues/5870
// and: https://github.com/ReactTraining/react-router/issues/5870#issuecomment-394194338
export const pathParams = (props, path) =>
  matchPath(props.history.location.pathname, {
    path: path,
    exact: false,
    strict: false
  })
