import asyncComponent from 'common/components/AsyncComponent'
import InventoryIcon from 'icons/InventoryIcon'
export const ROUTE_DEVICES = '/app/devices/'
export const ROUTE_DEVICES_INVENTORY = '/app/devices/inventory'
export const ROUTE_DEVICES_OVERVIEW = '/app/devices/overview'
export const ROUTE_DEVICES_ATTRIBUTES = '/app/devices/attributes'
export const ROUTE_INVENTORY_DEVICE = '/app/inventory/device/:id'
export const ROUTE_INVENTORY_DEVICE_COMMUNICATION =
  '/app/inventory/device/communication/:id'
export const ROUTE_INVENTORY_DEVICE_COMMUNICATION_NETFLOWS =
  '/app/inventory/device/communication/netflows/:id'
export const ROUTE_INVENTORY_GROUP = '/app/inventory/group/:id'
export const ROUTE_INVENTORY_ATTRIBUTE = '/app/inventory/attribute'
export const ROUTE_DEVICES_SUPPRESSED = '/app/devices/suppressed'
export const ROUTE_INVENTORY_CUSTOM_ATTRIBUTE =
  '/app/inventory/custom_attribute/:id'

export const devicesRoutes = [
  // Devices Tabs
  {
    route: ROUTE_DEVICES,
    exact: false,
    component: asyncComponent(() => import('components/Main/Inventory'), {
      retryKey: 'inventory'
    })
  },
  {
    route: ROUTE_DEVICES_OVERVIEW,
    component: asyncComponent(() => import('components/Main/Inventory/'), {
      retryKey: 'device-groups'
    }),
    icon: 'os-icon-hierarchy-structure-2',
    heroIcon: <InventoryIcon />,
    title: 'Inventory',
    tabTitle: 'Groups',
    navbar: true,
    order: 2,
    subContexts: [/app\/(device|group|inventory)(.*?)/],
    children: [
      { name: 'Groups', path: ROUTE_DEVICES_OVERVIEW },
      { name: 'Devices', path: ROUTE_DEVICES_INVENTORY },
      { name: 'Attributes', path: ROUTE_DEVICES_ATTRIBUTES }
    ],
    engine: 'device_engine'
  },
  {
    route: ROUTE_DEVICES_INVENTORY,
    parent: ROUTE_DEVICES_OVERVIEW,
    title: 'Devices'
  },
  {
    route: ROUTE_DEVICES_ATTRIBUTES,
    parent: ROUTE_DEVICES_OVERVIEW,
    title: 'Attributes'
  },
  {
    route: ROUTE_DEVICES_SUPPRESSED,
    parent: ROUTE_DEVICES_OVERVIEW,
    title: 'Suppressed Devices'
  },
  {
    route: ROUTE_INVENTORY_GROUP,
    component: asyncComponent(
      () =>
        import(
          'components/Main/Inventory/groups/GroupInventory/GroupInventory'
        ),
      { retryKey: 'group-inventory' }
    ),
    parent: ROUTE_DEVICES_OVERVIEW,
    title: 'Device Group'
  },
  // Device Details Tabs
  {
    route: ROUTE_INVENTORY_DEVICE,
    component: asyncComponent(
      () =>
        import('components/Main/Inventory/devices/DeviceDetail/DeviceDetail'),
      {
        retryKey: 'device-detail'
      }
    ),
    parent: ROUTE_DEVICES_INVENTORY,
    title: 'Device Detail'
  },
  {
    route: ROUTE_INVENTORY_DEVICE_COMMUNICATION,
    component: asyncComponent(
      () =>
        import(
          'components/Main/Inventory/devices/DeviceDetail/DeviceCommunication/'
        ),
      { retryKey: 'device-communication' }
    ),
    parent: ROUTE_INVENTORY_DEVICE,
    title: 'Device Communication'
  },
  {
    route: ROUTE_INVENTORY_DEVICE_COMMUNICATION_NETFLOWS,
    component: asyncComponent(
      () =>
        import(
          'components/Main/Inventory/devices/DeviceDetail/DeviceCommunication/DeviceNetflows'
        ),
      { retryKey: 'device-netflows' }
    ),
    parent: ROUTE_INVENTORY_DEVICE_COMMUNICATION,
    title: 'Device Netflows'
  },
  {
    route: ROUTE_INVENTORY_ATTRIBUTE,
    component: asyncComponent(
      () => import('components/Main/Inventory/attributes/attribute/Attribute'),
      {
        retryKey: 'attribute'
      }
    ),
    parent: ROUTE_DEVICES_ATTRIBUTES,
    title: 'Attribute Detail'
  },
  {
    route: ROUTE_INVENTORY_CUSTOM_ATTRIBUTE,
    component: asyncComponent(
      () =>
        import(
          'components/Main/Inventory/attributes/CustomAttributes/CustomAttributeDetail/CustomAttributeDetail'
        ),
      {
        retryKey: 'custom-attribute'
      }
    ),
    parent: ROUTE_DEVICES_ATTRIBUTES,
    title: 'Custom Attribute Detail'
  }
]
export default devicesRoutes
