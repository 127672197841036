import apiAction from 'common/actions/api'

export const SET_APPLIANCE_QUERY = 'cylera/appliances/SET_APPLIANCE_QUERY'
export const SET_APPLIANCE_UNHEALTHY_ONLY =
  'cylera/appliances/SET_APPLIANCE_UNHEALTHY_ONLY'

const applianceApi = apiAction('appliance')

export const getAppliances = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  query,
  unhealthyOnly,
  callback
) => {
  return applianceApi(
    'list:appliances',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query: query,
      unhealthy_only: unhealthyOnly
    },
    { success: callback }
  )
}

export const getAppliancesInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  query,
  unhealthyOnly,
  callback
) => {
  return applianceApi(
    'list:appliances',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query: query,
      unhealthy_only: unhealthyOnly
    },
    { success: callback, infinite: true }
  )
}

export const getApplianceStatus = () => applianceApi('appliance_status')

export function setApplianceQuery(value) {
  return dispatch => {
    dispatch({
      type: SET_APPLIANCE_QUERY,
      data: value
    })
  }
}

export function setApplianceUnhealthyOnly(value) {
  return dispatch => {
    dispatch({
      type: SET_APPLIANCE_UNHEALTHY_ONLY,
      data: value
    })
  }
}
