import { selectIn } from 'common/util/selector'

const base = (state, props) => state.main.users.list

export const users = selectIn(base, 'users')
export const usersLoading = selectIn(base, 'users_loading')
export const usersTotal = selectIn(base, 'users_total')
export const sortKey = selectIn(base, 'sort_key')
export const sortDirection = selectIn(base, 'sort_direction')
export const query = selectIn(base, 'query')
export const roleOptions = selectIn(base, 'roles')
export const roleOptionsLoading = selectIn(base, 'roles_loading')
export const roleOptionsTotal = selectIn(base, 'roles_total')
