import { fromJS } from 'immutable'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'
import {
  SET_EXTERNAL_COMMUNICATION_QUERY,
  SET_GROUP_BY_COLUMNS,
  CLEAR_EXTERNAL_COMMUNICATIONS
} from './actions'

const initialState = fromJS({
  ...apiInitialState('external_communication'),
  ...apiInitialState('device_external_communication'),
  custom_query: '',
  group_by_columns: ['partner_country_code']
})

function externalCommunicationReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'network', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case SET_EXTERNAL_COMMUNICATION_QUERY:
      return state.merge({
        custom_query: action.data
      })
    case SET_GROUP_BY_COLUMNS:
      return state.merge({
        group_by_columns: action.data
      })
    case CLEAR_EXTERNAL_COMMUNICATIONS:
      return initialState
    default:
      return state
  }
}

export default externalCommunicationReducer
