import apiAction from 'common/actions/api'

const reportingApi = apiAction('reporting')

const locationApi = apiAction('organization/location')

export const getLocations = callback => {
  return locationApi('list', {}, { success: callback })
}

export const getReportDownload = (uuid, callback) =>
  reportingApi('download', { uuid }, { success: callback })

export const getReportFrequencies = () => reportingApi('frequencies')

export const getReportKinds = () => reportingApi('kinds')

export const getScheduledReports = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  frequencies
) =>
  reportingApi(
    'scheduled',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      frequency: frequencies
    },
    {
      cacheable: false
    }
  )

export const getScheduledReportsInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  frequencies
) =>
  reportingApi(
    'scheduled',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      frequency: frequencies
    },
    {
      cacheable: false,
      infinite: true
    }
  )

export const getGeneratedReports = () => reportingApi('generated')

export const getDownloadToken = (uuid, callback, failureCallback) =>
  reportingApi(
    'token',
    { report_schedule_execution_uuid: uuid },
    { success: callback, failure: failureCallback, cacheable: false }
  )

export const unscheduleReport = (uuid, callback) =>
  reportingApi(
    'unschedule',
    { schedule_uuid: uuid },
    { method: 'POST', success: callback, cacheable: false }
  )

export const getReportDetails = (reportKindUuid, callback) =>
  reportingApi(
    'details',
    { report_kind_uuid: reportKindUuid },
    { method: 'GET', success: callback }
  )

export const scheduleReport = (
  kind,
  frequency,
  delivery,
  location,
  recipient,
  newRecipientEmail,
  values,
  reportName,
  successCallback,
  failureCallback
) =>
  reportingApi(
    'schedule',
    {
      delivery,
      frequency,
      location,
      kind,
      recipient,
      values,
      new_recipient_email: newRecipientEmail,
      name: reportName
    },
    {
      method: 'POST',
      success: successCallback,
      failure: failureCallback,
      cacheable: false
    }
  )

export const getReportRecipients = () => reportingApi('recipients')
