import apiAction from 'common/actions/api'

const usersApi = apiAction('users', '/user')

export const RESET_USERS_LIST = 'cylera/users/RESET_USERS_LIST'
export const SET_SORT = 'cylera/users/SET_SORT'
export const SET_QUERY = 'cylera/users/SET_QUERY'

export const exportUsers = query =>
  usersApi('export', { query }, { cacheable: false })

export const searchUsers = (page, pageSize, sortKey, sortDirection, query) =>
  usersApi(
    'search:users',
    {
      query: query,
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection
    },
    { cacheable: false }
  )

export const searchUsersInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  id
) => {
  return usersApi(
    'search:users',
    {
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      subnet_id: id
    },
    { infinite: true, cacheable: false }
  )
}

export const addNewUser = (user, success, failure) => {
  return usersApi(
    'add',
    {
      role_uuid: user.role_uuid,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      password: user.password ?? '',
      generate_temp_password: user.generate_temp_password,
      owner_uuid: user.owner
    },
    {
      method: 'POST',
      success: success,
      failure: failure,
      cacheable: false
    }
  )
}

export function resetUsersList() {
  return dispatch => {
    dispatch({
      type: RESET_USERS_LIST
    })
  }
}

export function setSort({ sortKey, sortDirection } = {}) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: {
        sort_key: sortKey,
        sort_direction: sortDirection
      }
    })
  }
}

export function setQuery(query) {
  return dispatch => {
    dispatch({
      type: SET_QUERY,
      data: query
    })
  }
}

export const getRoleOptions = () => usersApi('roles', {}, { cacheable: false })
