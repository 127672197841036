import { combineReducers } from 'redux'
import organizationReducer from './organization/reducer'
import userReducer from '../../Navigation/components/Topbar/modals/MFA/reducer'
import { reducer as accountReducer } from '../Account/DataAccess'
import { reducer as customDashboardsReducer } from '../CustomDashboards/DataAccess'
import {
  dashboardReducer,
  widgetReducer
} from '../CustomDashboards/CustomDashboardEditor/DataAccess'
import { reducer as roleReducer } from '../Roles/DataAccess'
import { reducer as defaultRoleReducer } from '../Roles/DefaultRoles/DataAccess'
import { reducer as customRoleReducer } from '../Roles/CustomRoles/DataAccess'
import { reducer as roleEditorReducer } from '../Roles/RoleEditor/DataAccess'

export default combineReducers({
  organization: organizationReducer,
  user: userReducer,
  account: accountReducer,
  dashboards: customDashboardsReducer,
  dashboard: dashboardReducer,
  widgets: widgetReducer,
  roles: roleReducer,
  default_roles: defaultRoleReducer,
  custom_roles: customRoleReducer,
  role: roleEditorReducer
})
