import { createSelector } from 'reselect'

const resetPassword = (state, props) => state.auth.reset_password

export const error = createSelector(
  resetPassword,
  resetPassword =>
    resetPassword.getIn(['reset_password_error', 'token', 0]) ||
    resetPassword.getIn(['reset_password_error', 'password', 0]) ||
    resetPassword.getIn(['reset_password_error', 'confirm_password', 0]) ||
    resetPassword.get('reset_password_error')
)
export const isLoading = createSelector(resetPassword, resetPassword =>
  resetPassword.get('change_password_loading')
)
