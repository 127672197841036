import { createSelector } from 'reselect'

const verify = (state, props) => state.auth.verify

export const error = createSelector(
  verify,
  verify =>
    verify.getIn(['verify_error', 'verification_code', 0]) ||
    verify.get('verify_error')
)
export const isLoading = createSelector(verify, verify =>
  verify.get('verify_loading')
)
