import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  registration: undefined,
  registration_error: undefined,
  registration_loading: true
})

function mfaReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'settings/mfa')
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    default:
      return state
  }
}

export default mfaReducer
