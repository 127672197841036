import { createSelector } from 'reselect'

const notifications = (state, props) =>
  state.main.settings.organization.notifications

export const condition = createSelector(notifications, notifications =>
  notifications.get('condition')
)
export const conditions = createSelector(notifications, notifications =>
  notifications.get('conditions')
)

export const conditionsLoading = createSelector(notifications, notifications =>
  notifications.get('conditions_loading')
)
export const details = createSelector(notifications, notifications =>
  notifications.get('details')
)
export const detailsLoading = createSelector(notifications, notifications =>
  notifications.get('details_loading')
)
export const detailsUnsavedAction = createSelector(
  notifications,
  notifications => notifications.get('details_unsaved_action')
)
export const detailsUnsavedChanges = createSelector(
  notifications,
  notifications => notifications.get('details_unsaved_changes')
)
export const list = createSelector(notifications, notifications =>
  notifications.get('list')
)
export const listLoading = createSelector(notifications, notifications =>
  notifications.get('list_loading')
)
export const ruleUuidFromRoute = (state, props) => props.match.params.uuid
