import { createSelector } from 'reselect'
import { safeAccess } from 'common/util/iterable'
import { selectIn } from 'common/util/selector'
import { pathParams } from 'common/util/url'

export const deviceUuid = (state, props) =>
  state.main.device.info.getIn(['details', 'uuid'])

export const base = state => state.main.device.info

export const hasClinicalUtilization = selectIn(base, 'has_clinical')
export const hasClinicalUtilizationLoading = selectIn(
  base,
  'has_clinical_loading'
)
export const hasUtilization = selectIn(base, 'has_utilization')
export const hasUtilizationLoading = selectIn(base, 'has_utilization_loading')

const bandwidth = state => state.main.device.info.get('bandwidth')
const bandwidthLoading = state =>
  state.main.device.info.get('bandwidth_loading')
const bandwidthRangeHours = state =>
  state.main.device.info.get('bandwidth_range_hours')
const bandwidthUnauthorized = state =>
  state.main.device.info.get('bandwidth_unauthorized')

const attributes = state => state.main.device.info.get('attributes')
const attributesLoading = state =>
  state.main.device.info.get('attributes_loading')
const device = state => state.main.device.info.get('details')
const deviceDetailsLoading = state =>
  state.main.device.info.get('details_loading')
const deviceIdFromRoute = props => {
  const id = props.match.params.id
  if (id) {
    return id
  }
  return safeAccess(pathParams(props, '/app/device/:deviceUuid'), [
    'params',
    'deviceUuid'
  ])
}
const deviceUtilizationLoading = state =>
  state.main.device.info.get('utilization_loading')
const deviceUtilization = state => state.main.device.info.get('utilization')
const cves = (state, props) => state.main.device.info.get('cves')
const cvesLoading = (state, props) => state.main.device.info.get('cves_loading')
const cvesUnauthorized = (state, props) =>
  state.main.device.info.get('cves_unauthorized')
const alerts = (state, props) => state.main.device.info.get('alerts')
const alertsLoading = (state, props) =>
  state.main.device.info.get('alerts_loading')
const alertsPerPage = (state, props) =>
  state.main.device.info.get('alerts_page_size')
const alertsTotal = (state, props) => state.main.device.info.get('alerts_total')
const threats = (state, props) => state.main.device.info.get('threats')
const threatsLoading = (state, props) =>
  state.main.device.info.get('threats_loading')
const threatsUnauthorized = (state, props) =>
  state.main.device.info.get('threats_unauthorized')
const threatSeverityDescending = (a, b) => a.get('severity') - b.get('severity')
const deviceThreats = createSelector(
  threats,
  threats =>
    threats && threats.get('device_threats').sort(threatSeverityDescending)
)

const summary = state => state.main.devices.inventory.get('summary')

const deviceId = createSelector(device, device =>
  device ? device.get('uuid') : undefined
)
const hasRisks = (state, props) => state.main.device.info.get('has_risks')
const hasThreats = (state, props) => state.main.device.info.get('has_threats')
const policy = (state, props) => state.main.device.info.get('policy')
const policyLoading = (state, props) =>
  state.main.device.info.get('policy_loading')
const policyUnauthorized = (state, props) =>
  state.main.device.info.get('policy_unauthorized')
const createPolicyUuid = (state, props) =>
  state.main.device.info.get('create_policy')
const utilizationLoading = selectIn(base, 'utilization_detail_loading')
const utilization = selectIn(base, 'utilization_detail')
const utilizationUnauthorized = selectIn(
  base,
  'utilization_detail_unauthorized'
)
const commsOverview = selectIn(base, 'comms_overview')
const commsOverviewLoading = selectIn(base, 'comms_overview_loading')
const commsOverviewUnauthorized = selectIn(base, 'comms_overview_unauthorized')
const commsSearch = selectIn(base, 'comms_search')
const commsSearchLoading = selectIn(base, 'comms_search_loading')
const commsSearchTotal = selectIn(base, 'comms_search_total')
const netflowFilters = selectIn(base, 'comms_search_filters')

export {
  attributes,
  attributesLoading,
  bandwidth,
  bandwidthLoading,
  bandwidthRangeHours,
  bandwidthUnauthorized,
  createPolicyUuid,
  cves,
  cvesLoading,
  cvesUnauthorized,
  device,
  deviceId,
  deviceIdFromRoute,
  deviceDetailsLoading,
  deviceThreats,
  deviceUtilizationLoading,
  deviceUtilization,
  hasRisks,
  hasThreats,
  alerts,
  alertsLoading,
  alertsPerPage,
  alertsTotal,
  summary,
  threatsLoading,
  threatsUnauthorized,
  policy,
  policyLoading,
  policyUnauthorized,
  utilizationLoading,
  utilization,
  utilizationUnauthorized,
  commsOverview,
  commsOverviewLoading,
  commsOverviewUnauthorized,
  commsSearch,
  commsSearchLoading,
  commsSearchTotal,
  netflowFilters
}
