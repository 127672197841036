import { combineReducers } from 'redux'

import policiesReducer from './Policies/reducer'
import subnetsReducer from './Subnets/reducer'
import { reducer as externalCommunicationReducer } from './ExternalCommunication/DataAccess/'

export default combineReducers({
  policies: policiesReducer,
  subnets: subnetsReducer,
  external_communication: externalCommunicationReducer
})
