import { fromJS } from 'immutable'
import { convertFilterValueToArray } from 'common/util/type'
import { apiNamespaceReducer } from 'common/reducers/api'
import {
  CHANGE_FILTER,
  CHANGE_SINGULAR_FILTER,
  RESET_FILTERS,
  SET_GROUP_UUID,
  PERSIST_GROUP_FILTERS,
  SET_GROUP_COLUMN_VISIBILITIES,
  CLEAR_DEVICE_GROUP_DETAILS,
  UPDATE_QUERY_FILTER,
  SET_SORT
} from './actions'
import {
  initialFilters,
  initialFilterValues
} from 'components/Main/Inventory/devices/DataAccess/reducer'

export const devicesGroupColumnVisibilities = fromJS({
  ip_address: { label: 'IP Address', visible: true },
  'inventory_status.device_class': { label: 'Class', visible: true },
  mac_address: { label: 'MAC Address', visible: true },
  vlan: { label: 'VLAN', visible: false },
  hostname: { label: 'Hostname', visible: true },
  risk: { label: 'Risk', visible: true },
  tag_type: { label: 'Type', visible: true },
  tag_model: { label: 'Model', visible: true },
  inventory_status: { label: 'Insights', visible: true },
  aetitle: { label: 'AE Title', visible: false },
  eol_os: { label: 'EOL OS', visible: false },
  tag_vendor: { label: 'Vendor', visible: false },
  first_seen: { label: 'First Seen', visible: false },
  last_seen: { label: 'Last Seen', visible: false },
  fda_class_code: { label: 'FDA Class Code', visible: false },
  serial_number: { label: 'Serial Number', visible: false },
  vulnerabilities: { label: 'Vulnerabilities', visible: false },
  threats: { label: 'Threats', visible: false },
  tag_os: { label: 'Operating System', visible: false },
  owner_names: { label: 'Owners', visible: false }
})

const initialState = fromJS({
  alerts_loading: true,
  alerts: undefined,
  bandwidth_loading: true,
  bandwidth: undefined,
  custom_loading: true,
  custom: undefined,
  details_loading: true,
  details: undefined,
  devices_breakdown_key: 'risk',
  devices_breakdown_loading: true,
  devices_breakdown: undefined,
  devices_loading: true,
  devices_total: undefined,
  devices: undefined,
  filters: initialFilters,
  filters_loading: true,
  filter_values: initialFilterValues,
  group_uuid: undefined,
  group_filters: undefined,
  group_filters_loading: true,
  groups_owned_count: undefined,
  name: undefined,
  protocols_breakdown_key: 'l7',
  protocols_loading: true,
  protocols: undefined,
  risk_loading: true,
  risk: undefined,
  summary_loading: true,
  summary: undefined,
  utilization_loading: true,
  utilization: undefined,
  vulnerabilities_loading: true,
  vulnerabilities: undefined,
  devices_group_column_visibilities: devicesGroupColumnVisibilities,
  query_filter: undefined,
  sort_direction: 1,
  sort_key: 'risk'
})

function groupReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'group', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case CHANGE_FILTER:
      const filterKey = action.data['filter']
      const filterValue = action.data['value']
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filterKey]: convertFilterValueToArray(filterValue)
        })
      )
    case CHANGE_SINGULAR_FILTER: {
      const filterKey = action.data['filter']
      const filterValue = action.data['value']
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filterKey]: filterValue
        })
      )
    }
    case PERSIST_GROUP_FILTERS:
      return state.merge({
        group_active_filters: action.data.active,
        group_preset_filters: action.data.preset
      })
    case UPDATE_QUERY_FILTER:
      return state.merge({
        query_filter: action.data
      })
    case RESET_FILTERS: {
      const pinnedFilters = action.data
      const mergedFilters = initialState
        .get('filter_values')
        .merge(pinnedFilters)
      return state.merge({
        filter_values: mergedFilters,
        seen_since: initialState.get('seen_since'),
        not_seen_since: initialState.get('not_seen_since')
      })
    }
    case SET_GROUP_UUID:
      return state.merge({
        group_uuid: action.data
      })
    case SET_GROUP_COLUMN_VISIBILITIES:
      return state.merge({
        devices_group_column_visibilities: action.data
      })
    case CLEAR_DEVICE_GROUP_DETAILS:
      return state.merge({ details: undefined })
    case SET_SORT:
      const sortKey = action.data['sort_key']
      const sortDirection = action.data['sort_direction']
      return state.merge({
        sort_key: sortKey,
        sort_direction: sortDirection
      })
    default:
      return state
  }
}

export default groupReducer
