import apiAction from 'common/actions/api'

export const CHANGE_RISK_LIST_FILTER =
  'cylera/risk/list/CHANGE_RISK_LIST_FILTER'
export const CHANGE_SINGULAR_RISK_LIST_FILTER =
  'cylera/risk/list/CHANGE_SINGULAR_FILTER'
export const RESET_RISK_LIST_FILTER = 'cylera/risk/list/RESET_RISK_LIST_FILTER'
export const SET_FILTERS = 'cylera/risk/list/SET_FILTERS'
export const SET_SORT = 'cylera/risk/list/SET_SORT'

const riskApi = apiAction('risk')

export const loadRiskList = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues
) => {
  const {
    group_by,
    device_type_name,
    device_vendor_name,
    vulnerability_reference_name,
    vulnerability_reference_category,
    vulnerability_reference_severity,
    vulnerability_source_name,
    owner,
    status,
    custom_query,
    detected_after,
    detected_before,
    accepted_after,
    accepted_before
  } = filterValues || {}

  return riskApi('list', {
    page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    group_by,
    device_type_name,
    device_vendor_name,
    vulnerability_reference_name,
    vulnerability_reference_category,
    vulnerability_reference_severity,
    vulnerability_source_name,
    owner_uuid: owner,
    unresolved_only: status,
    custom_query,
    detected_after,
    detected_before,
    accepted_after,
    accepted_before
  })
}

export const loadRiskListInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues
) => {
  const {
    group_by,
    device_type_name,
    device_vendor_name,
    vulnerability_reference_name,
    vulnerability_reference_category,
    vulnerability_reference_severity,
    vulnerability_source_name,
    owner,
    status,
    custom_query,
    detected_after,
    detected_before,
    accepted_after,
    accepted_before
  } = filterValues || {}

  return riskApi(
    'list',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      group_by,
      device_type_name,
      device_vendor_name,
      vulnerability_reference_name,
      vulnerability_reference_category,
      vulnerability_reference_severity,
      vulnerability_source_name,
      owner_uuid: owner,
      unresolved_only: status,
      custom_query,
      detected_after,
      detected_before,
      accepted_after,
      accepted_before
    },
    { infinite: true, cacheable: false }
  )
}

export const loadRiskListFilters = () => {
  return riskApi('list_filters')
}

export function setFilters(filters) {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      data: filters
    })
  }
}

export const exportRiskList = filterValues => {
  const {
    group_by,
    device_type_name,
    device_vendor_name,
    vulnerability_reference_name,
    vulnerability_reference_category,
    vulnerability_reference_severity,
    vulnerability_source_name,
    owner,
    status,
    custom_query,
    detected_after,
    detected_before,
    accepted_after,
    accepted_before
  } = filterValues || {}

  return riskApi(
    'list/export',
    {
      group_by,
      device_type_name,
      device_vendor_name,
      vulnerability_reference_name,
      vulnerability_reference_category,
      vulnerability_reference_severity,
      vulnerability_source_name,
      owner_uuid: owner,
      unresolved_only: status,
      custom_query,
      detected_after,
      detected_before,
      accepted_after,
      accepted_before
    },
    { cacheable: false }
  )
}

export function changeRiskListFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_RISK_LIST_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function resetRiskListFilter() {
  return dispatch => {
    dispatch({
      type: RESET_RISK_LIST_FILTER
    })
  }
}

export function changeSingularFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_SINGULAR_RISK_LIST_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function setSort({ sortKey, sortDirection } = {}) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: {
        sort_key: sortKey,
        sort_direction: sortDirection
      }
    })
  }
}
