import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'

import {
  ADD_RULE_ACTION,
  ADD_RULE,
  IS_EDITING_RULE,
  REMOVE_RULE_ACTION,
  UPDATE_CONDITION,
  UPDATE_SEVERITY
} from './actions'

const initialState = fromJS({
  condition: undefined,
  conditions: undefined,
  conditions_loading: true,
  details: undefined,
  details_loading: true,
  details_unsaved_changes: false,
  details_unsaved_action: undefined,
  list: undefined,
  list_loading: true,
  severity: -1
})

function notificationsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'settings/organization/notifications',
    initialState
  )
  if (apiResult) {
    return apiResult
  }

  const { kind, val } = action

  switch (action.type) {
    case ADD_RULE_ACTION:
      const value = kind === 'flash' ? 'user' : val
      return state.merge({
        details: state.get('details').merge({
          data: state.getIn(['details', 'data']).merge({
            [kind]: state.getIn(['details', 'data', kind]).push(value)
          })
        }),
        details_unsaved_changes: false,
        details_unsaved_action: undefined
      })
    case ADD_RULE:
      return state.merge({
        details: {
          data: {
            email: [],
            flash: [],
            sms: []
          },
          filters: {}
        },
        details_loading: false,
        list: undefined,
        list_loading: true
      })
    case IS_EDITING_RULE:
      return state.merge({
        details_unsaved_changes: true,
        details_unsaved_action: {
          kind: action.kind,
          val: action.val
        }
      })
    case REMOVE_RULE_ACTION:
      return state.merge({
        details: state.get('details').merge({
          data: state.getIn(['details', 'data']).merge({
            [kind]: state
              .getIn(['details', 'data', kind])
              .filter(each => each !== val)
          })
        })
      })
    case UPDATE_CONDITION:
      return state.merge({
        condition: action.condition
      })
    case UPDATE_SEVERITY:
      return state.merge({
        details: state.get('details').merge({
          filters: state.getIn(['details', 'filters']).merge({
            severity: action.severity
          })
        })
      })
    default:
      return state
  }
}

export default notificationsReducer
