import { createSelector } from 'reselect'

const contract = (state, props) => state.main.settings.account.contract
const usage = (state, props) => state.main.settings.account.usage

export const contractDetails = createSelector(contract, contract =>
  contract.get('details')
)
export const contractLoading = createSelector(contract, contract =>
  contract.get('details_loading')
)

export const monthlyUsage = createSelector(usage, usage => usage.get('list'))
export const monthlyUsageLoading = createSelector(usage, usage =>
  usage.get('list_loading')
)
export const monthlyUsageTotal = createSelector(usage, usage =>
  usage.get('list_total')
)

export const historicalUsage = createSelector(usage, usage =>
  usage.get('history')
)
export const historicalUsageLoading = createSelector(usage, usage =>
  usage.get('history_loading')
)
