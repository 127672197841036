import apiAction from 'common/actions/api'

const networkApi = apiAction('network')

export const RESET_SUBNETS_LIST = 'cylera/subnets/RESET_SUBNETS_LIST'
export const SET_SUBNET_QUERY = 'cylera/subnete/SET_SUBNET_QUERY'
export const SET_SORT = 'cylera/subnets/SET_SORT'

export const searchSubnets = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  query
) => {
  return networkApi('subnets', {
    query: query,
    page: page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection
  })
}

export const searchSubnetsInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  query
) => {
  return networkApi(
    'subnets',
    {
      query: query,
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection
    },
    { infinite: true, cacheable: false }
  )
}

export const subnetDetails = id => {
  return networkApi('subnet', {
    id
  })
}

export const subnetDevices = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  id,
  filters
) => {
  return networkApi('subnet_devices', {
    page: page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    subnet_id: id,
    tag_type: filters.deviceTypes
  })
}

export const subnetDevicesInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  id,
  filters
) => {
  return networkApi(
    'subnet_devices',
    {
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      subnet_id: id,
      tag_type: filters.deviceTypes
    },
    { infinite: true, cacheable: false }
  )
}

export const exportSubnets = query => {
  return networkApi('export_subnets', { query }, { cacheable: false })
}

export const addNewSubnet = (subnet, callback) => {
  return networkApi(
    'subnets',
    {
      description: subnet.description,
      subnet_inet: subnet.subnet_inet,
      vlan: subnet.vlan,
      is_monitored: subnet.is_monitored
    },
    {
      method: 'POST',
      success: callback,
      cacheable: false
    }
  )
}

export const saveSubnet = (subnet, success, failure) => {
  return networkApi(
    'subnet',
    {
      id: subnet.id,
      description: subnet.description,
      subnet_inet: subnet.subnet_inet,
      vlan: subnet.vlan
    },
    {
      method: 'PUT',
      cacheable: false,
      success,
      failure
    }
  )
}

export function resetSubnetsList() {
  return dispatch => {
    dispatch({
      type: RESET_SUBNETS_LIST
    })
  }
}

export function setSubnetQuery(value) {
  return dispatch => {
    dispatch({
      type: SET_SUBNET_QUERY,
      data: value
    })
  }
}

export function setSort({ sortKey, sortDirection } = {}) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: { sort_key: sortKey, sort_direction: sortDirection }
    })
  }
}
