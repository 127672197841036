import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import { SORT_ASC } from 'common/constants'
import { SET_FILTERS, SET_SORT, SET_TOTAL } from './actions'

const initialState = fromJS({
  ...apiInitialState('details'),
  ...apiInitialState('metadata'),
  ...apiInitialState('locations'),
  ...apiInitialState('assigned_groups'),
  ...apiInitialState('edit_groups'),
  ...apiInitialState('permissions'),
  ...apiInitialState('users'),
  ...apiInitialState('users_count'),
  ...apiInitialState('primary_dashboard'),
  ...apiInitialState('secondary_dashboards'),
  ...apiInitialState('edit_groups_uuids'),
  filter_values: { query: undefined },
  sort_key: 'first_name',
  sort_direction: SORT_ASC,
  total_groups: undefined
})

function roleReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'role', initialState)

  if (apiResult) {
    return apiResult
  }
  switch (action.type) {
    case SET_FILTERS:
      return state.merge({
        filter_values: action.data
      })
    case SET_TOTAL:
      return state.merge({
        total_groups: action.data
      })
    case SET_SORT:
      const sortKey = action.data['sort_key']
      const sortDirection = action.data['sort_direction']
      return state.merge({
        sort_key: sortKey,
        sort_direction: sortDirection
      })
    default:
      return state
  }
}

export default roleReducer
