import { fromJS } from 'immutable'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('model_groups'),
  ...apiInitialState('type_groups')
})

function groupUtilizationReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'utilization',
    initialState
  )
  if (apiResult) {
    return apiResult
  }
  switch (action.type) {
    default:
      return state
  }
}

export default groupUtilizationReducer
