export const SECONDS_ONE_HOUR = 3600
export const SECONDS_ONE_DAY = 24 * SECONDS_ONE_HOUR
export const SECONDS_ONE_WEEK = 7 * SECONDS_ONE_DAY
export const SECONDS_THIRTY_DAYS = 30 * SECONDS_ONE_DAY

export const SORT_ASC = 0
export const SORT_DESC = 1
export const DEFAULT_MAX_ROWS_FOR_EXPORT = 30000

export const ALERT_STATUS = {
  UNREAD: 1,
  READ: 2
}
