import { createSelector } from 'reselect'
import { selectIn } from 'common/util/selector'

const threats = (state, props) => state.main.threats.list

export const threatsList = createSelector(threats, threats =>
  threats.get('threats')
)

export const threatsLoading = createSelector(threats, threats =>
  threats.get('threats_loading')
)

export const filters = selectIn(threats, 'filters')
export const filtersLoading = selectIn(threats, 'filters_loading')
export const filterValues = selectIn(threats, 'filter_values')
export const total = selectIn(threats, 'threats_total')
export const threatsColumnVisibilities = selectIn(
  threats,
  'threats_column_visibilities'
)
export const sortKey = selectIn(threats, 'sort_key')
export const sortDirection = selectIn(threats, 'sort_direction')
