import { applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'

import history from 'core/store'

import analyticsMiddleware from 'common/middlewares/analyticsMiddleware'
import apiMiddleware from 'common/middlewares/apiMiddleware'
import historyMiddleware from 'common/middlewares/historyMiddleware'
import loggingMiddleware from 'common/middlewares/loggingMiddleware'
import notificationsMiddleware from 'common/middlewares/notificationsMiddleware'
import firestoreMiddleware from 'common/middlewares/firestoreMiddleware'
import firebaseMiddleware from 'common/middlewares/firebaseMiddleware'
import filteringMiddleware from './filteringMiddleware'
import lifecycleMiddleware from './lifecycleMiddleware'

export const middlewares = [
  analyticsMiddleware,
  apiMiddleware,
  filteringMiddleware,
  historyMiddleware,
  lifecycleMiddleware,
  loggingMiddleware,
  notificationsMiddleware,
  routerMiddleware(history),
  firebaseMiddleware,
  firestoreMiddleware,
  thunk
]

export default applyMiddleware(...middlewares)
