import { fromJS } from 'immutable'
import { convertFilterValueToArray } from 'common/util/type'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

import {
  CHANGE_SEEN_SINCE,
  CHANGE_NOT_SEEN_SINCE,
  CHANGE_FILTER,
  CHANGE_SINGULAR_FILTER,
  RESET_FILTERS,
  SET_FILTERS,
  SET_SORT,
  PERSIST_FILTERS,
  CLEAR_FILTER_OPTIONS,
  SET_DEVICES_COLUMN_VISIBILITIES,
  UPDATE_QUERY_FILTER,
  PERSIST_SELECTED_VIEW,
  APPLY_VIEW
} from './actions'

export const initialFilters = fromJS({
  activity_status: [],
  criticality: [],
  fda_class_code: [],
  owner_uuid: [],
  quarantine_state: [],
  risk_condition: [],
  risk: [],
  subnets: [],
  tag_category: [],
  tag_class: [],
  tag_group: [],
  tag_model: [],
  tag_os: [],
  tag_type: [],
  tag_vendor: [],
  tms_asset_number: [],
  vlan: [],
  wards: []
})

export const initialFilterValues = fromJS({
  activity_status: [],
  aetitle: '',
  criticality: [],
  device_uuids: [],
  fda_class_code: [],
  ip_address: '',
  mac_address: '',
  not_seen_since: undefined,
  owner_uuid: [],
  quarantine_state: [],
  risk_condition: [],
  risk: [],
  seen_since: undefined,
  serial_number: '',
  subnets: [],
  tag_category: [],
  tag_class: [],
  tag_group: [],
  tag_model: [],
  tag_os: [],
  tag_type: [],
  tag_vendor: [],
  tms_asset_number: '',
  vlan: [],
  wards: []
})

export const devicesColumnVisibilities = fromJS({
  ip_address: { label: 'IP Address', visible: true },
  'inventory_status.device_class': { label: 'Class', visible: true },
  mac_address: { label: 'MAC Address', visible: true },
  vlan: { label: 'VLAN', visible: false },
  hostname: { label: 'Hostname', visible: true },
  risk: { label: 'Risk', visible: true },
  tag_type: { label: 'Type', visible: true },
  tag_model: { label: 'Model', visible: true },
  inventory_status: { label: 'Insights', visible: true },
  aetitle: { label: 'AE Title', visible: false },
  eol_os: { label: 'EOL OS', visible: false },
  tag_vendor: { label: 'Vendor', visible: false },
  first_seen: { label: 'First Seen', visible: false },
  last_seen: { label: 'Last Seen', visible: false },
  fda_class_code: { label: 'FDA Class Code', visible: false },
  serial_number: { label: 'Serial Number', visible: false },
  vulnerabilities: { label: 'Vulnerabilities', visible: false },
  threats: { label: 'Threats', visible: false },
  tag_os: { label: 'Operating System', visible: false },
  owner_names: { label: 'Owners', visible: false }
})

const initialState = fromJS({
  devices_loading: true,
  devices_total: 0,
  devices: [],
  filter_options: undefined,
  filter_options_loading: undefined,
  model_filter_options: undefined,
  type_filter_options: undefined,
  vendor_filter_options: undefined,
  filter_values: initialFilterValues,
  filters_loading: true,
  filters: initialFilters,
  groupings_loading: true,
  groupings: undefined,
  ip_address_value: undefined,
  not_seen_since: undefined,
  seen_since: undefined,
  sort_direction: 1,
  sort_key: 'risk',
  summary: undefined,
  tags_loading: true,
  tags: undefined,
  utilization: undefined,
  devices_column_visibilities: devicesColumnVisibilities,
  query_filter: undefined,
  selected_view: undefined,
  ...apiInitialState('views')
})

function devicesReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'devices', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case CHANGE_SEEN_SINCE:
      return state.merge({
        seen_since: action.data
      })
    case CHANGE_NOT_SEEN_SINCE:
      return state.merge({
        not_seen_since: action.data
      })
    case CHANGE_FILTER:
      const filterKey = action.data['filter']
      const filterValue = action.data['value']
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filterKey]: convertFilterValueToArray(filterValue)
        })
      )
    // leave this as a separate action because filteringMiddleware listens to the
    // CHANGE_FILTER action, and making a HTTP request for each keypress results
    // in poor performance
    case CHANGE_SINGULAR_FILTER: {
      const filterKey = action.data['filter']
      const filterValue = action.data['value']
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filterKey]: filterValue
        })
      )
    }
    case UPDATE_QUERY_FILTER:
      return state.merge({
        query_filter: action.data
      })

    case RESET_FILTERS: {
      const pinnedFilters = action.data
      const mergedFilters = initialState
        .get('filter_values')
        .merge(pinnedFilters)
      return state.merge({
        filter_values: mergedFilters,
        seen_since: initialState.get('seen_since'),
        not_seen_since: initialState.get('not_seen_since')
      })
    }
    case SET_FILTERS:
      return state.merge({
        filter_values: action.data
      })
    case SET_SORT:
      const sortKey = action.data['sort_key']
      const sortDirection = action.data['sort_direction']
      return state.merge({
        sort_key: sortKey,
        sort_direction: sortDirection
      })
    case PERSIST_FILTERS:
      return state.merge({
        active_filters: action.data.active,
        preset_filters: action.data.preset
      })
    case CLEAR_FILTER_OPTIONS:
      return state.merge({
        model_filter_options: undefined,
        type_filter_options: undefined,
        vendor_filter_options: undefined
      })
    case SET_DEVICES_COLUMN_VISIBILITIES:
      return state.merge({
        devices_column_visibilities: action.data
      })
    case PERSIST_SELECTED_VIEW:
      return state.merge({
        selected_view: action.data
      })
    case APPLY_VIEW:
      return state.merge({
        active_filters: action.data
      })
    default:
      return state
  }
}

export default devicesReducer
