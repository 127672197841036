import { fromJS } from 'immutable'
import { apiNamespaceReducer } from 'common/reducers/api'
import { VERIFY_REMOVE_ERROR } from './actions'

const initialState = fromJS({
  verify_loading: false,
  verify_error: undefined
})

function verifyReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'auth')
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case VERIFY_REMOVE_ERROR:
      return state.merge({
        verify_error: undefined
      })
    default:
      return state
  }
}

export default verifyReducer
