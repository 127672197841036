import asyncComponent from 'common/components/AsyncComponent'

export const ROUTE_EXPORT_DOWNLOAD = '/app/export/:filename'

export const exportsRoutes = [
  {
    route: ROUTE_EXPORT_DOWNLOAD,
    component: asyncComponent(
      () => import('components/Main/Export/DownloadExport'),
      { retryKey: 'download-export' }
    )
  }
]

export default exportsRoutes
