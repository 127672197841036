import apiAction from 'common/actions/api'

export const CHANGE_COMMUNICATION_FILTER =
  'cylera/device/CHANGE_COMMUNICATION_FILTER'
export const RESET_DEVICE = 'cylera/device/RESET_DEVICE'
export const SET_DEVICE = 'cylera/device/SET_DEVICE'
export const CHANGE_BANDWIDTH_RANGE = 'cylera/device/CHANGE_BANDWIDTH_RANGE'
export const PARSE_COMMUNICATION_FILTERS =
  'cylera/device/communication/PARSE_COMMUNICATION_FILTERS'
export const SELECT_COMMUNICATION_HOST =
  'cylera/device/communication/SELECT_COMMUNICATION_HOST'
export const CHANGE_FLOWS_FILTER = 'cylera/device/partners/CHANGE_FLOWS_FILTER'
export const RESET_FLOWS_FILTERS = 'cylera/device/partners/RESET_FLOWS_FILTERS'
export const CHANGE_PARTNERS_FILTER =
  'cylera/device/partners/CHANGE_PARTNERS_FILTER'
export const RESET_PARTNERS_FILTERS =
  'cylera/device/partners/RESET_PARTNERS_FILTERS'

const deviceApi = apiAction('device')
const deviceUtilizationApi = apiAction('utilization/device')

export const getClinicalUtilizationExists = device =>
  deviceUtilizationApi(`has_clinical`, {
    entity_uuid: device
  })

export const getUtilizationExists = device =>
  deviceUtilizationApi(`has_utilization`, {
    device_uuid: device
  })

export const getUtilizationDetail = device =>
  deviceUtilizationApi('utilization_detail', { device_uuid: device })

export const initDeviceUtilizationDetail = device => dispatch => {
  dispatch(getClinicalUtilizationExists(device))
  dispatch(getUtilizationExists(device))
  dispatch(getUtilizationDetail(device))
}

export const getDeviceDetails = uuid => {
  return deviceApi('details', { uuid: uuid })
}

export const getDeviceBandwidth = (uuid, hours) => {
  return deviceApi('bandwidth', {
    uuid: uuid,
    hours: hours
  })
}

export const getDeviceComms = uuid => {
  return deviceApi('comms_overview', { device_uuid: uuid })
}

export const getDeviceCve = uuid => {
  return deviceApi('cves', { uuid: uuid })
}

export const exportDeviceFlows = (uuid, filterValues) => {
  const {
    role,
    partner,
    protocol,
    service,
    domain,
    country,
    rawPort,
    'intranet peer': intranetPartner,
    partnerIp,
    risk
  } = filterValues
  return deviceApi(
    'flows/export',
    {
      device_uuid: uuid,
      device_role: role,
      partner,
      port: rawPort,
      protocol,
      service_name: service,
      partner_domain: domain,
      partner_country_code: country,
      partner_identifier: intranetPartner,
      partner_ip_address: partnerIp,
      partner_risk: risk
    },
    {
      method: 'GET',
      cacheable: false
    }
  )
}

export const loadNetflowFilters = () => deviceApi('comms_search_filters')

export const searchDeviceComms = (uuid, filterValues) => {
  const {
    role,
    rawPort,
    protocol,
    service,
    domain,
    country,
    partnerIp,
    risk,
    'intranet peer': partnerIdentifier
  } = filterValues
  return deviceApi('comms_search', {
    device_role: role,
    port: rawPort,
    protocol: protocol,
    device_uuid: uuid,
    service_name: service,
    partner_domain: domain,
    partner_country_code: country,
    partner_ip_address: partnerIp,
    partner_risk: risk,
    partner_identifier: partnerIdentifier
  })
}

export const getAttributes = uuid => deviceApi('attributes', { uuid })
export const setAttribute = (attributes, successCallback) =>
  deviceApi(
    'attributes',
    { attributes: attributes },
    { method: 'POST', success: successCallback }
  )

export const getDeviceThreats = uuid => {
  return deviceApi('threats', { uuid: uuid })
}

export const getDeviceHasRisks = uuid => {
  return deviceApi('has_risks', { uuid })
}
export const getDeviceHasThreats = uuid => {
  return deviceApi('has_threats', { uuid })
}

export const exportDeviceServices = uuid => {
  return deviceApi(
    'services/export',
    {
      uuid
    },
    {
      method: 'POST',
      cacheable: false
    }
  )
}

export const exportDeviceConnections = (uuid, groupBy) => {
  return deviceApi(
    'connections/export',
    {
      uuid,
      group_by: groupBy
    },
    {
      method: 'POST',
      cacheable: false
    }
  )
}

export const getDevicePolicy = uuid => {
  return deviceApi('policy', { uuid })
}

export function initDevice(uuid) {
  return dispatch => {
    dispatch(getDeviceDetails(uuid))
    dispatch(getDeviceHasThreats(uuid))
    dispatch(getDeviceThreats(uuid))
    dispatch(getDeviceCve(uuid))
    dispatch(initDeviceUtilizationDetail(uuid))
    dispatch(getDeviceHasRisks(uuid))
    dispatch(getAttributes(uuid))
    dispatch(getDevicePolicy(uuid))
    dispatch(getDeviceComms(uuid))
  }
}

export const changeBandwidthRange = hours => ({
  type: CHANGE_BANDWIDTH_RANGE,
  data: hours
})

export const resetDevice = () => ({
  type: RESET_DEVICE
})

const mds2Api = apiAction('device/attribute/mds2')

export const deleteForm = (mds2Uuid, success) =>
  mds2Api(
    'delete',
    {
      mds2_uuid: mds2Uuid
    },
    {
      method: 'DELETE',
      success,
      cacheable: false
    }
  )

export const loadFormsByDevice = (deviceUuid, success) =>
  mds2Api(
    'list',
    {
      device_uuid: deviceUuid
    },
    {
      success
    }
  )

export const loadFormsByGroup = (groupUuid, success) =>
  mds2Api(
    'list',
    {
      group_uuid: groupUuid
    },
    {
      success
    }
  )

export const uploadFormsByDevice = (deviceUuid, files, version, success) =>
  mds2Api(
    'upload',
    {
      device_uuid: deviceUuid,
      files,
      version
    },
    {
      method: 'POST',
      cacheable: false,
      success
    }
  )

export const uploadFormsByGroup = (groupUuid, files, version, success) =>
  mds2Api(
    'upload',
    {
      group_uuid: groupUuid,
      files,
      version
    },
    {
      method: 'POST',
      cacheable: false,
      success
    }
  )
