import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'
import { CHANGE_LOCATION } from '../../../DataAccess/actions'

const initialState = fromJS({
  list: undefined,
  list_loading: undefined,
  selected: undefined
})

function locationsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'organization/location',
    initialState
  )
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case CHANGE_LOCATION:
      const selected = action.location_id
      return state.merge({
        selected
      })
    default:
      return state
  }
}

export default locationsReducer
