import apiAction from 'common/actions/api'

export const CHANGE_THREATS_LIST_FILTER =
  'cylera/threat/CHANGE_THREATS_LIST_FILTER'
export const CHANGE_SINGULAR_FILTER = 'cylera/threat/CHANGE_SINGULAR_FILTER'
export const RESET_THREATS_LIST_FILTER =
  'cylera/threat/RESET_THREATS_LIST_FILTER'
export const SET_THREATS_COLUMN_VISIBILITIES =
  'cylera/threat/SET_THREATS_COLUMN_VISIBILITIES'
export const SET_SORT = 'cylera/threat/SET_SORT'

const threatApi = apiAction('threat')

export const loadThreats = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues
) => {
  const { severity, status, category, assignee, query } = filterValues || {}
  return threatApi('threats', {
    page: page,
    page_size: pageSize,
    severity: severity,
    sort_key: sortKey,
    sort_direction: sortDirection,
    status: status,
    category: category,
    assignee: assignee,
    query
  })
}

export const loadThreatsInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues
) => {
  const { severity, status, category, assignee, query } = filterValues || {}
  return threatApi(
    'threats',
    {
      page: page,
      page_size: pageSize,
      severity: severity,
      sort_key: sortKey,
      sort_direction: sortDirection,
      status: status,
      category: category,
      assignee: assignee,
      query
    },
    { infinite: true }
  )
}

export const exportThreats = filterValues => {
  const { severity, status, category, assignee, query } = filterValues || {}
  return threatApi(
    'export',
    {
      severity: severity,
      status: status,
      category: category,
      assignee: assignee,
      query
    },
    { cacheable: false }
  )
}

export const loadThreatsListFilters = () => threatApi('filters')

export function changeThreatsListFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_THREATS_LIST_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function changeSingularFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_SINGULAR_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function resetThreatsListFilter() {
  return dispatch => {
    dispatch({
      type: RESET_THREATS_LIST_FILTER
    })
  }
}

export function setThreatsColumnVisibilities(threatsColumnVisibilities) {
  return dispatch => {
    dispatch({
      type: SET_THREATS_COLUMN_VISIBILITIES,
      data: threatsColumnVisibilities
    })
  }
}

export function setSort({ sortKey, sortDirection } = {}) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: {
        sort_key: sortKey,
        sort_direction: sortDirection
      }
    })
  }
}
