import asyncComponent from 'common/components/AsyncComponent'

export const ROUTE_DOWNLOAD = '/app/download/:kind/:filename'

export const downloadRoutes = [
  {
    route: ROUTE_DOWNLOAD,
    component: asyncComponent(() => import('components/Main/Download'), {
      retryKey: 'download'
    })
  }
]

export default downloadRoutes
