import { createSelector } from 'reselect'

export const organization = (state, props) => state.main.organization
export const location = createSelector(
  organization,
  organization => organization.location
)
export const locations = createSelector(
  location,
  location => location && location.get('list')
)

export const locationOverviews = (state, props) => state.main.locations

export const locationOptions = createSelector(
  locations,
  locations =>
    locations &&
    locations.toJS().map((location, i) => ({
      label: location.name,
      value: location.id
    }))
)
export const locationsSelected = createSelector(
  location,
  location => location && location.get('selected')
)

export const overviews = createSelector(locationOverviews, locations =>
  locations.get('overview')
)
export const overviewsLoading = createSelector(locationOverviews, locations =>
  locations.get('overview_loading')
)
