import apiAction from 'common/actions/api'

const riskApi = apiAction('risk')

export const getRiskSummary = () => riskApi('summary')

export const getEolOsBreakdown = () => riskApi('eol_os_breakdown')

export const getRiskByLocation = () => riskApi('risk_by_location')

export const getRiskiestModels = () => riskApi('riskiest_models')

export const getRiskiestVendors = () => riskApi('riskiest_vendors')

export const getAgeSeverityMap = () => riskApi('age_severity_map')

export const getTopMitigation = () => riskApi('top_mitigation')

export const getScoreHistory = () => riskApi('score_history')
