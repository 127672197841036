import apiAction from 'common/actions/api'

const dashboardEditorApi = apiAction('custom_dashboard')
const widgetApi = apiAction('widget')

export const loadDashboard = uuid => dashboardEditorApi('details', { uuid })
export const loadWidgetLibrary = () => widgetApi('list')

export const saveDashboard = (
  { uuid, name, description, widget_uuids } = {},
  { onSuccess, onFailure } = {}
) =>
  dashboardEditorApi(
    'details',
    { uuid, name, description, widget_uuids },
    { method: 'POST', success: onSuccess, failure: onFailure }
  )

export const deleteDashboard = (uuid, { onSuccess, onFailure } = {}) =>
  dashboardEditorApi(
    'details',
    { uuid },
    { method: 'DELETE', success: onSuccess, failure: onFailure }
  )
