import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'
import { CLEAR_INTEGRATIONS } from 'components/Main/Integrations/DataAccess/actions'
import { CLEAR_INTEGRATION_DETAIL } from 'components/Main/Integrations/IntegrationsDetail/DataAccess/actions'

const initialState = fromJS({
  appliances: undefined,
  appliances_loading: true,
  details: undefined,
  details_loading: true,
  kinds: undefined,
  kinds_loading: true,
  integrations: undefined,
  integrations_loading: true,
  actions: undefined,
  actions_loading: true,
  groups: undefined,
  logs: undefined,
  logs_loading: true,
  logs_total: undefined,
  logfile: undefined,
  logfile_loading: true,
  logos: undefined,
  logos_loading: true
})

function integrationsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'settings/integrations',
    initialState
  )
  if (apiResult) {
    return apiResult
  }
  switch (action.type) {
    case CLEAR_INTEGRATIONS:
      return state.merge({ integrations: undefined })
    case CLEAR_INTEGRATION_DETAIL:
      return state.merge({ details: undefined })
    default:
      return state
  }
}

export default integrationsReducer
