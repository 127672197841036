import { fromJS } from 'immutable'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

import { RESET_DETAIL } from './actions'

const initialState = fromJS({
  ...apiInitialState('details'),
  ...apiInitialState('events'),
  ...apiInitialState('participants'),
  ...apiInitialState('mitigation_status'),
  ...apiInitialState('mitigation_details'),
  ...apiInitialState('mitigation_plans')
})

function threatDetailReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'threat', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case RESET_DETAIL:
      return initialState

    default:
      return state
  }
}

export default threatDetailReducer
