import { selectIn } from 'common/util/selector'

const base = (state, props) => state.main.cyberAlert

export const cyberAlertInstances = selectIn(base, 'cyber_alert_instances')
export const cyberAlertInstancesLoading = selectIn(
  base,
  'cyber_alert_instances_loading'
)
export const cyberAlertInstancesTotal = selectIn(
  base,
  'cyber_alert_instances_total'
)
export const cyberAlertDetails = selectIn(base, 'cyber_alert_details')
export const cyberAlertDetailsLoading = selectIn(
  base,
  'cyber_alert_details_loading'
)
export const cyberAlertMetrics = selectIn(base, 'cyber_alert_metrics')
export const cyberAlertMetricsLoading = selectIn(
  base,
  'cyber_alert_metrics_loading'
)
export const filterValues = selectIn(base, 'filter_values')
export const cyberAlertRiskList = selectIn(base, 'cyber_alert_risk_list')

export const cyberAlertRiskListLoading = selectIn(
  base,
  'cyber_alert_risk_list_loading'
)
export const cyberAlertRiskListTotal = selectIn(
  base,
  'cyber_alert_risk_list_total'
)
export const riskListFilters = selectIn(base, 'cyber_alert_risk_list_filters')
export const riskListFiltersLoading = selectIn(
  base,
  'cyber_alert_risk_list_filters_loading'
)
export const riskListFilterValues = selectIn(
  base,
  'cyber_alert_risk_list_filter_values'
)
