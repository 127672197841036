import { fromJS } from 'immutable'
import { SELECT_ROLE_TYPE } from './actions'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'

const initialState = fromJS({
  selected_role_type: 'Default',
  ...apiInitialState('summary'),
  ...apiInitialState('default_roles')
})

function roleReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'role', initialState)

  if (apiResult) {
    return apiResult
  }
  switch (action.type) {
    case SELECT_ROLE_TYPE:
      const selection = action.data
      return state.merge({
        selected_role_type: selection
      })
    default:
      return state
  }
}

export default roleReducer
