import apiAction from 'common/actions/api'

export const SET_FILTERS = 'cylera/settings/dashboards/SET_FILTERS'
export const CLEAR_DASHBOARDS = 'cylera/settings/dashboards/CLEAR_DASHBOARDS'

const dashboardApi = apiAction('custom_dashboard')

export const getFilterOptions = () => dashboardApi('filter_options')

export function clearDashboards(callback) {
  return dispatch => {
    dispatch({
      type: CLEAR_DASHBOARDS
    })
  }
}

export const getDashboards = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues,
  { infinite = false } = {}
) => {
  const { widget_uuid, custom_query } = filterValues || {}
  return dashboardApi(
    'list',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      widget_uuid,
      custom_query
    },
    { infinite, cacheable: false }
  )
}

export function setFilters(filters) {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      data: {
        filters
      }
    })
  }
}

export const createDashboard = (
  { name = '', description = '' } = {},
  { onSuccess, onFailure } = {}
) =>
  dashboardApi(
    '',
    { name, description },
    { method: 'POST', success: onSuccess, failure: onFailure }
  )
