export const SET_ATTRIBUTE_TYPE = 'cylera/attributes/SET_ATTRIBUTE_TYPE'

export function setAttributeType(attributeType) {
  return dispatch => {
    dispatch({
      type: SET_ATTRIBUTE_TYPE,
      data: attributeType
    })
  }
}
