import apiAction from 'common/actions/api'

const contractApi = apiAction('contract')
const usageApi = apiAction('usage')

export const loadContract = () => {
  return contractApi('details', {})
}

export const loadHistoricalUsage = () => {
  return usageApi('history', {})
}

export const loadMonthlyUsage = (page, pageSize, sortKey, sortDirection) => {
  return usageApi('list', {
    page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection
  })
}

export const loadMonthlyUsageInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection
) => {
  return usageApi(
    'list',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection
    },
    { infinite: true, cacheable: false }
  )
}

export const exportMonthlyUsage = () => {
  return usageApi('list/export', {}, { method: 'POST', cacheable: false })
}
