import apiAction from 'common/actions/api'

import API from 'common/util/api'
import { UPDATE_USER_FEATURE_FLAG } from 'components/Main/Dashboard/DataAccess/actions'

export const LOGIN_FAILURE = 'cylera/auth/LOGIN_FAILURE'
export const LOGIN_REMOVE_ERROR = 'cylera/auth/LOGIN_REMOVE_ERROR'
export const LOGIN_USER = 'login_user'
export const LOGOUT_USER = 'logout_user'
export const GET_USER = 'get_user:user'

const authApi = apiAction('auth')

export const SET_LOGIN_LOADING = 'cylera/auth/SET_LOGIN_LOADING'

export const setLoginLoading = isLoading => ({
  type: SET_LOGIN_LOADING,
  payload: isLoading
})

export const doLogin = (email, password, successCallback, failureCallback) => {
  return authApi(
    LOGIN_USER,
    {
      email: email,
      password: password
    },
    {
      method: 'POST',
      failure: failureCallback,
      success: response => {
        if (API.checkAllowedRoles(response.user_role)) {
          API.setToken(response.token)
          successCallback()
        } else {
          failureCallback('Invalid Role')
        }
        setLoginLoading(false)
      }
    }
  )
}

export const doLogout = successCallback => {
  return authApi(
    LOGOUT_USER,
    {},
    {
      method: 'POST',
      success: () => {
        if (successCallback) {
          successCallback()
        }
      }
    }
  )
}

export const getUser = (successCallback, failureCallback) =>
  authApi(
    GET_USER,
    {},
    {
      success: successCallback,
      failure: failureCallback,
      cacheable: false
    }
  )

export const loginFailure = message => ({
  type: LOGIN_FAILURE,
  data: message
})

export const removeLoginError = () => ({
  type: LOGIN_REMOVE_ERROR
})

export const updateUserFeatureFlag = payload => ({
  type: UPDATE_USER_FEATURE_FLAG,
  payload: payload
})
