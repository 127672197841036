import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import store, { history } from 'core/store'
import App from 'core/App'
import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://8b21bbbd4d1f0cc8c118a2450df6b28a@o4505661811064832.ingest.sentry.io/4505673507405824',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['https://*.cylera.com']
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

const root = document.querySelector('#root')

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  root
)
