import { fromJS, OrderedMap } from 'immutable'
import asyncComponent from 'common/components/AsyncComponent'
import dashboardRoutes from './dashboard'
import devicesRoutes from './devices'
import downloadRoutes from './download'
import exportsRoutes from './exports'
import integrationsRoutes from './integrations'
import networkingRoutes from './networking'
import reportingRoutes from './reporting'
import riskRoutes from './risk'
import settingsRoutes from './settings'
import threatsRoutes from './threats'
import utilizationRoutes from './utilization'

export const paths = fromJS([
  ...dashboardRoutes,
  ...devicesRoutes,
  ...threatsRoutes,
  ...riskRoutes,
  ...utilizationRoutes,
  ...networkingRoutes,
  ...reportingRoutes,
  ...integrationsRoutes,
  ...settingsRoutes,
  ...downloadRoutes,
  ...exportsRoutes,
  {
    component: asyncComponent(() => import('common/components/PageNotFound'), {
      retryKey: 'page-not-found'
    }),
    route: undefined,
    title: 'Not Found'
  }
])

export const routes = OrderedMap(paths.map(path => [path.get('route'), path]))
export default routes
