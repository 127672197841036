import { fromJS } from 'immutable'
import { combineReducers } from 'redux'

import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import { convertFilterValueToArray } from 'common/util/type'
import {
  SECONDS_ONE_DAY,
  SECONDS_ONE_WEEK,
  SECONDS_THIRTY_DAYS
} from 'common/constants'
import { defaultPaginationParams } from 'components/Main/reducer'

import {
  CHANGE_BANDWIDTH_RANGE,
  CHANGE_COMMUNICATION_FILTER,
  CHANGE_FLOWS_FILTER,
  CHANGE_PARTNERS_FILTER,
  PARSE_COMMUNICATION_FILTERS,
  RESET_DEVICE,
  SET_DEVICE,
  RESET_FLOWS_FILTERS,
  RESET_PARTNERS_FILTERS,
  SELECT_COMMUNICATION_HOST
} from './actions'

export const initialCommunicationFilters = fromJS({
  ports: [],
  protocols: [],
  direction: []
})

export const initialCommunicationFilterValues = fromJS({
  ports: [],
  protocols: [],
  direction: []
})

export const initialFlowsFilters = fromJS({
  role: [],
  partner: [],
  port: [],
  protocol: [],
  window: [],
  service: []
})

export const initialFlowsFilterValues = fromJS({
  role: [],
  partner: [],
  port: [],
  protocol: [],
  window: 720,
  service: []
})

export const initialPartnersFilters = fromJS({
  partner_type: [],
  partner_risk: [],
  partner_class: [],
  window: []
})

export const initialPartnersFilterValues = fromJS({
  partner_type: [],
  partner_risk: [],
  partner_class: [],
  window: 720
})

export const initialFlowsPaginationParams = {
  ...defaultPaginationParams,
  pageSize: 10
}

export const initialPartnersPaginationParams = {
  ...defaultPaginationParams,
  pageSize: 10
}

const initialState = fromJS({
  bandwidth: undefined,
  bandwidth_loading: true,
  bandwidth_range_hours: 24,
  bandwidth_unauthorized: undefined,
  communication: undefined,
  communication_loading: true,
  communication_filters: initialCommunicationFilters,
  communication_filter_values: initialCommunicationFilterValues,
  communication_host_selected: undefined,
  communication_overview: undefined,
  communication_overview_loading: true,
  cves: undefined,
  cves_loading: true,
  cves_unauthorized: false,
  details: undefined,
  details_loading: true,
  flows: undefined,
  flows_loading: true,
  flows_total: undefined,
  flows_page_size: initialFlowsPaginationParams.pageSize,
  flows_filters: initialFlowsFilters,
  flows_filters_loading: true,
  flows_filter_values: initialFlowsFilterValues,
  ip_history: undefined,
  ip_history_loading: true,
  ip_history_total: undefined,
  alerts: undefined,
  alerts_page_size: undefined,
  alerts_total: undefined,
  partners: undefined,
  partners_loading: true,
  partners_total: undefined,
  partners_page_size: initialPartnersPaginationParams.pageSize,
  partners_filters: initialPartnersFilters,
  partners_filters_loading: true,
  partners_filter_values: initialPartnersFilterValues,
  protocol: undefined,
  has_risks: false,
  risk: undefined,
  has_threats: false,
  threats: undefined,
  threats_loading: true,
  threats_unauthorized: false,
  utilization_loading: true,
  utilization: undefined,
  risk_factors: undefined,
  risk_factors_loading: true,

  ...apiInitialState('has_clinical'),
  ...apiInitialState('has_utilization'),
  ...apiInitialState('utilization_detail'),
  ...apiInitialState(`percentage_and_trend_${SECONDS_ONE_DAY}`),
  ...apiInitialState(`percentage_and_trend_${SECONDS_ONE_WEEK}`),
  ...apiInitialState(`percentage_and_trend_${SECONDS_THIRTY_DAYS}`),
  ...apiInitialState('tags'),
  ...apiInitialState('timeline_day'),
  ...apiInitialState('timeline_hour'),
  ...apiInitialState('timeline_weekday'),
  ...apiInitialState('services'),
  ...apiInitialState('connections'),
  ...apiInitialState('services_overview'),
  ...apiInitialState('domains_overview'),
  ...apiInitialState('partners_overview'),
  ...apiInitialState('countries_overview'),
  ...apiInitialState('attributes'),
  ...apiInitialState('policy'),
  ...apiInitialState('create_policy'),
  ...apiInitialState('comms_overview'),
  ...apiInitialState('comms_search'),
  ...apiInitialState('comms_search_filters')
})

function deviceReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'device', initialState)
  if (apiResult) {
    return apiResult
  }
  const utilizationApiResult = apiNamespaceReducer(
    state,
    action,
    'utilization/device',
    initialState
  )
  if (utilizationApiResult) {
    return utilizationApiResult
  }

  const { filter, value } = action.data || {}
  switch (action.type) {
    case CHANGE_BANDWIDTH_RANGE:
      return state.merge({
        bandwidth_range_hours: action.data
      })
    case CHANGE_COMMUNICATION_FILTER:
      return state.mergeIn(
        ['communication_filter_values'],
        fromJS({
          [filter]: value
        })
      )
    case CHANGE_FLOWS_FILTER:
      return state.mergeIn(
        ['flows_filter_values'],
        fromJS({
          [filter]: convertFilterValueToArray(value)
        })
      )
    case CHANGE_PARTNERS_FILTER:
      return state.mergeIn(
        ['partners_filter_values'],
        fromJS({
          [filter]: convertFilterValueToArray(value)
        })
      )
    case RESET_FLOWS_FILTERS:
      return state.mergeIn(
        ['flows_filter_values'],
        initialState.get('flows_filter_values')
      )
    case RESET_PARTNERS_FILTERS:
      return state.mergeIn(
        ['partners_filter_values'],
        initialState.get('partners_filter_values')
      )
    // TODO: D-d-deprecate
    case PARSE_COMMUNICATION_FILTERS:
      const directions = ['Destination', 'Source'].map(item => ({
        label: item,
        value: item
      }))
      const ports = [
        ...new Set(action.data.flows.map(flow => flow.port))
      ].map(port => ({ label: port, value: port }))
      const protocols = [
        ...new Set(action.data.flows.map(flow => flow.protocols.l7))
      ].map(port => ({ label: port, value: port }))
      return state.merge({
        communication_filters: {
          directions: directions,
          ports: ports,
          protocols: protocols
        }
      })
    case RESET_DEVICE:
      return initialState
    case SET_DEVICE:
      return state.merge({
        details: action.data
      })
    case SELECT_COMMUNICATION_HOST:
      return state.merge({
        communication_host_selected: action.data
      })
    default:
      return state
  }
}

export default combineReducers({
  info: deviceReducer
})
