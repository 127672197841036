import React from 'react'

export default function asyncComponent(
  importComponent,
  {
    retryKey,
    retryKeyPrefix = 'load-component-retry-count-',
    retryCount = 1
  } = {}
) {
  const uniqueRetryCountSessionStorageKey = `${retryKeyPrefix}${retryKey}`
  const attempts =
    Number(window.sessionStorage.getItem(uniqueRetryCountSessionStorageKey)) ??
    0
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        component: null
      }
    }

    async componentDidMount() {
      const { default: component } = await new Promise((resolve, reject) =>
        importComponent()
          .then(component => {
            window.sessionStorage.removeItem(uniqueRetryCountSessionStorageKey)
            resolve(component)
          })
          .catch(() => {
            if (attempts < retryCount && retryKey !== undefined) {
              window.sessionStorage.setItem(
                uniqueRetryCountSessionStorageKey,
                `${attempts + 1}`
              )
              return window.location.reload()
            }
          })
      )

      this.setState({
        component: component
      })
    }

    render() {
      const C = this.state.component
      return C ? <C {...this.props} /> : null
    }
  }

  return AsyncComponent
}
