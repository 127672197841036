import { fromJS } from 'immutable'
import { convertFilterValueToArray } from 'common/util/type'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import {
  CHANGE_FILTER,
  CHANGE_SINGULAR_FILTER,
  RESET_FILTERS,
  SET_SORT,
  SET_DEVICE_SEARCH_TEXT
} from './actions'
import { SORT_ASC } from 'common/constants'

export const initialFilterValues = fromJS({
  category: [],
  query: ''
})

const initialState = fromJS({
  ...apiInitialState('org_attributes'),
  ...apiInitialState('attribute_devices'),
  sort_key: 'ip',
  sort_direction: SORT_ASC,
  filter_values: initialFilterValues
})

function devicesAttributesReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'attributes',
    initialState
  )
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case CHANGE_FILTER:
      const filterKey = action.data['filter']
      const filterValue = action.data['value']
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filterKey]: convertFilterValueToArray(filterValue)
        })
      )
    case CHANGE_SINGULAR_FILTER:
      const singularFilterKey = action.data['filter']
      const singularFilterValue = action.data['value']
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [singularFilterKey]: singularFilterValue
        })
      )
    case RESET_FILTERS: {
      return state.merge({
        filter_values: initialState.get('filter_values')
      })
    }
    case SET_SORT:
      const sort_key = action.data['sort_key']
      const sort_direction = action.data['sort_direction']
      return state.merge({
        sort_key,
        sort_direction
      })
    case SET_DEVICE_SEARCH_TEXT:
      return state.merge({
        device_search_text: action.data
      })
    default:
      return state
  }
}

export default devicesAttributesReducer
