import React from 'react'
import { Route, Switch } from 'react-router'
import './App.css'
import classnames from 'classnames'
import asyncComponent from 'common/components/AsyncComponent'
import store, { history } from 'core/store'
import * as LIFECYCLE_ACTIONS from 'common/actions/lifecycle'

const AsyncLogin = asyncComponent(() => import('components/Auth/Login'), {
  retryKey: 'login'
})
const AsyncAuth = asyncComponent(() => import('components/Auth'), {
  retryKey: 'auth'
})
const AsyncMain = asyncComponent(() => import('components/Main'), {
  retryKey: 'main'
})
const AsyncReportingDownload = asyncComponent(
  () => import('components/Main/Reporting/Download'),
  { retryKey: 'download' }
)
const AsyncNotFound = asyncComponent(
  () => import('common/components/PageNotFound'),
  { retryKey: 'app-page-not-found' }
)

class App extends React.Component {
  componentDidMount = () => {
    store.dispatch(
      LIFECYCLE_ACTIONS.initApp({
        history
      })
    )
  }

  render = () => {
    return (
      <div
        className={classnames(
          'cui-tw-h-screen',
          'cui-tw-w-screen',
          'cui-tw-overflow-hidden'
        )}
      >
        <Switch>
          <Route exact path="/" component={AsyncLogin} />
          <Route exact path="/login" component={AsyncLogin} />
          <Route path="/auth" component={AsyncAuth} />
          <Route
            exact
            path="/app/reporting/download/:uuid"
            component={AsyncReportingDownload}
          />
          <Route path="/app" component={AsyncMain} />
          <Route component={AsyncNotFound} />
        </Switch>
      </div>
    )
  }
}

export default App
