import { fromJS } from 'immutable'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'
import { convertFilterValueToArray } from 'common/util/type'
import { convertDateToEpochTimestampSeconds } from 'common/util/date'

import {
  CHANGE_RISK_LIST_FILTER,
  CHANGE_SINGULAR_RISK_LIST_FILTER,
  RESET_RISK_LIST_FILTER,
  SET_FILTERS,
  SET_SORT
} from './actions'

export const initialFilterValues = fromJS({
  device_type_name: [],
  device_vendor_name: [],
  vulnerability_reference_category: [],
  vulnerability_reference_severity: [],
  vulnerability_source_name: [],
  vulnerability_reference_name: [],
  status: '1', // show unresolved only
  custom_query: '',
  detected_after: undefined,
  detected_before: undefined,
  accepted_after: undefined,
  accepted_before: undefined
})

const initialState = fromJS({
  list_filter_values: initialFilterValues,
  ...apiInitialState('list'),
  ...apiInitialState('list_filters')
})

function riskListReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'risk', initialState)
  if (apiResult) {
    return apiResult
  }

  const { filter, value } = action.data || {}
  switch (action.type) {
    case CHANGE_RISK_LIST_FILTER:
      if (filter === 'detected_after' || filter === 'accepted_after') {
        return state.mergeIn(
          ['list_filter_values'],
          fromJS({
            [filter]: convertDateToEpochTimestampSeconds(value)
          })
        )
      } else {
        return state.mergeIn(
          ['list_filter_values'],
          fromJS({
            [filter]: convertFilterValueToArray(value)
          })
        )
      }
    case CHANGE_SINGULAR_RISK_LIST_FILTER:
      return state.mergeIn(
        ['list_filter_values'],
        fromJS({
          [filter]: value
        })
      )
    case RESET_RISK_LIST_FILTER:
      return state.mergeIn(
        ['list_filter_values'],
        initialState.get('list_filter_values')
      )
    case SET_FILTERS:
      return state.merge({
        list_filter_values: action.data
      })
    case SET_SORT:
      const sortKey = action.data['sort_key']
      const sortDirection = action.data['sort_direction']
      return state.merge({
        sort_key: sortKey,
        sort_direction: sortDirection
      })
    default:
      return state
  }
}

export default riskListReducer
