import moment from 'moment'

export function convertDateToEpochTimestampSeconds(date) {
  return (date.getTime() / 1000) | 0
}

export function format(date, format) {
  return moment(date).format(format)
}

export function durationFromSeconds(seconds) {
  return moment.duration(seconds, 'seconds')
}
