import { fromJS } from 'immutable'
import { SORT_DESC } from 'common/constants'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'
import { RESET_SUBNETS_LIST, SET_SUBNET_QUERY, SET_SORT } from './actions'

const initialState = fromJS({
  subnets: undefined,
  subnets_loading: true,
  subnets_total: undefined,
  subnets_page_size: undefined,
  subnets_query: '',
  subnet: undefined,
  subnet_loading: true,
  subnets_sort_key: 'total_device_count',
  subnets_sort_direction: SORT_DESC,
  ...apiInitialState('subnet_devices')
})

function subnetsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'network', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case RESET_SUBNETS_LIST:
      return state.merge({
        subnets: initialState.get('subnets'),
        subnets_loading: initialState.get('subnets_loading')
      })
    case SET_SUBNET_QUERY:
      return state.merge({
        subnets_query: action.data
      })
    case SET_SORT:
      const sortKey = action.data['sort_key']
      const sortDirection = action.data['sort_direction']
      return state.merge({
        subnets_sort_key: sortKey,
        subnets_sort_direction: sortDirection
      })
    default:
      return state
  }
}

export default subnetsReducer
