const commonInitialisms = ['Fda', 'Uuid', 'Id']

export const isStr = str => typeof str === 'string'

export function capitalizeInitialisms(
  str,
  initalisms = commonInitialisms.slice()
) {
  const initalism = initalisms.pop()
  const capitalized = globalReplace(str, initalism, initalism.toUpperCase())
  if (initalisms.length === 0) {
    return capitalized
  }
  return capitalizeInitialisms(
    globalReplace(capitalized, initalism, initalism.toUpperCase()),
    initalisms
  )
}

export function fromSnakeCase(str) {
  return globalReplace(str, '_', ' ')
}

export function globalReplace(str, search, replace) {
  if (!str || !isStr(str)) {
    return str
  }
  return str.replace(new RegExp(search, 'g'), replace)
}

export function lowerCase(str) {
  if (!str || !isStr(str)) {
    return str
  }
  return str.toLowerCase()
}

export function upperCase(str) {
  if (!str || !isStr(str)) {
    return str
  }
  return str.toUpperCase()
}

export function titleCase(str) {
  if (!str || !isStr(str)) {
    return str
  }
  return lowerCase(str)
    .split(' ')
    .map(word => {
      const capitalized = word.charAt(0).toUpperCase()
      const lowercase = word.slice(1)
      return `${capitalized}${lowercase}`
    })
    .join(' ')
}

export const capitalizeFirstLetter = str => {
  if (!str || !isStr(str)) {
    return str
  }
  return str[0].toUpperCase() + str.slice(1).toLowerCase()
}

export function toSnakeCase(str) {
  if (str && isStr(str)) {
    return globalReplace(str.toLowerCase(), ' ', '_')
  }
  return lowerCase(str)
}

export const camelToSnakeCase = str => {
  if (str && isStr(str)) {
    return str
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase()
  }
  return str
}

export const scrubUuids = (str, replacement) =>
  str.replace(
    new RegExp(
      '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}',
      'gi'
    ),
    replacement
  )

export const snakeToCamelCase = str => {
  if (str && isStr(str)) {
    str += ''
    str = str.split('_')
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
    }
    return str.join('')
  }
  return str
}
