import apiAction from 'common/actions/api'

const riskApi = apiAction('risk')

export const loadDetails = referenceUuid => {
  return riskApi('details', {
    reference_uuid: referenceUuid
  })
}

export const loadDevices = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  groupUuid,
  referenceUuid,
  customQuery,
  detectedAfter,
  detectedBefore,
  acceptedAfter,
  acceptedBefore,
  affectedModels,
  status,
  deviceTypeName,
  deviceVendorName
) => {
  return riskApi('devices', {
    page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    group_uuid: groupUuid,
    reference_uuid: referenceUuid,
    custom_query: customQuery,
    detected_after: detectedAfter,
    detected_before: detectedBefore,
    accepted_after: acceptedAfter,
    accepted_before: acceptedBefore,
    affected_models: affectedModels,
    status,
    device_type_name: deviceTypeName,
    device_vendor_name: deviceVendorName
  })
}

export const exportDevices = args => {
  const {
    referenceUuid,
    customQuery,
    detectedAfter,
    detectedBefore,
    acceptedAfter,
    acceptedBefore,
    affectedModels,
    status,
    deviceType,
    deviceVendor
  } = args
  return riskApi(
    'devices/export',
    {
      reference_uuid: referenceUuid,
      custom_query: customQuery,
      detected_after: detectedAfter,
      detected_before: detectedBefore,
      accepted_after: acceptedAfter,
      accepted_before: acceptedBefore,
      affected_models: affectedModels,
      status,
      device_type_name: deviceType,
      device_vendor_name: deviceVendor
    },
    { cacheable: false }
  )
}

export const loadDevicesInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  groupUuid,
  referenceUuid,
  customQuery,
  detectedAfter,
  detectedBefore,
  acceptedAfter,
  acceptedBefore,
  affectedModels,
  status,
  deviceTypeName,
  deviceVendorName
) => {
  return riskApi(
    'devices',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      group_uuid: groupUuid,
      reference_uuid: referenceUuid,
      custom_query: customQuery,
      detected_after: detectedAfter,
      detected_before: detectedBefore,
      accepted_after: acceptedAfter,
      accepted_before: acceptedBefore,
      affected_models: affectedModels,
      status,
      device_type_name: deviceTypeName,
      device_vendor_name: deviceVendorName
    },
    { infinite: true }
  )
}

export const loadStatusOptions = () => {
  return riskApi('status:status_options')
}

export const loadMitigations = referenceUuid => {
  return riskApi('mitigations', { reference_uuid: referenceUuid })
}

export const loadAffectedModelCounts = referenceUuid => {
  return riskApi('affected_model_counts', {
    vulnerability_reference_uuid: referenceUuid
  })
}

export const loadAffectedDeviceDetail = (referenceUuid, deviceUuid) => {
  return riskApi('affected_device_detail', {
    reference_uuid: referenceUuid,
    device_uuid: deviceUuid
  })
}

export const updateSingleDeviceStatus = (
  deviceUuid,
  referenceUuid,
  status,
  acceptedUntil,
  acceptanceReason,
  successCallback,
  failureCallback
) => {
  return riskApi(
    'status',
    {
      device_uuid: deviceUuid,
      reference_uuid: referenceUuid,
      status: status,
      accepted_until: acceptedUntil,
      acceptance_reason: acceptanceReason
    },
    {
      method: 'POST',
      cacheable: false,
      success: successCallback,
      failure: failureCallback
    }
  )
}

export const updateMultipleDeviceStatus = (
  deviceUuids,
  referenceUuid,
  status,
  filters,
  updateAllDevices,
  acceptedUntil,
  acceptanceReason,
  successCallback,
  failureCallback
) => {
  return riskApi(
    'status',
    {
      device_uuid: deviceUuids,
      reference_uuid: referenceUuid,
      status: status,
      accepted_until: acceptedUntil,
      acceptance_reason: acceptanceReason,
      all_devices: updateAllDevices,
      detected_after: filters?.detectedAfter,
      detected_before: filters?.detectedBefore,
      affected_models: filters?.affectedModels,
      status_filter: filters?.status
      //TODO??
    },
    {
      method: 'POST',
      cacheable: false,
      success: successCallback,
      failure: failureCallback
    }
  )
}

export const loadRiskDetailFilters = (referenceUuid, callback) => {
  return riskApi(
    'device_filters',
    {
      vulnerability_reference_uuid: referenceUuid
    },
    {
      cacheable: false,
      success: callback
    }
  )
}
