export const devices = state => state.main.devices.suppressed.get('devices')
export const devicesLoading = state =>
  state.main.devices.suppressed.get('devices_loading')
export const devicesTotal = state =>
  state.main.devices.suppressed.get('devices_total')
export const filters = state => state.main.devices.suppressed.get('filters')
export const filterOptions = state =>
  state.main.devices.suppressed.get('filter_options')
export const filterOptionsLoading = state =>
  state.main.devices.suppressed.get('filter_options_loading')
export const devicesColumnVisibilities = state =>
  state.main.devices.suppressed.get('columnVisibilities')
export const queryFilter = state =>
  state.main.devices.suppressed.get('queryFilter')
export const sortKey = state => state.main.devices.suppressed.get('sortKey')
export const sortDirection = state =>
  state.main.devices.suppressed.get('sortDirection')
export const rules = state => state.main.devices.suppressed.get('rules')
export const rulesLoading = state =>
  state.main.devices.suppressed.get('rules_loading')
