import { fromJS } from 'immutable'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('details'),
  ...apiInitialState('devices'),
  ...apiInitialState('mitigations'),
  ...apiInitialState('status'),
  ...apiInitialState('status_options'),
  ...apiInitialState('affected_device_detail'),
  ...apiInitialState('affected_model_counts'),
  ...apiInitialState('device_filters'),
  devices_unresolved: 0
})

function riskDetailReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'risk', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    default:
      return state
  }
}

export default riskDetailReducer
