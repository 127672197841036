import apiAction from 'common/actions/api'

const userApi = apiAction('user')

export const RESET_USER_PAGE = 'cylera/user/RESET_USER_PAGE'

export const getUsers = () => {
  return userApi('all_users')
}

export const updateUserDetails = (userDetails, success, failure) => {
  return userApi(
    'update',
    {
      uuid: userDetails.uuid,
      role_uuid: userDetails.roleUuid,
      first_name: userDetails.firstName,
      last_name: userDetails.lastName,
      email: userDetails.email,
      owner_uuid: userDetails.ownerUuid
    },
    {
      method: 'POST',
      cacheable: false,
      success: success,
      failure: failure
    }
  )
}

export const deleteUser = (user, success, failure) => {
  return userApi(
    'delete',
    {
      email: user.email
    },
    {
      method: 'POST',
      success: success,
      cacheable: false,
      failure: failure
    }
  )
}
