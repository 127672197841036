import apiAction from 'common/actions/api'

export const SET_FILTERS = 'cylera/devices/suppressed/SET_FILTERS'
export const SET_COLUMN_VISIBILITIES =
  'cylera/devices/suppressed/SET_DEVICES_COLUMN_VISIBILITIES'
export const SET_QUERY = 'cylera/devices/suppresssed/SET_QUERY'
export const SET_SORT = 'cylera/devices/suppresssed/SET_SORT'

const suppressedDevicesApi = apiAction('suppressed', '/device/suppressed')

export const getSuppressionRules = () => suppressedDevicesApi('rules')
export const updateSuppressionRules = rules =>
  suppressedDevicesApi('rules', { updates: rules }, { method: 'POST' })

export const deleteSuppressionRule = uuid =>
  suppressedDevicesApi('rules', { uuid }, { method: 'DELETE' })

export const getFilterOptions = () => suppressedDevicesApi('filter_options')

export const searchSuppressedDevices = (
  filters,
  page,
  sortKey,
  sortDirection,
  pageSize
) =>
  suppressedDevicesApi(
    'devices',
    filters.reduce(
      (acc, filter) =>
        filter
          ? {
              ...acc,
              [filter.value]: JSON.stringify(filter.getQuery())
            }
          : acc,
      {
        page,
        sort_key: sortKey,
        sort_direction: sortDirection,
        page_size: pageSize
      }
    )
  )

export const searchSuppressedDevicesInfinite = (
  filters,
  page,
  sortKey,
  sortDirection,
  pageSize
) =>
  suppressedDevicesApi(
    'devices',
    filters.reduce(
      (acc, filter) =>
        filter
          ? {
              ...acc,
              [filter.value]: JSON.stringify(filter.getQuery())
            }
          : acc,
      {
        page,
        sort_key: sortKey,
        sort_direction: sortDirection,
        page_size: pageSize
      }
    ),
    { infinite: true, cacheable: false }
  )

export function setQuery(newQueryFilter) {
  return dispatch => {
    dispatch({
      type: SET_QUERY,
      data: newQueryFilter
    })
  }
}

export function setSort(newSort) {
  return dispatch => {
    dispatch({
      type: SET_SORT,
      data: newSort
    })
  }
}

export function setFilters(filters) {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      data: filters
    })
  }
}

export function setDevicesColumnVisibilities(devicesColumnVisibilities) {
  return dispatch => {
    dispatch({
      type: SET_COLUMN_VISIBILITIES,
      data: devicesColumnVisibilities
    })
  }
}
