import * as actions from '../actions/history'

const historyMiddleware = store => next => action => {
  if (action) {
    const state = store.getState()
    const { history } = action || {}

    if (action.type === actions.INIT_LISTENERS) {
      const { listeners } = action
      history.listen(location => {
        listeners.map(listener =>
          store.dispatch(listener({ history, location, state }))
        )
      })
    }
    return next(action)
  }
}

export default historyMiddleware
