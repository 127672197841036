import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('list')
})

function customDashboardsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'widget', initialState)

  if (apiResult) {
    return apiResult
  }
  return state
}

export default customDashboardsReducer
