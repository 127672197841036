import { fromJS } from 'immutable'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('details')
})

function reducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'contract', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    default:
      return state
  }
}

export default reducer
