import { createSelector } from 'reselect'

const threat = (state, props) => state.main.threats.detail

export const threatDetail = createSelector(threat, threat =>
  threat.get('details')
)
export const threatDetailLoading = createSelector(threat, threat =>
  threat.get('details_loading')
)
export const threatEvents = createSelector(threat, threat =>
  threat.get('events')
)
export const threatEventsLoading = createSelector(threat, threat =>
  threat.get('events_loading')
)
export const threatEventsTotal = createSelector(threat, threat =>
  threat.get('events_total')
)
export const threatParticipants = createSelector(threat, threat =>
  threat.get('participants')
)
export const threatParticipantsLoading = createSelector(threat, threat =>
  threat.get('participants_loading')
)
export const threatMitigationStatus = createSelector(threat, threat =>
  threat.get('mitigation_status')
)
export const threatMitigationStatusLoading = createSelector(threat, threat =>
  threat.get('mitigation_status_loading')
)
export const threatMitigationDetails = createSelector(threat, threat =>
  threat.get('mitigation_details')
)
export const threatMitigationDetailsLoading = createSelector(threat, threat =>
  threat.get('mitigation_details_loading')
)
export const threatMitigationPlans = createSelector(threat, threat =>
  threat.get('mitigation_plans')
)
export const threatMitigationPlansLoading = createSelector(threat, threat =>
  threat.get('mitigation_plans_loading')
)
