import { fromJS } from 'immutable'

import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import {
  SET_APPLIANCE_QUERY,
  SET_APPLIANCE_UNHEALTHY_ONLY
} from 'components/Main/Settings/Appliances/actions'

const initialState = fromJS({
  ...apiInitialState('appliance_status'),
  ...apiInitialState('appliances'),
  query: '',
  unhealthyOnly: false
})

function appliancesReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'appliance',
    initialState
  )
  if (apiResult) {
    return apiResult
  }
  switch (action.type) {
    case SET_APPLIANCE_QUERY:
      return state.merge({
        query: action.data
      })
    case SET_APPLIANCE_UNHEALTHY_ONLY:
      return state.merge({
        unhealthyOnly: action.data
      })
    default:
      return state
  }
}

export default appliancesReducer
