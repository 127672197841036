export const INIT_FIRESTORE = 'core/INIT_FIRESTORE'
export const REGISTER_FIRESTORE_COLLECTION =
  'core/REGISTER_FIRESTORE_COLLECTION'
export const HANDLE_FIRESTORE_DATA = 'core/HANDLE_FIRESTORE_DATA'
export const DELETE_FIRESTORE_DATA = 'core/DELETE_FIRESTORE_DATA'
export const REGISTER_FIRESTORE_SUBSCRIPTION =
  'core/REGISTER_FIRESTORE_SUBSCRIPTION'
export const UNSUBSCRIBE_FIRESTORE = 'core/UNSUBSCRIBE_FIRESTORE'
export const REGISTER_FIRESTORE_DB = 'core/REGISTER_FIRESTORE_DB'

export const initFirestore = (channel, listeners) => ({
  type: INIT_FIRESTORE,
  channel: channel,
  listeners: listeners
})

export const registerFirestoreDB = firestoreDB => ({
  type: REGISTER_FIRESTORE_DB,
  firestoreDB: firestoreDB
})

export const registerFirestoreCollection = (channel, firestoreCollection) => ({
  type: REGISTER_FIRESTORE_COLLECTION,
  channel: channel,
  firestoreCollection: firestoreCollection
})

export const handleFirestoreData = (channel, userUuid, docId, data) => ({
  type: HANDLE_FIRESTORE_DATA,
  channel: channel,
  userUuid: userUuid,
  docId: docId,
  data: data
})

export const deleteFirestoreData = (channel, userUuid, docId) => ({
  type: DELETE_FIRESTORE_DATA,
  channel: channel,
  userUuid: userUuid,
  docId: docId
})

export const registerFirestoreSubscription = subscription => ({
  type: REGISTER_FIRESTORE_SUBSCRIPTION,
  subscription: subscription
})

export const unsubscribeFirestore = () => ({
  type: UNSUBSCRIBE_FIRESTORE
})
