import { fromJS } from 'immutable'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('breakdown'),
  ...apiInitialState('summary'),
  ...apiInitialState('procedures'),
  ...apiInitialState('events')
})

function deviceClinicalUtilizationReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'utilization/group/clinical',
    initialState
  )

  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    default:
      return state
  }
}

export default deviceClinicalUtilizationReducer
