import apiAction from 'common/actions/api'

const dashboardApi = apiAction('dashboard')
const userApi = apiAction('user')
const customDashboardApi = apiAction('custom_dashboard')
export const UPDATE_USER_FEATURE_FLAG = 'auth/login/user/feature_flags'
export const CHANGE_INVENTORY_GROUP_TYPE =
  'cylera/dashboard/overview/inventory_type'
export const SELECT_DASHBOARD = 'cylera/dashboard/select_dashboard'
export const getSummary = () => dashboardApi('summary')
export const getInventory = () => dashboardApi('inventory')
export const getRisk = () => dashboardApi('risk')
export const getThreat = () => dashboardApi('threat')
export const getNetwork = () => dashboardApi('network')
export const getUtilization = () => dashboardApi('utilization')
export const getCriticalDeviceCount = () =>
  dashboardApi('critical_device_count')
export const dismissBanner = (featureFlagKey, callback) => {
  return userApi(
    'disable_feature_flag',
    { feature_flag_key: featureFlagKey },
    { method: 'POST', success: callback }
  )
}

export function initDashboard() {
  return dispatch => {
    dispatch(getSummary())
    dispatch(getInventory())
    dispatch(getRisk())
    dispatch(getThreat())
    dispatch(getNetwork())
    dispatch(getCriticalDeviceCount())
  }
}

export function changeInventoryGroupType(value) {
  return dispatch => {
    dispatch({
      type: CHANGE_INVENTORY_GROUP_TYPE,
      data: value
    })
  }
}

export function selectDashboard(value) {
  return dispatch => {
    dispatch({
      type: SELECT_DASHBOARD,
      data: value
    })
  }
}

export const getDashboards = () => customDashboardApi('user')
