import { TRACK_ROUTE_SERVED } from 'common/actions/history'
import { fromJS } from 'immutable'

import routes from 'core/routes'

const initialState = fromJS({
  impressions: []
})

export const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRACK_ROUTE_SERVED:
      let impressions = state
        .get('impressions')
        .filter(impression => !impression.get('modal'))
      const route = routes.get(action.path)
      const lastRoute = impressions.last()
      const { match } = action
      const { params } = match || {}

      if (route && lastRoute && route.get('route') === lastRoute.get('route')) {
        return state
      }
      return state.update('impressions', impressions =>
        impressions.push(
          route.merge({
            params,
            route_evaluated: window.location.pathname
          })
        )
      )
    default:
      return state
  }
}

export default historyReducer
