import { selectIn } from 'common/util/selector'

const base = (state, props) => state.main.risk.risks

export const riskList = selectIn(base, 'list')
export const riskListLoading = selectIn(base, 'list_loading')
export const riskListTotal = selectIn(base, 'list_total')
export const riskListFilters = selectIn(base, 'list_filters')
export const riskListFiltersLoading = selectIn(base, 'list_filters_loading')
export const riskListFilterValues = selectIn(base, 'list_filter_values')
export const riskListSortDirection = selectIn(base, 'sort_direction')
export const riskListSortKey = selectIn(base, 'sort_key')
