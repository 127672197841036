import { createSelector } from 'reselect'

export const devices = state => state.main.devices.inventory.get('devices')
export const devicesLoading = state =>
  state.main.devices.inventory.get('devices_loading')
export const devicesTotal = state =>
  state.main.devices.inventory.get('devices_total')
export const filters = state => state.main.devices.inventory.get('filters')
export const filterOptions = state =>
  state.main.devices.inventory.get('filter_options')
export const filterOptionsLoading = state =>
  state.main.devices.inventory.get('filter_options_loading')
export const devicesColumnVisibilities = state =>
  state.main.devices.inventory.get('devices_column_visibilities')
export const modelFilterOptions = state =>
  state.main.devices.inventory.get('model_filter_options')
export const typeFilterOptions = state =>
  state.main.devices.inventory.get('type_filter_options')
export const vendorFilterOptions = state =>
  state.main.devices.inventory.get('vendor_filter_options')
export const filtersLoading = state =>
  state.main.devices.inventory.get('filters_loading')
export const filterValues = state =>
  state.main.devices.inventory.get('filter_values')
export const persistedActiveFilters = state =>
  state.main.devices.inventory.get('active_filters')
export const queryFilter = state =>
  state.main.devices.inventory.get('query_filter')
export const persistedPresetFilters = state =>
  state.main.devices.inventory.get('preset_filters')
export const views = state => state.main.devices.inventory.get('views')
export const selectedView = state =>
  state.main.devices.inventory.get('selected_view')
export const seenSince = createSelector(
  filterValues,
  filterValues => filterValues && filterValues.get('seen_since')
)
export const notSeenSince = createSelector(
  filterValues,
  filterValues => filterValues && filterValues.get('not_seen_since')
)
export const sortKey = state => state.main.devices.inventory.get('sort_key')
export const sortDirection = state =>
  state.main.devices.inventory.get('sort_direction')
export const summary = state => state.main.devices.inventory.get('summary')
export const orgUtilization = state =>
  state.main.devices.inventory.get('utilization')

export const location = createSelector(
  filters,
  filters => filters && filters.get('location_id')
)
export const locationValues = createSelector(
  filterValues,
  filterValues => filterValues && filterValues.get('location_id')
)

export const classSummary = state => {
  const requisite = summary(state)
  if (requisite) {
    return requisite.get('class')
  }
}

export const locationOptions = createSelector([location], location => {
  return location && Array.from(location).map(item => item.toJS())
})

export const totalIot = createSelector([classSummary], classSummary => {
  if (classSummary) {
    return classSummary
      .map(entry => entry.get('count'))
      .reduce((prev, current) => prev + current)
  }
})
