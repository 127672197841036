const ENABLED = parseInt(process.env.REACT_APP_REDUX_LOGGING, 10)

const log = (...msg) => {
  if (ENABLED === 1) {
    console.log(...msg)
  }
}

const loggingMiddleware = store => next => action => {
  log('Dispatching', action)
  let result = next(action)
  log('Next state', store.getState())
  return result
}

export default loggingMiddleware
