import { createSelector } from 'reselect'

const integrations = (state, props) =>
  state.main.settings.organization.integrations

export const list = createSelector(integrations, integrations =>
  integrations.get('integrations')
)
export const listLoading = createSelector(integrations, integrations =>
  integrations.get('integrations_loading')
)
export const listTotal = createSelector(integrations, integrations =>
  integrations.get('integrations_total')
)

export const integrationLogos = createSelector(integrations, integrations =>
  integrations.get('logos')
)
export const integrationLogosLoading = createSelector(
  integrations,
  integrations => integrations.get('logos_loading')
)
export const integrationKinds = createSelector(integrations, integrations =>
  integrations.get('kinds')
)
export const groups = createSelector(integrations, integrations =>
  integrations.get('groups')
)
export const integrationLogs = createSelector(integrations, integrations =>
  integrations.get('logs')
)
export const integrationLogsLoading = createSelector(
  integrations,
  integrations => integrations.get('logs_loading')
)
export const integrationLogsTotal = createSelector(integrations, integrations =>
  integrations.get('logs_total')
)
