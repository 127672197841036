import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import { CLEAR_DASHBOARDS, SET_FILTERS } from './actions'

export const initialFilterValues = fromJS({
  widgets: [],
  query: ''
})

const initialState = fromJS({
  ...apiInitialState('list'),
  ...apiInitialState('filter_options'),
  ...apiInitialState('dashboard'),
  filter_values: initialFilterValues
})

function customDashboardsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'custom_dashboard',
    initialState
  )

  if (apiResult) {
    return apiResult
  }
  switch (action.type) {
    case SET_FILTERS:
      const filters = action.data.filters
      return state.merge({
        filter_values: filters
      })
    case CLEAR_DASHBOARDS:
      return state.merge({
        list: undefined,
        list_total: undefined
      })
    default:
      return state
  }
}

export default customDashboardsReducer
