import { lowerCase } from 'common/util/string'

export const FLASH_MESSAGE = 'core/notifications/FLASH_MESSAGE'
export const REGISTER_LISTENER = 'core/notifications/REGISTER_LISTENER'

export const flashMessage = (channel, message) => ({
  type: FLASH_MESSAGE,
  channel: channel,
  message: message
})

export const registerListener = (channel, listener) => ({
  type: REGISTER_LISTENER,
  channel: channel,
  listener: listener
})

// Common types of messages

export const failureMessage = (
  channel,
  message,
  title,
  timeout,
  actions = undefined
) =>
  flashMessage(channel, {
    actions: actions,
    display: true,
    kind: 'alert',
    title: title || 'Alert',
    message: message,
    timeout: timeout || 5
  })

export const generalMessage = (
  channel,
  message,
  title,
  timeout,
  actions = undefined
) =>
  flashMessage(channel, {
    actions: actions,
    display: true,
    kind: 'general',
    title: title || '',
    message: message,
    timeout: timeout || 5
  })

export const noteMessage = (
  channel,
  message,
  title,
  timeout,
  actions = undefined
) =>
  flashMessage(channel, {
    actions: actions,
    display: true,
    kind: 'note',
    title: title || '',
    message: message,
    timeout: timeout || 5
  })

export const successMessage = (
  channel,
  message,
  title,
  timeout,
  actions = undefined
) =>
  flashMessage(channel, {
    actions: actions,
    display: true,
    kind: 'success',
    title: title || 'Success',
    message: message,
    timeout: timeout || 5
  })

// Common messages

export const exportConfirmation = (channel, type = 'data', ...props) =>
  generalMessage(
    channel,
    `You'll receive an email with your ${lowerCase(
      type
    )} export in a few minutes.`,
    'Generating Export',
    ...props
  )

export const notificationsActions = (dispatch, channel = 'notifications') => ({
  // Common types of messages
  flashFailure: (...props) => dispatch(failureMessage(channel, ...props)),
  flashGeneral: (...props) => dispatch(generalMessage(channel, ...props)),
  flashNote: (...props) => dispatch(noteMessage(channel, ...props)),
  flashSuccess: (...props) => dispatch(successMessage(channel, ...props)),

  // Common messages
  flashExportConfirmation: (...props) =>
    dispatch(exportConfirmation(channel, ...props))
})

export default notificationsActions
