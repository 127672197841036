import { REGISTER_LISTENER } from 'common/actions/notifications'

const initialState = {
  channels: {}
}

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_LISTENER:
      let listeners = [action.listener]
      if (state.channels[action.channel]) {
        listeners = state.channels[action.channel]['listeners']
        listeners.push(action.listener)
      }
      return {
        ...state,
        channels: {
          ...state.channels,
          [action.channel]: {
            listeners: listeners
          }
        }
      }
    default:
      return state
  }
}

export default notificationsReducer
