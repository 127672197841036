import { fromJS } from 'immutable'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('details'),
  ...apiInitialState('devices'),
  ...apiInitialState('devices_breakdown'),
  ...apiInitialState('groups'),
  ...apiInitialState('policies'),
  policies_query: null,
  ...apiInitialState('available_policies'),
  ...apiInitialState('apply_policy'),
  ...apiInitialState('profile_options'),
  ...apiInitialState('profile'),
  ...apiInitialState('policy_kind_options'),
  ...apiInitialState('rules'),
  ...apiInitialState('rules_options'),
  ...apiInitialState('save_profile'),
  ...apiInitialState('nac_details'),
  reference_rules: undefined,
  reference_rules_loading: false,
  ...apiInitialState('create_policy'),
  ...apiInitialState('reference'),
  ...apiInitialState('generation_settings'),
  generate_policy: null,
  generate_policy_loading: false,
  can_save_rules: true
})

function policiesReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'network/policy')
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case 'can_save_rules':
      return state.merge({
        can_save_rules: action.payload
      })
    default:
      return state
  }
}

export default policiesReducer
