import apiAction from 'common/actions/api'

export const CLEAR_INTEGRATIONS =
  'cylera/settings/integrations/CLEAR_INTEGRATIONS'
const integrationsApi = apiAction('settings/integrations')

export const loadIntegrationInstances = (
  page,
  sortKey,
  sortDirection,
  filterValues
) =>
  integrationsApi('integrations', {
    page: page,
    sort_key: sortKey,
    sort_direction: sortDirection
  })

export const loadIntegrationLogos = success =>
  integrationsApi('logos', {}, { success })

export const loadIntegrations = callback =>
  integrationsApi(
    'kinds',
    {},
    {
      cacheable: false,
      success: callback
    }
  )
export const addIntegrationInstance = (integration, success) =>
  integrationsApi(
    'instance/add',
    {
      integration
    },
    {
      method: 'POST',
      success
    }
  )
export const loadGroups = callback =>
  integrationsApi(
    'groups',
    {},
    {
      cacheable: false,
      success: callback
    }
  )
export const loadIntegrationLogs = (
  uuid,
  page,
  pageSize,
  sortKey,
  sortDirection,
  query,
  filters,
  callback
) =>
  integrationsApi(
    'logs',
    {
      uuid,
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query,
      ...filters
    },
    { cacheable: false, success: callback }
  )

export const loadIntegrationLogsInfinite = (
  uuid,
  page,
  pageSize,
  sortKey,
  sortDirection,
  query,
  filters,
  callback
) =>
  integrationsApi(
    'logs',
    {
      uuid,
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query,
      ...filters
    },
    { success: callback, infinite: true, cacheable: false }
  )

export const triggerTicketingIntegrations = (
  shortDescription,
  longDescription,
  url,
  entityType,
  entityId,
  severity,
  successCallback
) =>
  integrationsApi(
    'trigger_ticketing_integrations',
    {
      short_description: shortDescription,
      long_description: longDescription,
      url: url,
      entity_type: entityType,
      entity_id: entityId,
      severity: severity
    },
    {
      method: 'POST',
      success: () => {
        successCallback()
      }
    }
  )

export const clearIntegrations = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_INTEGRATIONS
    })
  }
}
