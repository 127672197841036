export const REGISTER_FIREBASE_APP = 'core/REGISTER_FIREBASE_APP'
export const REGISTER_FIREBASE_PERFORMANCE =
  'core/REGISTER_FIREBASE_PERFORMANCE'

export const registerFirebaseApp = firebaseApp => ({
  type: REGISTER_FIREBASE_APP,
  firebaseApp: firebaseApp
})

export const registerFirebasePerformance = firebaseApp => ({
  type: REGISTER_FIREBASE_PERFORMANCE,
  firebaseApp: firebaseApp
})
