import { safeAccess } from 'common/util/iterable'
import { fromJS } from 'immutable'
import includes from 'lodash/includes'

const API_TOKEN = `API_TOKENcylera-web-app`
const API_PAGING_PARAMS = ['page', 'page_size', 'total', 'unresolved']
const ALLOWED_ROLES = [
  'user',
  'admin',
  'cylera',
  'admin-user',
  'sales_engineer'
]

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const imageHeaders = {
  Accept: 'image'
}

export class API {
  getBaseURL = () => {
    if (process.env.REACT_APP_API_BASE_URL) {
      return process.env.REACT_APP_API_BASE_URL
    } else {
      return `${window.location.origin}/api`
    }
  }

  getWebsocketURL = namespace => {
    if (process.env.REACT_APP_API_BASE_URL) {
      return `${process.env.REACT_APP_API_BASE_URL}/${namespace}`
    } else {
      return `${window.location.origin}/${namespace}`
    }
  }

  getFullUrl = url => {
    this.onAuthPage = false
    this.onAuthVerifyPage = false
    this.onLoginPage = false
    if (url.startsWith('/auth')) {
      this.onAuthPage = true
    }
    if (url === '/auth/verify') {
      this.onAuthVerifyPage = true
    }
    if (url === '/' || url === '/login') {
      this.onLoginPage = true
    }
    return this.getBaseURL() + url
  }

  hasToken = () => {
    return this.getToken() !== null
  }

  getToken = () => {
    return window.localStorage.getItem(API_TOKEN)
  }

  setToken = token => {
    window.localStorage.setItem(API_TOKEN, token)
  }

  deleteToken = () => {
    window.localStorage.removeItem(API_TOKEN)
  }

  appendAuthHeader = headers => {
    return { ...headers, ...{ Authorization: 'Token ' + this.getToken() } }
  }

  appendGetParams = (url, params = {}) => {
    if (params === {} || Object.keys(params).length === 0) return url

    const queryParams = Object.keys(params)
      .map(key => {
        const param = this.formatParam(params[key])
        // Filter out blank params
        if (
          param !== undefined &&
          param !== null &&
          param !== [] &&
          (!Array.isArray(param) || param.length > 0)
        ) {
          if (param !== 1) {
            window.p = param
            window.px = params[key]
          }
          return `${key}=${param}`
        }
        return undefined
      })
      .filter(val => val !== undefined)
      .join('&')
    return `${url}?${queryParams}`
  }

  formatParam = param => {
    // Make Immutable arrays URL friendly
    if (param && param.hasOwnProperty('size')) {
      if (param.size > 0) {
        return Array.from(param)
      }
      return undefined
    }
    return param
  }

  get = (url, params = {}, signal = undefined, headers = {}) => {
    const fullUrl = this.getFullUrl(url)
    return fetch(this.appendGetParams(fullUrl, params), {
      method: 'GET',
      signal: signal,
      headers: new Headers({ ...headers })
    }).then(this.handleResponse)
  }

  mutate = (url, params = {}, signal = undefined, headers = {}, method) => {
    const fullUrl = this.getFullUrl(url)
    const { files, ...otherParams } = params
    if (files) {
      const formData = new FormData()
      for (let [index, file] of files.entries()) {
        formData.append(`file${index}`, file)
      }
      for (let [key, value] of Object.entries(otherParams)) {
        if (value) {
          formData.append(key, value)
        }
      }
      return fetch(fullUrl, {
        method: method,
        signal: signal,
        headers: new Headers({ ...headers }),
        body: formData
      }).then(this.handleResponse)
    }
    return fetch(fullUrl, {
      method: method,
      signal: signal,
      headers: new Headers({ ...defaultHeaders, ...headers }),
      body: JSON.stringify(params)
    }).then(this.handleResponse)
  }

  image_post = (url, image_data, headers = {}) => {
    const fullUrl = this.getFullUrl(url)
    var data = new FormData()
    data.append('file', image_data.file)
    data.append('tag_id', image_data.tag_id)
    data.append('extension', image_data.extension)
    return fetch(fullUrl, {
      method: 'POST',
      headers: new Headers({ ...imageHeaders, ...headers }),
      body: data
    }).then(this.handleResponse)
  }

  withAuth = () => {
    return {
      get: (url, params, signal, headers) =>
        this.get(url, params, signal, this.appendAuthHeader(headers)),
      mutate: (url, params, signal, headers, method) =>
        this.mutate(
          url,
          params,
          signal,
          this.appendAuthHeader(headers),
          method
        ),
      image_post: (url, image_data, signal, headers) =>
        this.image_post(url, image_data, signal, this.appendAuthHeader(headers))
    }
  }

  handleResponse = async response => {
    // Check token auth
    if (response.ok) {
      return response
    }

    if (response.status !== 401) {
      return response
    }

    try {
      const json = await response.clone().json()
      // entered credentials
      if (json.token) {
        this.setToken(json.token)
      }
      // entered credentials or navigated to page while unauthed
      if (json.mfa_required && !this.onAuthVerifyPage) {
        window.location = '/auth/verify'
        return undefined
      }
      if (json.password_change_required) {
        if (json.reset_link.split('?').length === 2) {
          window.location = `${window.location.origin}/auth/reset-password?${
            json.reset_link.split('?')[1]
          }`
        } else {
          window.location = json.reset_link
        }
      }
    } catch (err) {
      console.error('Unparseable 401 response', err)
    }

    if (!(this.onLoginPage || this.onAuthPage)) {
      this.deleteToken()
      window.location = '/login'
      return undefined
    }

    return response
  }

  /**
   * Checks if role is allowed to login to dashboard
   * @param {string} role
   * @returns boolean
   */
  checkAllowedRoles = role => {
    return includes(ALLOWED_ROLES, role)
  }
}

export const apiActionCacheKey = (action, globalParams) => {
  return `${action.api.path}:${JSON.stringify(
    action.api.params
  )}:${JSON.stringify(globalParams)}`
}

export const apiActionType = action =>
  action && action.type.substr(0, action.type.indexOf(':'))

export const extractPagingParams = (response, addKeyPrefix = '') => {
  let params = {}
  API_PAGING_PARAMS.map(key => {
    if (key in response) {
      if (key === 'total' && response['page'] !== 0) {
        return null
      }
      params = {
        ...params,
        [`${addKeyPrefix}${key}`]: response[key]
      }
    }
    return null
  })
  return params
}

export const getGlobalParamValues = state => {
  const globalParams = safeAccess(state, ['api', 'params'])
  let globalParamValues = {}
  if (!globalParams) {
    return undefined
  }
  globalParams.keySeq().forEach(key => {
    globalParamValues[key] = safeAccess(state, globalParams.get(key))
  })
  return globalParamValues
}

export const mergeExistingKeys = (state, values) => {
  const keys = Array.from(state.keys())
  for (var val in values) {
    if (keys.includes(val)) {
      state = state.merge({
        [val]: values[val]
      })
    }
  }
  return state
}

export const appendInfiniteExistingKeys = (state, values) => {
  const keys = Array.from(state.keys())
  for (var val in values) {
    if (keys.includes(val)) {
      if (Array.isArray(values[val])) {
        state = state.merge({
          [val]: safeAccess(state, [val])
            ? safeAccess(state, [val]).concat(fromJS(values[val]))
            : values[val]
        })
      } else {
        state = state.merge({
          [val]: values[val]
        })
      }
    }
  }
  return state
}

export default new API()
