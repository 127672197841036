import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'

import { API_PURGE_STORE } from 'common/actions/api'
import {
  LOGIN_REMOVE_ERROR,
  LOGIN_FAILURE,
  SET_LOGIN_LOADING
} from 'common/actions/login'
import { UPDATE_USER_FEATURE_FLAG } from '../../components/Main/Dashboard/DataAccess/actions'

const initialState = fromJS({
  user: {
    modules: {
      assess: true,
      monitoring_add_on: true,
      identify_business_analytics_add_on: true,
      extend_add_on: true,
      segment: true
    }
  },
  is_loading: false,
  error: undefined,
  login_test: true,
  contract_usage_status: undefined
})

function loginReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'auth', initialState)

  if (apiResult) {
    if (action.type === 'core/API_REQUEST_SUCCESS:auth:/auth/get_user') {
      return state.mergeIn(['user'], action.data)
    }
    return apiResult
  }
  switch (action.type) {
    case API_PURGE_STORE:
      return initialState
    case LOGIN_REMOVE_ERROR:
      return state.merge({
        error: undefined
      })
    case LOGIN_FAILURE:
      return state.merge({
        error: action.data
      })
    case UPDATE_USER_FEATURE_FLAG:
      const { key, value } = action.payload
      return state.merge({
        user: state
          .get('user')
          .mergeIn(['feature_flags'], fromJS({ [key]: value }))
      })
    case SET_LOGIN_LOADING:
      return state.merge({
        is_loading: action.payload
      })
    default:
      return state
  }
}

export default loginReducer
