import { actions as notificationActions } from './organization/notifications/'
import { selectors as notificationSelectors } from './organization/notifications/'
import { selectors as overviewSelectors } from './organization/summary'
import { actions as overviewActions } from './organization/summary'
import reducer from './reducer'

const actions = { ...notificationActions, ...overviewActions }
const selectors = { ...notificationSelectors, ...overviewSelectors }

export { actions, selectors, reducer }
