import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import { RESET_USERS_LIST, SET_QUERY, SET_SORT } from './actions'
import { SORT_ASC } from 'common/constants'

const initialState = fromJS({
  users: undefined,
  users_loading: true,
  users_total: undefined,
  sort_key: 'first_name',
  sort_direction: SORT_ASC,
  query: undefined,
  ...apiInitialState('roles')
})

function usersListReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'users', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case RESET_USERS_LIST:
      return state.merge({
        users: initialState.get('users'),
        users_loading: initialState.get('users_loading')
      })

    case SET_SORT:
      const sortKey = action.data['sort_key']
      const sortDirection = action.data['sort_direction']
      return state.merge({
        sort_key: sortKey,
        sort_direction: sortDirection
      })
    case SET_QUERY:
      return state.merge({
        query: action.data
      })
    default:
      return state
  }
}

export default usersListReducer
