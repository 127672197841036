import { fromJS } from 'immutable'

export function convertFilterValueToArray(value) {
  if (value === '') {
    return []
  }
  if (typeof value === 'number') {
    return value
  }
  return Array.from(value.split(','))
}

export function firstKnownValue(...vals) {
  for (var val of vals) {
    if (val && val !== 'Unknown') {
      return val
    }
  }
}

export function ifDefined(value, fallback = '') {
  if (value !== undefined) {
    return value
  }
  return fallback
}

export const isDefined = (value, allowNull = false) => {
  if (value !== undefined && (allowNull || value !== null)) {
    return true
  }
  return false
}

export function objectIsEmpty(value) {
  return value === undefined || JSON.stringify(value) === '{}'
}

export const mapToLabelValueDicts = input => {
  if (!input) {
    return null
  }
  const values = input.toJS()
  const dicts = []
  for (var key in values) {
    dicts.push({
      label: key,
      value: values[key]
    })
  }
  return fromJS(dicts)
}

export const mapToAggregateLabelsValues = input => {
  /* Provided a map, returns a dict with aggregated lists of labels/values
   e.g. {
    labels: ['a', 'b', 'c'],
    values: [1, 2, 3],
   }
  */
  if (!input) {
    return null
  }
  const labels = Array.from(input.keys())
  return labels.reduce(
    (data, label) => {
      data['labels'].push(label)
      data['values'].push(input.get(label))
      return data
    },
    {
      labels: [],
      values: []
    }
  )
}

export const sumMapKeys = dicts => {
  if (!dicts || dicts.size < 1) {
    return dicts
  }
  const keys = Array.from(dicts.get(0).keys())
  let merged = {}
  const reducer = (total, dict) => {
    return total + dict.get(key.toString())
  }
  for (var key in keys) {
    const total = dicts.reduce(reducer, 0)
    merged[key] = total
  }
  return fromJS(merged)
}

export const zipLabelsValues = (
  input,
  labelFormatter = label => label,
  valueFormatter = value => value
) => {
  if (!input) {
    return null
  }
  const { labels, values } = input
  let data = []
  for (let index = 0; index < values.size; index++) {
    data.push({
      label: labelFormatter(labels.get(index)),
      value: valueFormatter(values.get(index))
    })
  }
  return data
}
