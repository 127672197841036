import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import { CHANGE_INVENTORY_GROUP_TYPE, SELECT_DASHBOARD } from './actions'

const initialState = fromJS({
  ...apiInitialState('summary'),
  ...apiInitialState('inventory'),
  ...apiInitialState('risk'),
  ...apiInitialState('threat'),
  ...apiInitialState('network'),
  ...apiInitialState('utilization'),
  ...apiInitialState('critical_device_count'),
  ...apiInitialState('user'),
  inventory_group_type: 'Type',
  selected_dashboard: undefined
})

function dashboardReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'dashboard',
    initialState
  )

  const customDashboardsApiResult = apiNamespaceReducer(
    state,
    action,
    'custom_dashboard',
    initialState
  )

  if (apiResult) {
    return apiResult
  }

  if (customDashboardsApiResult) {
    return customDashboardsApiResult
  }

  switch (action.type) {
    case CHANGE_INVENTORY_GROUP_TYPE:
      return state.merge({
        inventory_group_type: action.data
      })
    case SELECT_DASHBOARD:
      return state.merge({
        selected_dashboard: action.data
      })
    default:
      return state
  }
}

export default dashboardReducer
