import { fromJS } from 'immutable'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('token')
})

function exportReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'download', initialState)
  if (apiResult) {
    return apiResult
  }

  return state
}

export default exportReducer
