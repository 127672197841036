import { fromJS } from 'immutable'
import { combineReducers } from 'redux'

import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('download'),
  ...apiInitialState('frequencies'),
  ...apiInitialState('generated'),
  ...apiInitialState('kinds'),
  ...apiInitialState('scheduled'),
  ...apiInitialState('recipients'),
  ...apiInitialState('details'),
  ...apiInitialState('fields')
})

function reportingReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'reporting',
    initialState
  )
  if (apiResult) {
    return apiResult
  }

  return state
}

export default combineReducers({
  scheduled: reportingReducer
})
