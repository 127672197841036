import API, {
  apiActionCacheKey,
  apiActionType,
  extractPagingParams,
  getGlobalParamValues
} from 'common/util/api'
import { safeAccess } from 'common/util/iterable'
import { FEATURE_API_ERROR_ALERT, FeatureEnabled } from 'common/util/env'
import {
  API_PURGE_CACHE_FOR_ROUTE,
  API_PURGE_STORE,
  API_REFETCH_FOR_ROUTE,
  API_REQUEST_CALLBACK,
  API_REQUEST_FAILURE,
  API_REQUEST_LOADING,
  API_REQUEST_SUCCESS,
  callbackAction
} from 'common/actions/api'

import { failureMessage as failureMessageAction } from 'common/actions/notifications'

const constructFailureMessage = ({
  title,
  message,
  channel = 'notifications'
} = {}) => failureMessageAction(channel, message, title)

const executeRequest = (dispatch, request, controller, getState, cacheKey) => {
  const {
    method,
    namespace,
    params,
    path,
    responseKey,
    failure: failureCallbacks,
    success: successCallbacks,
    unauthorized: unauthorizedCallbacks
  } = request

  const failureAction = (message, unauthorized, failureCallback, getState) => ({
    callback: failureCallback,
    callbackName: `FAILURE:${namespace}:${path}`,
    callbackMessage: message,
    getState,
    data: message,
    type: `${API_REQUEST_FAILURE}:${namespace}:${path}`,
    api: request,
    unauthorized: unauthorized
  })

  const handleFailure = (
    message,
    status,
    onUnauthorizedCallbacks,
    unauthorizedStatus = [401, 403]
  ) => {
    if (unauthorizedStatus.includes(status)) {
      if (onUnauthorizedCallbacks) {
        onUnauthorizedCallbacks.forEach((callback, index) => {
          callback(message)
        })
      }
    } else if (FeatureEnabled(FEATURE_API_ERROR_ALERT)) {
      dispatch(
        constructFailureMessage({
          message: `Error executing request ${path}`,
          title: 'API Error'
        })
      )
    }

    dispatch(failureAction(message, status === 403, failureCallbacks, getState))
  }

  const requestHandler =
    method === 'GET' ? API.withAuth().get : API.withAuth().mutate

  // Grab the latest values for any globally stored params
  const requestParams = {
    ...params,
    ...getGlobalParamValues(getState())
  }

  requestHandler(path, requestParams, controller.signal, undefined, method)
    .then(response => {
      if (response) {
        if (response.ok) {
          return response.json().then(json => {
            if (
              process.env.REACT_APP_DEV_MODE &&
              !(responseKey in json) &&
              method === 'GET'
            ) {
              console.error(
                `${responseKey} not in response - are you trying to store this response in Redux? Response:`,
                json
              )
            }

            const response = json[responseKey]

            let successAction = {
              callback: successCallbacks,
              callbackName: `SUCCESS:${namespace}:${path}`,
              callbackMessage: json,
              getState,
              type: `${API_REQUEST_SUCCESS}:${namespace}:${path}`,
              data: response,
              api: request,
              cacheKey: cacheKey
            }
            dispatch({
              ...successAction,
              ...extractPagingParams(json)
            })
          })
        } else {
          window.r = response
          return response.json().then(json => {
            handleFailure(
              json['message'] || json['messages'],
              response.status,
              unauthorizedCallbacks
            )
          })
        }
      }
    })
    .catch(error => {
      if (!controller.signal.aborted) {
        console.error(error)
        handleFailure('Unknown error occured!', null, unauthorizedCallbacks)
      }
    })
}

const getMostRecentRoute = routesToRefetch => {
  return routesToRefetch
    .sort((route1, route2) => (route1.cached > route2.cached ? 1 : -1))
    .reduce(
      (acc, route) => ({
        ...acc,
        [route.api.path]: [
          route,
          ...(acc[route.api.path] ?? []).filter(
            r => r.api.forceRefetch === true
          )
        ]
      }),
      {}
    )
}

export const apiMiddleware = store => next => action => {
  const nextAction = next(action)
  if (action.type === API_PURGE_STORE && action.callback) {
    action.callback()
  }
  if (action.api || action.callback) {
    const actionType = apiActionType(action)
    switch (actionType) {
      case API_REQUEST_LOADING:
        const state = store.getState()
        const cacheKey = apiActionCacheKey(action, getGlobalParamValues(state))
        executeRequest(
          store.dispatch,
          action.api,
          action.controller,
          store.getState,
          cacheKey
        )
        break
      case API_REQUEST_SUCCESS:
        if (action.callback) {
          if (Array.isArray(action.callback)) {
            for (let index in action.callback) {
              store.dispatch(
                callbackAction({ ...action, callback: action.callback[index] })
              )
            }
          } else {
            store.dispatch(callbackAction(action))
          }
        }
        break
      case API_REQUEST_FAILURE:
        if (Array.isArray(action.callback)) {
          for (let index in action.callback) {
            store.dispatch(
              callbackAction({ ...action, callback: action.callback[index] })
            )
          }
        } else {
          store.dispatch(callbackAction(action))
        }
        break
      case API_REQUEST_CALLBACK:
        const { callback, callbackMessage, getState, unauthorized } = action
        if (Array.isArray(callback)) {
          for (let index in callback) {
            callback[index](callbackMessage, getState, unauthorized)
          }
        } else {
          callback(callbackMessage, getState, unauthorized)
        }

        break
      default:
        return false
    }
  }
  if (action.type === API_REFETCH_FOR_ROUTE) {
    const { route } = action
    const state = store.getState()
    const cached = safeAccess(state, ['api', 'cached'])

    const refetch = cached
      .valueSeq()
      .filter(cache => cache.getIn(['api', 'route']) === route)
      .toJS()

    const mostRecentRoutes = getMostRecentRoute(refetch)
    const resetCache = { type: API_PURGE_CACHE_FOR_ROUTE, data: route }
    store.dispatch(resetCache)
    for (let route in mostRecentRoutes) {
      for (let action in mostRecentRoutes[route]) {
        const controller = new window.AbortController()
        const { api } = mostRecentRoutes[route][action]
        const { namespace, path } = api
        const loadingAction = {
          type: `${API_REQUEST_LOADING}:${namespace}:${path}`,
          controller,
          api
        }
        store.dispatch(loadingAction)
      }
    }
  }
  return nextAction
}

export default apiMiddleware
