import {
  HIDE_MODAL,
  HIDE_MODAL_LOADING,
  SHOW_MODAL,
  SHOW_MODAL_LOADING
} from 'common/actions/modal'

const initialState = {
  body: undefined,
  loading: false,
  open: false
}

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_MODAL:
      return {
        ...state,
        open: false,
        loading: false
      }
    case HIDE_MODAL_LOADING:
      return {
        ...state,
        loading: false
      }
    case SHOW_MODAL:
      return {
        ...state,
        open: true,
        body: action.body,
        handleClose: action.handleClose,
        loading: false
      }
    case SHOW_MODAL_LOADING:
      return {
        ...state,
        loading: true
      }
    default:
      return state
  }
}

export default modalReducer
