import apiAction from 'common/actions/api'
import API from 'common/util/api'

export const VERIFY_REMOVE_ERROR = 'cylera/auth/VERIFY_REMOVE_ERROR'

const authApi = apiAction('auth')

export const submitVerificationCode = (
  code,
  successCallback,
  failureCallback
) =>
  authApi(
    'verify',
    {
      verification_code: code
    },
    {
      method: 'POST',
      success: response => {
        API.setToken(response.token)
        successCallback()
      },
      failure: failureCallback
    }
  )

export const sendSMSCode = (successCallback, failureCallback) =>
  authApi(
    'resend',
    {},
    {
      method: 'POST',
      success: successCallback,
      failure: failureCallback
    }
  )
