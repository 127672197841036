import { combineReducers } from 'redux'

import { reducer as integrationsReducer } from 'components/Main/Integrations/DataAccess/'
import { reducer as notificationsReducer } from './notifications'
import { reducer as summaryReducer } from './summary'

export default combineReducers({
  summary: summaryReducer,
  integrations: integrationsReducer,
  notifications: notificationsReducer
})
