import { selectIn } from 'common/util/selector'

const dashboard = (state, props) => state.main.dashboard

export const summary = selectIn(dashboard, 'summary')
export const summaryLoading = selectIn(dashboard, 'summary_loading')

export const inventory = selectIn(dashboard, 'inventory')
export const inventoryLoading = selectIn(dashboard, 'inventory_loading')

export const risk = selectIn(dashboard, 'risk')
export const riskLoading = selectIn(dashboard, 'risk_loading')
export const riskUnauthorized = selectIn(dashboard, 'risk_unauthorized')

export const threat = selectIn(dashboard, 'threat')
export const threatLoading = selectIn(dashboard, 'threat_loading')
export const threatUnauthorized = selectIn(dashboard, 'threat_unauthorized')

export const network = selectIn(dashboard, 'network')
export const networkLoading = selectIn(dashboard, 'network_loading')
export const networkUnauthorized = selectIn(dashboard, 'network_unauthorized')

export const utilization = selectIn(dashboard, 'utilization')
export const utilizationLoading = selectIn(dashboard, 'utilization_loading')

export const criticalDeviceCount = selectIn(dashboard, 'critical_device_count')
export const criticalDeviceCountLoading = selectIn(
  dashboard,
  'critical_device_count_loading'
)

export const inventoryGroupType = selectIn(dashboard, 'inventory_group_type')
export const selected_dashboard = selectIn(dashboard, 'selected_dashboard')

export const dashboards = selectIn(dashboard, 'user')
export const dashboardsLoading = selectIn(dashboard, 'user_loading')
