import { selectIn } from 'common/util/selector'

const base = (state, props) => state.main.settings.role

export const details = selectIn(base, 'details')
export const detailsLoading = selectIn(base, 'details_loading')
export const metadata = selectIn(base, 'metadata')
export const metadataLoading = selectIn(base, 'metadata_loading')
export const locations = selectIn(base, 'locations')
export const locationsLoading = selectIn(base, 'locations_loading')
export const assignedGroups = selectIn(base, 'assigned_groups')
export const assignedGroupsLoading = selectIn(base, 'assigned_groups_loading')
export const editGroups = selectIn(base, 'edit_groups')
export const editGroupsLoading = selectIn(base, 'edit_groups_loading')
export const editGroupsTotal = selectIn(base, 'edit_groups_total')
export const editGroupsUuids = selectIn(base, 'edit_groups_uuids')
export const totalGroups = selectIn(base, 'total_groups')
export const permissions = selectIn(base, 'permissions')
export const permissionsLoading = selectIn(base, 'permissions_loading')
export const users = selectIn(base, 'users')
export const usersLoading = selectIn(base, 'users_loading')
export const usersTotal = selectIn(base, 'users_count')
export const filterValues = selectIn(base, 'filter_values')
export const sortKey = selectIn(base, 'sort_key')
export const sortDirection = selectIn(base, 'sort_direction')
export const dashboards = selectIn(base, 'secondary_dashboards')
export const dashboardsLoading = selectIn(base, 'secondary_dashboards_loading')
export const primaryDashboard = selectIn(base, 'primary_dashboard')
export const primaryDashboardLoading = selectIn(
  base,
  'primary_dashboard_loading'
)
