import { fromJS, List } from 'immutable'

import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'

import {
  CHANGE_GROUP_SEARCH,
  CLEAR_GROUPINGS,
  CHANGE_GROUP_LIST_FILTER,
  CHANGE_MY_GROUPINGS_SEARCH
} from './actions'

export const initialFilterValues = fromJS({
  display: 'cards',
  grouping: 'type',
  owner: null,
  search: '',
  selectedClasses: []
})

const initialState = fromJS({
  groupings_loading: true,
  groupings: undefined,
  tag_classes: [],
  search_query: '',
  refreshScroll: false,
  filter_values: initialFilterValues,
  my_groupings_search_query: '',
  ...apiInitialState('groupings'),
  ...apiInitialState('my_groupings'),
  ...apiInitialState('stats')
})

export const devicesOverviewReducer = (state = initialState, action) => {
  const apiResult = apiNamespaceReducer(state, action, 'devices', initialState)
  if (apiResult) {
    return apiResult
  }

  const { filter, value } = action.data || {}
  switch (action.type) {
    case CHANGE_GROUP_LIST_FILTER:
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filter]: value
        })
      )
    case CHANGE_GROUP_SEARCH:
      return state.merge({
        search_query: action.data
      })
    case CHANGE_MY_GROUPINGS_SEARCH:
      return state.merge({
        my_groupings_search_query: action.data
      })
    case CLEAR_GROUPINGS:
      return state.merge({
        groupings: List([]),
        groupings_total: undefined,
        refreshScroll: !state.toJS().refreshScroll
      })

    default:
      return state
  }
}

export default devicesOverviewReducer
