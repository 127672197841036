export const INIT_LISTENERS = 'core/history/INIT_LISTENERS'
export const TRACK_ROUTE_SERVED = 'core/history/TRACK_ROUTE_SERVED'

export const initListeners = ({ history, listeners }) => ({
  type: INIT_LISTENERS,
  history,
  listeners
})

export const trackRouteServed = ({ match, path }) => ({
  type: TRACK_ROUTE_SERVED,
  match,
  path
})
