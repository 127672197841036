import { combineReducers } from 'redux'

import clinicalUtilizationDevicesReducer from './GroupUtilizationDetail/GroupUtilizationClinical/reducer'
import groupUtilizationDetailReducer from './GroupUtilizationDetail/reducer'
import groupUtilizationReducer from './GroupUtilizations/reducer'
import utilizationDevicesReducer from './GroupUtilizationDetail/GroupUtilizationDevices/reducer'

export default combineReducers({
  clinical: clinicalUtilizationDevicesReducer,
  devices: utilizationDevicesReducer,
  groups: groupUtilizationReducer,
  group_detail: groupUtilizationDetailReducer
})
