import { fromJS } from 'immutable'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'
import { convertFilterValueToArray } from 'common/util/type'
import { SORT_DESC } from 'common/constants'

import {
  CHANGE_THREATS_LIST_FILTER,
  RESET_THREATS_LIST_FILTER,
  CHANGE_SINGULAR_FILTER,
  SET_THREATS_COLUMN_VISIBILITIES,
  SET_SORT
} from './actions'

export const initialFilterValues = fromJS({
  severity: [],
  status: [1, 2],
  category: [],
  assignee: [],
  query: ''
})

export const threatsColumnVisibilities = fromJS({
  name: { label: 'Threat', visible: true },
  device_name: { label: 'Device', visible: true },
  device_mac: { label: 'MAC', visible: true },
  severity: { label: 'Severity', visible: true },
  last_seen: { label: 'Last Seen', visible: true },
  status: { label: 'Status', visible: true },
  assignee: { label: 'Assignee', visible: true },
  category: { label: 'Category', visible: false }
})

const initialState = fromJS({
  filter_values: initialFilterValues,
  threats_column_visibilities: threatsColumnVisibilities,
  sort_key: 'last_seen',
  sort_direction: SORT_DESC,
  ...apiInitialState('filters'),
  ...apiInitialState('threats')
})

function threatsListReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'threat', initialState)
  if (apiResult) {
    return apiResult
  }

  const { filter, value } = action.data || {}
  switch (action.type) {
    case CHANGE_THREATS_LIST_FILTER:
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filter]: convertFilterValueToArray(value)
        })
      )
    case CHANGE_SINGULAR_FILTER:
      return state.mergeIn(
        ['filter_values'],
        fromJS({
          [filter]: value
        })
      )
    case RESET_THREATS_LIST_FILTER:
      return state.mergeIn(['filter_values'], initialState.get('filter_values'))
    case SET_THREATS_COLUMN_VISIBILITIES:
      return state.merge({
        threats_column_visibilities: action.data
      })
    case SET_SORT:
      const sortKey = action.data['sort_key']
      const sortDirection = action.data['sort_direction']
      return state.merge({
        sort_key: sortKey,
        sort_direction: sortDirection
      })
    default:
      return state
  }
}

export default threatsListReducer
