import asyncComponent from 'common/components/AsyncComponent'
import { HomeIcon } from '@heroicons/react/outline'

export const ROUTE_DASHBOARD = '/app/dashboard'

export const dashboardRoutes = [
  // Dashboard Tabs
  {
    route: ROUTE_DASHBOARD,
    component: asyncComponent(() => import('components/Main/Dashboard'), {
      retryKey: 'overview'
    }),
    exact: true,
    icon: 'os-icon-window-content',
    heroIcon: <HomeIcon />,
    title: 'Dashboard',
    tabTitle: 'Overview',
    navbar: true,
    order: 1,
    subContexts: [/app\/dashboard\/(.*?)/]
  }
]

export default dashboardRoutes
