import apiAction from 'common/actions/api'

export const SET_FILTERS = 'cylera/settings/roles/custom/SET_FILTERS'
export const CLEAR_ROLES = 'cylera/settings/roles/custom/CLEAR_DASHBOARDS'

const rolesApi = apiAction('role')

export function clearRoles(callback) {
  return dispatch => {
    dispatch({
      type: CLEAR_ROLES
    })
  }
}

export const getRolesCount = filterValues =>
  rolesApi(
    'count',
    { role_type: 'Custom', ...filterValues },
    { cacheable: false }
  )

export const getRoles = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  filterValues,
  { infinite = false } = {}
) => {
  const { query } = filterValues || {}
  return rolesApi(
    'list',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      query,
      role_type: 'Custom'
    },
    { infinite, cacheable: false }
  )
}

export function setFilters(filters) {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      data: {
        filters
      }
    })
  }
}

export const createRole = (
  { name, description } = {},
  { onSuccess, onFailure } = {}
) => {
  return rolesApi(
    'create',
    { name, description },
    {
      infinite: false,
      cacheable: false,
      method: 'POST',
      success: onSuccess,
      failure: onFailure
    }
  )
}
