import apiAction from 'common/actions/api'

export const CHANGE_FILTER = 'cylera/alertsV2/CHANGE_FILTER'
export const CHANGE_SINGULAR_FILTER = 'cylera/alertsV2/CHANGE_SINGULAR_FILTER'
export const RESET_FILTERS = 'cylera/alertsV2/RESET_FILTERS'
export const CLEAR_INSTANCES = 'cylera/alertsV2/CLEAR_INSTANCES'

const alertApi = apiAction('alert')

export const loadInstances = (
  query,
  severity,
  status,
  page,
  pageSize,
  sortKey,
  sortDirection,
  successCallback
) => {
  return alertApi(
    'instances',
    {
      query,
      severity,
      status,
      page,
      page_size: pageSize,
      sort_direction: sortDirection,
      sort_key: sortKey
    },
    {
      cacheable: false,
      success: successCallback
    }
  )
}

export const loadInstancesInfinite = (
  query,
  severity,
  status,
  page,
  pageSize,
  sortKey,
  sortDirection,
  successCallback
) => {
  return alertApi(
    'instances',
    {
      query,
      severity,
      status,
      page,
      page_size: pageSize,
      sort_direction: sortDirection,
      sort_key: sortKey
    },
    {
      infinite: true,
      cacheable: false,
      success: successCallback
    }
  )
}

export const markAlertsRead = (callback, uuid) =>
  alertApi(
    'read_alert_v2',
    { uuid },
    { method: 'POST', cacheable: false, success: callback }
  )

export const loadInstanceCount = () => alertApi('count_v2')

export const clearInstances = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_INSTANCES
    })
  }
}

export const extendAlertAcceptance = (
  uuid,
  notificationCategory,
  triggerId,
  extendedTime,
  acceptedBefore,
  acceptedAfter,
  success,
  failure
) => {
  const body = {
    alert_instance_uuid: uuid,
    alert_category: notificationCategory,
    extended_time: extendedTime,
    accepted_before: acceptedBefore,
    accepted_after: acceptedAfter,
    ...(triggerId ? { trigger_id: triggerId } : {})
  }
  return alertApi('extend_acceptance', body, {
    method: 'POST',
    cacheable: false,
    success,
    failure
  })
}
