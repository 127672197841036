import { combineReducers } from 'redux'
import loginReducer from 'common/reducers/login'
import { reducer as ChangePasswordReducer } from './Login/components/AppLoginChangePasswordForm/DataAccess'
import { reducer as mfaReducer } from './Login/components/AppLoginMfaForm/DataAccess'
import { reducer as LoginSSOReducer } from './Login/components/AppLoginSSOForm/DataAccess'
import { reducer as ResetPasswordReducer } from './Login/components/AppResetPasswordForm/DataAccess'

export default combineReducers({
  change_password: ChangePasswordReducer,
  login: loginReducer,
  reset_password: ResetPasswordReducer,
  verify: mfaReducer,
  sso: LoginSSOReducer
})
