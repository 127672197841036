import apiAction from 'common/actions/api'

const threatApi = apiAction('threat')

export const RESET_DETAIL = 'cylera/threat/RESET_DETAIL'

export const loadThreatDetails = threatUuid =>
  threatApi('details', { uuid: threatUuid })

export const loadThreatEvents = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  threatUuid
) => {
  return threatApi('events', {
    page: page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    uuid: threatUuid
  })
}

export const loadThreatEventsInfinite = (
  page,
  pageSize,
  sortKey,
  sortDirection,
  threatUuid
) => {
  return threatApi(
    'events',
    {
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      uuid: threatUuid
    },
    { infinite: true }
  )
}

export const exportThreatEvents = uuid => {
  return threatApi(
    'export_events',
    {
      uuid: uuid
    },
    { cacheable: false }
  )
}

export const loadThreatParticipants = threatUuid =>
  threatApi('participants', { uuid: threatUuid })

export const updateStatus = (
  threats,
  status,
  acceptedUntil,
  acceptanceReason,
  refreshData,
  failure
) =>
  threatApi(
    'status',
    {
      threats: threats,
      status: status,
      accepted_until: acceptedUntil,
      acceptance_reason: acceptanceReason
    },
    {
      method: 'POST',
      success: refreshData,
      failure: failure
    }
  )

export const updateAssignee = (threats, assignee, refreshData) =>
  threatApi(
    'assignee',
    {
      threats: threats,
      assignee: assignee
    },
    {
      method: 'POST',
      success: refreshData
    }
  )

export const updateAssigneeNew = (threats, assignee, success, failure) =>
  threatApi(
    'assignee',
    {
      threats: threats,
      assignee: assignee
    },
    {
      method: 'POST',
      success,
      failure
    }
  )

export const loadMitigationPlans = threatUuid =>
  threatApi('mitigation_plans', { uuid: threatUuid })

export function resetDetail() {
  return dispatch => {
    dispatch({
      type: RESET_DETAIL
    })
  }
}
