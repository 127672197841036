import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('overview')
})

function locationsOverviewReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'dashboard/locations',
    initialState
  )
  if (apiResult) {
    return apiResult
  }

  return state
}

export default locationsOverviewReducer
