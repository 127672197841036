import { createSelector } from 'reselect'

const base = state => state.main.networking.external_communication

export const externalCommunication = createSelector(
  base,
  external_communication =>
    external_communication.getIn(['external_communication', 'data'])
)
export const externalCommunicationLoading = createSelector(
  base,
  external_communication =>
    external_communication.get('external_communication_loading')
)
export const maxTotalTraffic = createSelector(base, external_communication =>
  external_communication.getIn(['external_communication', 'max_total_traffic'])
)

export const groupByColumns = createSelector(base, external_communication =>
  external_communication.get('group_by_columns')
)

export const externalCommunicationQuery = createSelector(
  base,
  external_communication => external_communication.get('custom_query')
)

export const deviceExternalCommunication = createSelector(
  base,
  external_communication =>
    external_communication.get('device_external_communication')
)
export const deviceExternalCommunicationLoading = createSelector(
  base,
  external_communication =>
    external_communication.get('device_external_communication_loading')
)
export const deviceExternalCommunicationTotal = createSelector(
  base,
  external_communication =>
    external_communication.get('device_external_communication_total')
)
