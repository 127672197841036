import { fromJS } from 'immutable'
import { SET_ATTRIBUTE_TYPE } from './actions'

const initialState = fromJS({
  attribute_type: 'Default'
})

function devicesAttributesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ATTRIBUTE_TYPE:
      return state.merge({
        attribute_type: action.data
      })
    default:
      return state
  }
}

export default devicesAttributesReducer
