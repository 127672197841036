import apiAction from 'common/actions/api'

export const CHANGE_GROUP_SEARCH = 'cylera/devices/CHANGE_GROUP_SEARCH'
export const CLEAR_GROUPINGS = 'cylera/devices/CLEAR_GROUPINGS'
export const CHANGE_GROUP_LIST_FILTER = 'cylera/group/CHANGE_GROUP_LIST_FILTER'

export const CHANGE_MY_GROUPINGS_SEARCH =
  'cylera/devices/CHANGE_MY_GROUPINGS_SEARCH'

const devicesApi = apiAction('devices', '/device')

export function changeGroupListFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_GROUP_LIST_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export const getGroupingsInfinite = (
  grouping,
  deviceTagClasses,
  ownedBy,
  query,
  page,
  pageSize,
  sortKey,
  sortDirection,
  locationId = undefined
) => {
  return devicesApi(
    'groupings',
    {
      group_type: grouping,
      location_id: locationId,
      owned_by: ownedBy,
      query: query,
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      tag_class:
        deviceTagClasses.length === 0 ? null : deviceTagClasses.join(',')
    },
    { infinite: true, cacheable: false }
  )
}

export const getGroupings = (
  grouping,
  deviceTagClasses,
  ownedBy,
  query,
  page,
  pageSize,
  sortKey,
  sortDirection,
  locationId = undefined
) => {
  return devicesApi('groupings', {
    group_type: grouping,
    location_id: locationId,
    owned_by: ownedBy,
    query: query,
    page: page,
    page_size: pageSize,
    sort_key: sortKey,
    sort_direction: sortDirection,
    tag_class: deviceTagClasses.length === 0 ? null : deviceTagClasses.join(',')
  })
}

export const getTagClasses = () => devicesApi('tag_classes')

export const getStats = () => devicesApi('stats')

export function changeGroupSearch(query) {
  return dispatch => {
    dispatch({
      type: CHANGE_GROUP_SEARCH,
      data: query
    })
  }
}

export function clearGroupings(callback) {
  return dispatch => {
    dispatch({
      type: CLEAR_GROUPINGS
    })
  }
}

export function changeMyGroupingsSearch(query) {
  return dispatch => {
    dispatch({
      type: CHANGE_MY_GROUPINGS_SEARCH,
      data: query
    })
  }
}
