import apiAction from 'common/actions/api'

export const SET_GROUP_BY_COLUMNS =
  'cylera/externalCommunication/SET_GROUP_BY_COLUMNS'
export const SET_EXTERNAL_COMMUNICATION_QUERY =
  'cylera/externalCommunication/SET_EXTERNAL_COMMUNICATION_QUERY'
export const CLEAR_EXTERNAL_COMMUNICATIONS =
  'cylera/externalCommunication/CLEAR_EXTERNAL_COMMUNICATIONS'

const networkPolicyApi = apiAction('network')

export const getExternalCommunicationDetails = (filterValues, callback) => {
  const { query, groupBy } = filterValues
  return networkPolicyApi(
    'external_communication',
    { query: query, group_by: groupBy },
    { success: callback }
  )
}

export const exportExternalCommunicationDetails = (filterValues, callback) => {
  const { query, groupBy } = filterValues
  return networkPolicyApi(
    'external_communication/export',
    { query: query, group_by: groupBy },
    { success: callback, method: 'POST', cacheable: false }
  )
}

export const getDeviceExternalCommunicationDetails = (
  filterValues,
  callback
) => {
  const {
    port,
    protocol,
    partner_ip_address,
    partner_domain,
    partner_country_code
  } = filterValues
  return networkPolicyApi(
    'device_external_communication',
    {
      port: port,
      protocol: protocol,
      partner_ip_address: partner_ip_address,
      partner_domain: partner_domain,
      partner_country_code: partner_country_code
    },
    { success: callback }
  )
}

export const exportDeviceExternalCommunicationDetails = (
  filterValues,
  callback
) => {
  const {
    port,
    protocol,
    partner_ip_address,
    partner_domain,
    partner_country_code
  } = filterValues
  return networkPolicyApi(
    'device_external_communication/export',
    {
      port: port,
      protocol: protocol,
      partner_ip_address: partner_ip_address,
      partner_domain: partner_domain,
      partner_country_code: partner_country_code
    },
    { success: callback, method: 'POST', cacheable: false }
  )
}

export function setExternalCommunicationQuery(value) {
  return dispatch => {
    dispatch({
      type: SET_EXTERNAL_COMMUNICATION_QUERY,
      data: value
    })
  }
}

export function setExternalCommunicationColumns(value) {
  return dispatch => {
    dispatch({
      type: SET_GROUP_BY_COLUMNS,
      data: value
    })
  }
}

export function clearExternalCommunications() {
  return dispatch => {
    dispatch({
      type: CLEAR_EXTERNAL_COMMUNICATIONS
    })
  }
}
