import { combineReducers } from 'redux'
import { reducer as overviewReducer } from './groups/GroupInventory/DataAccess'
import { reducer as inventoryReducer } from './devices/DataAccess'
import { reducer as attributesReducer } from './attributes/DataAccess'
import { reducer as suppressedDevicesReducer } from './SuppressedDevices/DataAccess'
import { reducer as defaultAttributesReducer } from './attributes/DefaultAttributes/DataAccess'
import { reducer as customAttributesReducer } from './attributes/CustomAttributes/DataAccess'

export default combineReducers({
  inventory: inventoryReducer,
  overview: overviewReducer,
  attributes: attributesReducer,
  suppressed: suppressedDevicesReducer,
  default_attributes: defaultAttributesReducer,
  custom_attributes: customAttributesReducer
})
