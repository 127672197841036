import { createSelector } from 'reselect'
import { safeAccess } from 'common/util/iterable'

export const auth = (state, props) => state.auth

export const login = createSelector(auth, auth => safeAccess(auth, ['login']))
export const loginIsLoading = createSelector(login, login =>
  login.get('is_loading')
)
export const loginError = createSelector(login, login => login.get('error'))
export const user = createSelector(login, login => safeAccess(login, ['user']))
export const hasUser = createSelector(user, user => user !== undefined)
export const userEmail = createSelector(user, user =>
  safeAccess(user, ['email'])
)
export const isSystemUser = createSelector(
  userEmail,
  userEmail => userEmail && userEmail.includes('@cylera.com')
)
