export const HIDE_MODAL = 'core/modal/HIDE_MODAL'
export const HIDE_MODAL_LOADING = 'core/modal/HIDE_MODAL_LOADING'
export const SHOW_MODAL = 'core/modal/SHOW_MODAL'
export const SHOW_MODAL_LOADING = 'core/modal/SHOW_MODAL_LOADING'

export const hideModal = () => ({
  type: HIDE_MODAL
})

export const hideModalLoading = (body, handleClose) => ({
  type: HIDE_MODAL_LOADING
})

export const showModalLoading = (body, handleClose) => ({
  type: SHOW_MODAL_LOADING
})

export const showModal = (body, handleClose) => ({
  type: SHOW_MODAL,
  body,
  handleClose
})

export const modalActions = (dispatch, props) => ({
  hideModal: (...props) => dispatch(hideModal(...props)),
  hideModalLoading: (...props) => dispatch(hideModalLoading(...props)),
  showModal: (...props) => dispatch(showModal(...props)),
  showModalLoading: (...props) => dispatch(showModalLoading(...props))
})

export default modalActions
