import apiAction from 'common/actions/api'

export const MFA_REGISTRATION_SUCCESS =
  'cylera/settings/mfa/MFA_REGISTRATION_SUCCESS'
export const MFA_REGISTRATION_FAILURE =
  'cylera/settings/mfa/MFA_REGISTRATION_FAILURE'
export const MFA_REGISTRATION_REMOVE_ERROR =
  'cylera/settings/mfa/MFA_REGISTRATION_REMOVE_ERROR'

const authApi = apiAction('settings/mfa')

export const mfaRegistration = (
  countryCode,
  phoneNumber,
  successCallback,
  failureCallback
) =>
  authApi(
    'registration',
    {
      country_code: countryCode,
      phone_number: phoneNumber
    },
    {
      method: 'POST',
      failure: failureCallback,
      success: successCallback,
      cacheable: false
    }
  )

export const getMfaStatus = successCallback =>
  authApi(
    'registration',
    {},
    {
      success: successCallback
    }
  )

export const mfaDisable = (successCallback, failureCallback) =>
  authApi(
    'registration',
    {},
    {
      method: 'DELETE',
      failure: failureCallback,
      success: successCallback
    }
  )
