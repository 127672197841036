import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('details'),
  ...apiInitialState('widgets')
})

function customDashboardsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'custom_dashboard',
    initialState
  )

  if (apiResult) {
    return apiResult
  }
  return state
}

export default customDashboardsReducer
