import { fromJS } from 'immutable'
import { apiInitialState, apiNamespaceReducer } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('overview')
})

function reducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'organization',
    initialState
  )

  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    default:
      return state
  }
}
export default reducer
